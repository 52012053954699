import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { MaterialModule } from '../material/material.module';
import { LoaderComponent } from './loader/loader.component';
import { SpaceSeparatorPipe } from './pipes/space-separator.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { DisclaimerComponent } from './dialogs/disclaimer/disclaimer.component';
import { ErrorComponent } from '../core/error/error.component';
import { LanguageCodeToNamePipe } from './pipes/language-code-to-name.pipe';
import { SubscriptionCleaner } from './helper/subscription-cleaner/subscription-cleaner.component';
import { KeycloakGroupPipe } from './pipes/keycloak-group.pipe';
import { MultiDropdownComponent } from './components/multi-dropdown/multi-dropdown.component';
import { TranslationSortPipe } from './pipes/translation-sort.pipe';
import { FileSizePipe } from './pipes/filesize.pipe';
import { FileUploadImageComponent } from './file-upload-image/file-upload-image.component';
import { NotificationsModule } from '../notifications/notifications.module';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { SearchComponent } from './components/search/search.component';
import { SearchResultListComponent } from './components/search/search-result-list/search-result-list.component';

@NgModule({
  declarations: [
    FileUploadComponent,
    LoaderComponent,
    SpaceSeparatorPipe,
    FilterPipe,
    DisclaimerComponent,
    ErrorComponent,
    LanguageCodeToNamePipe,
    SubscriptionCleaner,
    KeycloakGroupPipe,
    MultiDropdownComponent,
    TranslationSortPipe,
    FileSizePipe,
    FileUploadImageComponent,
    AutocompleteComponent,
    SearchComponent,
    SearchResultListComponent,
  ],
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, MaterialModule, NotificationsModule],
  exports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadComponent,
    LoaderComponent,
    SpaceSeparatorPipe,
    FilterPipe,
    DisclaimerComponent,
    ErrorComponent,
    LanguageCodeToNamePipe,
    KeycloakGroupPipe,
    MultiDropdownComponent,
    TranslationSortPipe,
    FileSizePipe,
    FileUploadImageComponent,
    AutocompleteComponent,
    SearchComponent,
  ],
})
export class SharedModule {}
