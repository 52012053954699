<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content>{{ data.message }}</div>

<mat-dialog-actions>
    <button mat-button color="primary" mat-dialog-close (click)="reject()">
        <mat-icon>cancel</mat-icon> {{"ACTIONS.NO" | translate}}
    </button>
    <button mat-button color="primary" cdkFocusInitial (click)="approve()">
        <mat-icon>check_circle</mat-icon> {{"ACTIONS.YES" | translate}}
    </button>
</mat-dialog-actions>