/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Biometric } from './biometric';
import { CaseStatus } from './caseStatus';
import { CaseType } from './caseType';
import { CivilStatus } from './civilStatus';
import { Correspondence } from './correspondence';
import { Country } from './country';
import { DateTimeNpgsqlRange } from './dateTimeNpgsqlRange';
import { FamilyMember } from './familyMember';
import { Interview } from './interview';
import { MeansOfEvidence } from './meansOfEvidence';
import { Outcome } from './outcome';
import { RelatedRecord } from './relatedRecord';
import { SecondaryCaseStatus } from './secondaryCaseStatus';
import { SexType } from './sexType';
import { Task } from './task';
import { TransferModality } from './transferModality';
import { TransitOperation } from './transitOperation';
import { TransmitStatus } from './transmitStatus';
import { UserGroup } from './userGroup';

export interface ModelCase { 
    id?: string;
    requestingStateCaseReferenceNo?: string;
    requestedStateCaseReferenceNo?: string;
    givenName?: string;
    surname?: string;
    maidenName?: string;
    civilStatus?: CivilStatus;
    sex?: SexType;
    dateOfBirth?: string;
    placeOfBirth?: string;
    placeOfBirthRegionCity?: string;
    acceleratedProcedure?: boolean;
    interviewRequest?: boolean;
    readmissionInError?: boolean;
    physicalDescription?: string;
    alias?: string;
    languageSpoken?: string;
    lastAddress?: string;
    submittingAgencyReference?: string;
    stateOfHealth?: string;
    observations?: string;
    indicationOfDangerousPerson?: string;
    caseType?: CaseType;
    caseStatus?: CaseStatus;
    secondaryCaseStatus?: SecondaryCaseStatus;
    travelDocumentType?: string;
    documentNo?: string;
    createdDate?: Date;
    deadline?: Date;
    submittedBy?: string;
    submittedByGroupId?: string;
    submittedByGroup?: UserGroup;
    receivedByGroupId?: string;
    receivedByGroup?: UserGroup;
    assignedUserId?: string;
    transmitStatus?: TransmitStatus;
    transitOperation?: TransitOperation;
    transferModality?: TransferModality;
    outcome?: Outcome;
    biometrics?: Biometric;
    correspondences?: Array<Correspondence>;
    familyMembers?: Array<FamilyMember>;
    meansOfEvidences?: Array<MeansOfEvidence>;
    relatedRecords?: Array<RelatedRecord>;
    relatedRecordsParents?: Array<RelatedRecord>;
    photographFileId?: string;
    photograph?: any;
    isAnonymized?: boolean;
    isVisible?: boolean;
    period?: DateTimeNpgsqlRange;
    closedDate?: Date;
    tasks?: Array<Task>;
    interview?: Interview;
    caseAllocationRemark?: string;
    reactivated?: boolean;
    receivingCountryCode?: string;
    sentByCountryCode?: string;
    nationalityCountryCode?: string;
    receivingCountry?: Country;
    sentByCountry?: Country;
    nationalityCountry?: Country;
}