<div class="center flex image-content">
    <img *ngIf="fileInput" class="image" [src]="getFileUri()">
      <mat-icon *ngIf="!fileInput" class="input-icon">
          {{icon}}
      </mat-icon>
  </div>
  
  <div class="selected-file" *ngIf="fileInput">
    <a href="#" (click)="downloadFile($event)">
      {{ fileInput.fileName }} ({{ fileInput.sizeInBytes! | fileSize }})
    </a>
    <button mat-button mat-icon-button type="button" *ngIf="!viewOnly" (click)="removeFile()">
      <mat-icon color="warn">delete</mat-icon>
    </button>
  </div>
  
  <div class="buttons" *ngIf="!viewOnly && !fileInput">
    <input #file mat-raised-button type="file" (click)="file.value = ''" value="" accept="{{validFileExtensions}}"
    (change)="addFile($event);" hidden>
    <button type="button" mat-raised-button value="Choose File"
        (click)="file.click()">{{"ACTIONS.UPLOAD" | translate}}</button>
  </div>
  
  <div>
    <button color="primary" *ngIf="!viewOnly && icaoValidation && fileInput" mat-raised-button
    type="button" (click)="openPhotoResultsDialog()">
    {{"ICAO.SEE_RESULT" | translate}}</button>
  </div>
  
  <div *ngIf="!viewOnly && description && !fileInput">
    <label>{{description}}</label>
  </div>