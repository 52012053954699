/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TransitType = 'Air' | 'Land' | 'Sea';

export const TransitType = {
    Air: 'Air' as TransitType,
    Land: 'Land' as TransitType,
    Sea: 'Sea' as TransitType
};