export const PersonalDetailsReadmissionFieldNames: string[] = ['givenName', 'photograph', 'languageSpoken', 'surname', 'sex', 'maidenName', 'civilStatus',
    'nationalityCountryCode', 'placeOfBirth', 'dateOfBirth', 'physicalDescription', 'alias', 'lastAddress', 'submittingAgencyReference',
    'indicationOfDangerousPerson', 'stateOfHealth', 'observations', 'placeOfBirthRegionCity'];

export const PersonalDetailsTransitFieldNames: string[] = ['givenName', 'photograph', 'languageSpoken', 'surname', 'sex', 'maidenName', 'travelDocumentType',
    'nationalityCountryCode', 'placeOfBirth', 'dateOfBirth', 'physicalDescription', 'alias', 'documentNo', 'submittingAgencyReference', 'placeOfBirthRegionCity'];

export const TransitOperationFormFields: string[] = ['admissionGuaranteed', 'typeOfTransit', 'otherStatesOfTransit', 'anyReasonOfRefusal',
    'finalDestinationCountryCode', 'proposedCrossingPoint', 'proposedTransferDate', 'proposedTransferTime', 'escortPossible', 'escortDetails', 'observations'];

export const FamilyInformationFormFields: string[] = ['givenName', 'surname', 'maidenName', 'dateOfBirth', 'placeOfBirth', 'alias',
    'languageSpoken', 'relationship', 'nationalityCountryCode', 'physicalDescription', 'sex', 'placeOfBirthRegionCity'];

export const MeansOfEvidenceFormFields: string[] = ['meansOfEvidenceDocumentType', 'documentNo', 'dateOfIssuance', 'expirationDate',
    'placeOfIssuance', 'issuingAuthority', 'document'];

export const RelatedRecordsFormFields: string[] = ['reference', 'referenceType'];

export const TransferModalitiesFormFields: string[] = ['typeOfTransfer', 'proposedReturnDate', 'proposedReturnTime', 'transferDocumentType', 'transferDocumentNo', 'returneeIssuingAuthority', 'returneePlaceOfIssuance', 'returneeExpirationDate', 'escortPossible', 'escortFullName', 'escortNationalityCountryCode', 'escortPassportNo', 'escortIssuanceDate', 'escortExpirationDate',
    'escortIssuingAuthority', 'escortPlaceOfIssuance'];
