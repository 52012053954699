/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Country } from './country';
import { ModelCase } from './modelCase';
import { TaskData } from './taskData';
import { TaskRequestType } from './taskRequestType';
import { TaskResponse } from './taskResponse';
import { TaskResponseType } from './taskResponseType';
import { TaskStatus } from './taskStatus';
import { TaskType } from './taskType';
import { TransmitStatus } from './transmitStatus';
import { UserGroup } from './userGroup';

export interface Task { 
    id?: string;
    name?: string;
    description?: string;
    createdBy?: string;
    type?: TaskType;
    taskStatus?: TaskStatus;
    taskData?: TaskData;
    caseId?: string;
    deadline?: Date;
    assignedCaseWorkerIds?: Array<string>;
    createdByGroupId?: string;
    createdByGroup?: UserGroup;
    createdByGroupIsAgency?: boolean;
    agencyId?: string;
    agency?: UserGroup;
    departmentId?: string;
    department?: UserGroup;
    createdDate?: Date;
    responseType?: TaskResponseType;
    requestType?: TaskRequestType;
    transmitStatus?: TransmitStatus;
    taskResponses?: Array<TaskResponse>;
    _case?: ModelCase;
    receivingCountryCode?: string;
    sentByCountryCode?: string;
    sentByCountry?: Country;
    receivingCountry?: Country;
}