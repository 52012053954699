import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '@shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SubscriptionCleaner } from '@shared/helper/subscription-cleaner/subscription-cleaner.component';
import { NotificationService } from '@shared/services';
import { take } from 'rxjs';
import { KeycloakUser, UpdateUsersPassword, WorkshopService } from 'swagger';
import { SetPasswordsComponent } from '../dialogs/set-passwords/set-passwords.component';

@Component({
  selector: 'app-workshop-administration',
  templateUrl: './workshop-administration.component.html',
  styleUrls: ['./workshop-administration.component.scss'],
})
export class WorkshopAdministrationComponent extends SubscriptionCleaner implements OnInit {
  columns = [
    {
      key: 'username',
      label: 'USER_ADMINISTRATION.USERNAME',
    },
    {
      key: 'firstName',
      label: 'USER_ADMINISTRATION.FIRST_NAME',
    },
    {
      key: 'lastName',
      label: 'USER_ADMINISTRATION.LAST_NAME',
    },
  ];
  keycloakUsers: KeycloakUser[] = [];
  usersTable: MatTableDataSource<KeycloakUser> = new MatTableDataSource();
  displayedColumns: string[] = this.columns.map((col) => col.key);

  public constructor(
    private workshopService: WorkshopService,
    public translate: TranslateService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.refreshUsers();
  }

  public refreshUsers(): void {
    this.workshopService
      .getKeyCloakUsers()
      .pipe(take(1))
      .subscribe((result: KeycloakUser[]) => {
        this.keycloakUsers = result;
        this.usersTable = new MatTableDataSource<KeycloakUser>(result as KeycloakUser[]);
      });
  }

  public resetData(): void {
    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('Workshop.ConfirmDelete'),
      },
    });

    dialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (result) {
          this.workshopService
            .resetApplicationData()
            .pipe(take(1))
            .subscribe((res) => {
              if (res) {
                this.notificationService.showSuccess(this.translate.instant('SUCCESS'), this.translate.instant('Workshop.ResetDataSuccess'));
              } else {
                this.notificationService.showError(this.translate.instant('ERROR'), this.translate.instant('Workshop.ResetDataFailed'));
              }
            });
        }
      });
  }

  public setPassword(): void {
    const dialog = this.dialog.open(SetPasswordsComponent, {
      width: '500px',
    });

    dialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: string) => {
        if (result) {
          const request: UpdateUsersPassword = {
            password: result,
            keycloakUsers: this.keycloakUsers,
          };

          this.workshopService
            .setUserPasswords(request)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this.notificationService.showSuccess(this.translate.instant('SUCCESS'));
              },
              error: () => {
                this.notificationService.showError(this.translate.instant('ERROR'));
              },
            });
        }
      });
  }
}
