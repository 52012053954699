/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ReferenceType = 'Case' | 'Correspondence';

export const ReferenceType = {
    Case: 'Case' as ReferenceType,
    Correspondence: 'Correspondence' as ReferenceType
};