import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConfigService} from 'src/app/core/app-config/app-config.service';
import {CreateNonWorkDayDto, CreateNonWorkPeriodDto, NonWorkDayDto} from 'src/app/system-administration/models/nonWorkDayDto';
import {CalendarEventDto} from '@shared/models/calendarDto';
import { CalendarDto, CalendarService as CalendarApiService} from 'swagger';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  apiBaseUrl = this.config.getConfig().api.baseUrl;
  calendarPath = '/api/calendar/';

  constructor(private http: HttpClient,
              private config: AppConfigService,
              private calendarApiService: CalendarApiService) {
  }

  getNonWorkingDays(countryCode: string): Observable<CalendarDto> {
    return this.calendarApiService.getCalendarAndNonWorkingDays(countryCode);
  }

  addNonWorkDay(createNonWorkDay: CreateNonWorkDayDto) {
    return this.http.post(this.apiBaseUrl + this.calendarPath + 'nonworkday/', createNonWorkDay);
  }

  addNonWorkPeriod(createNonWorkPeriod: CreateNonWorkPeriodDto) {
    return this.http.post(this.apiBaseUrl + this.calendarPath + 'nonworkperiod/', createNonWorkPeriod);
  }

  deleteNonWorkDay(nonWorkDay: NonWorkDayDto) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: nonWorkDay,
    };
    return this.http.delete(this.apiBaseUrl + this.calendarPath + 'nonworkday/', options);
  }

  deleteNonWorkPeriod(nonWorkPeriod: CreateNonWorkPeriodDto) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: nonWorkPeriod,
    };
    return this.http.delete(this.apiBaseUrl + this.calendarPath + 'nonworkperiod/', options);
  }
  getNonWorkingDaysCalendarEvents() {
    return this.http.get<CalendarEventDto[]>(this.apiBaseUrl + this.calendarPath + 'nonworkdayevents');
  }

  getReturnScheduleCalendarEvents() {
    return this.http.get<CalendarEventDto[]>(this.apiBaseUrl + this.calendarPath + 'returnscheduleevents');
  }
}
