/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type SexType = 'Female' | 'Male' | 'Unknown';

export const SexType = {
    Female: 'Female' as SexType,
    Male: 'Male' as SexType,
    Unknown: 'Unknown' as SexType
};