import { createAction, props } from '@ngrx/store';
import { FlagDto } from 'src/app/case/models/flagDto';
import { UserPreferencesDto } from 'src/app/case/models/UserPreferencesDto';

export const loadUserPreferencesSuccess = createAction(
  '[user preferences API] Load Success',
  props<{ userPreferences: UserPreferencesDto }>()
);

export const loadUserPreferencesFailure = createAction(
  '[User Preferences API] Load Fail',
  props<{ error: string }>()
);

export const updateUserPreferencesSuccess = createAction(
  '[User Preferences API] Update User Preferences Success',
  props<{ userPreferences: UserPreferencesDto }>()
);

export const updateUserPreferencesFailure = createAction(
  '[User Preferences API] Update User Preferences Fail',
  props<{ error: string }>()
);

export const loadUserCaseFlagsSuccess = createAction(
  '[user Case Flags API] Load Success',
  props<{ userCaseFlags: FlagDto[] }>()
);

export const loadUserCaseFlagsFailure = createAction(
  '[User Case Flags API] Load Fail',
  props<{ error: string }>()
);

export const loadUserPermissionsSuccess = createAction(
  '[user Permissions API] Load Success',
  props<{ permissions: string[] }>()
);

export const loadUserPermissionsFailure = createAction(
  '[User Permissions API] Load Fail',
  props<{ error: string }>()
);

export const loadKeycloakUserInfoSuccess = createAction(
  '[Keycloak User Information API] Load Success',
  props<{ keycloakUserInfo: any }>()
);

export const loadKeycloakUserInfoFailure = createAction(
  '[Keycloak User Information API] Load Fail',
  props<{ error: string }>()
);