/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Country } from './country';
import { UserGroup } from './userGroup';

export interface CaseWorker { 
    caseWorkerId?: string;
    name?: string;
    roles?: Array<string>;
    groups?: Array<string>;
    permissions?: Array<string>;
    email?: string;
    agencyId?: string;
    agency?: UserGroup;
    departmentId?: string;
    department?: UserGroup;
    updatedAt?: Date;
    countryCode?: string;
    country?: Country;
    preferredLocale?: string;
}