import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { KeycloakUser } from '../../models/keycloakUser';
import { Store } from '@ngrx/store';
import { State } from 'src/app/case/state';
import { selectUserRoles } from 'src/app/users/state';
import { RoleTypes } from '@shared/models/roles';
import { SubscriptionCleaner } from '@shared/helper/subscription-cleaner/subscription-cleaner.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss'],
})
export class ManageUserComponent extends SubscriptionCleaner implements OnInit {
  @Input() user: KeycloakUser | undefined;
  newUser: FormGroup;
  roleTypes = RoleTypes;

  userRoles$ = this.store.select(selectUserRoles);

  enabledDropdown = [
    {
      value: true,
      name: 'Yes',
    },
    {
      value: false,
      name: 'No',
    },
  ];

  constructor(
    private dialogRef: MatDialogRef<ManageUserComponent>,
    private store: Store<State>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.newUser = this.createForm(this.user);

    this.userRoles$.pipe(takeUntil(this.destroy$)).subscribe((userRoles: string[]) => {
      if (userRoles.includes(RoleTypes.AdminUser)) {
        this.newUser.addControl('groupId', new FormControl(this.user?.groupId || '', [Validators.required]));
      }

      if (this.user !== undefined) {
        this.newUser.get('username')?.disable();
      }
    });
  }

  createForm(user: KeycloakUser | undefined): FormGroup {
    const formGroup = new FormGroup({
      id: new FormControl(user?.id || ''),
      firstName: new FormControl(user?.firstName || '', [Validators.required, Validators.minLength(2)]),
      lastName: new FormControl(user?.lastName || '', [Validators.required, Validators.minLength(2)]),
      email: new FormControl(user?.email || '', [Validators.required, Validators.email]),
      username: new FormControl(user?.username || '', [Validators.required, Validators.minLength(2)]),
      enabled: new FormControl(user?.enabled || false, [Validators.required]),
    });

    return formGroup;
  }

  selectedGroupHandler(groupId: string | null) {
    this.newUser.controls['groupId'].setValue(groupId);
  }

  onSubmit(): void {
    if (this.newUser!.valid) {
      this.dialogRef.close(this.newUser!.getRawValue());
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
