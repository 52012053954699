/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { NotificationLevel } from './notificationLevel';

export interface UserPreferencesDto { 
    userId?: string;
    languagePreference?: string;
    receivedCasesColumnPreference?: Array<string>;
    sentCasesColumnPreference?: Array<string>;
    disabledEmailNotificationPreference?: Array<NotificationLevel>;
    disabledSystemNotificationPreference?: Array<NotificationLevel>;
    requestingCountry?: boolean;
}