export enum FileExtensionType {
  pdf = '1',
  png = '2',
  jpg = '3',
  jpeg = '4',
  xls = '5',
  xlsx = '6',
  docx = '7',
  doc = '8',
  eps = '9',
  svg = '10',
  odt = '11',
  txt = '12',
  rar = '13',
  zip = '14',
  html = '15',
  htm = '16',
  ods = '17',
  ppt = '18',
  pptx = '19',
}
