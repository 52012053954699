import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class FileUploadStateService implements OnDestroy {

    private destroy$: Subject<any> = new Subject<any>();
    private _file: File | null = null;
    private _file$ = new BehaviorSubject(this._file);

    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    set file(file: any) {
        this._file = file;
        this._file$.next(this._file);
    }

    get file(): Observable<File | null> {
        return this._file$.asObservable();
    }
}


@Injectable({
    providedIn: 'root'
})
export class FileUploadStateService2 {

    private destroy$: Subject<any> = new Subject<any>();
    private _file: File | null = null;
    private _file$ = new BehaviorSubject(this._file);

    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    set file(file: any) {
        this._file = file;
        this._file$.next(this._file);
    }

    get file(): Observable<File | null> {
        return this._file$.asObservable();
    }
}