import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { PermissionTypes } from '@shared/models/permissionTypes';
import { selectUserPermissions } from 'src/app/users/state';
import { Store } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import { Subject, take, takeUntil } from 'rxjs';
import { CasesService } from '@shared/services';
import { CaseListDto } from 'swagger';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CaseStateService } from 'src/app/case/state';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-360deg)' })),
      transition('rotated => default', animate('1000ms ease-out')),
      transition('default => rotated', animate('1000ms ease-in')),
    ]),
  ],
})
export class SearchComponent implements OnDestroy {
  @Output() selectedCasesChange = new EventEmitter<CaseListDto[]>();

  public dataSource: MatTableDataSource<CaseListDto>;

  protected displayedColumns: string[] = [
    'caseStatus',
    'caseType',
    'givenName',
    'surName',
    'maidenName',
    'requestingStateCaseReferenceNo',
    'requestedStateCaseReferenceNo',
    'selectCase',
  ];

  private destroy$: Subject<boolean> = new Subject<boolean>();

  filterValue = '';
  permissions$ = this.store.select(selectUserPermissions);
  permissionTypes = PermissionTypes;
  cases: CaseListDto[] = [];
  selectedCases: CaseListDto[];
  searchPerformed = false;
  searchInProgress = false;
  searchValue = '';

  constructor(
    private store: Store<State>,
    private caseService: CasesService,
    public caseStateService: CaseStateService,
    private dialogRef: MatDialogRef<SearchComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      searchInformatiom: string;
      searchParameters: {
        [key: string]: string;
      };
    },
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onSelectedCasesChange(selectedCases: CaseListDto[]) {
    this.dialogRef.close({ selectedCases: selectedCases });
  }

  getCases() {
    for (const key in this.data.searchParameters) {
      this.data.searchParameters[key] = this.filterValue;
    }
    this.cases = [];
    this.searchPerformed = true;
    this.searchInProgress = true;

    this.caseService
      .getCasesBySearch(this.data.searchParameters)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((caseList: CaseListDto[]) => {
        this.cases = caseList;
        this.searchInProgress = false;
        this.searchValue = '';
      });
  }

  public setFilterValue(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValue = filterValue;
  }

  public close(): void {
    this.dialogRef.close();
  }

  showNoResultsMessage() {
    return this.searchPerformed && !this.searchInProgress && this.cases.length === 0;
  }
}
