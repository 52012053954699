/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TaskResponseType = 'Body' | 'BodyAndAttachment' | 'ProcessMinuteResponse' | 'ExtensionOfDeadline';

export const TaskResponseType = {
    Body: 'Body' as TaskResponseType,
    BodyAndAttachment: 'BodyAndAttachment' as TaskResponseType,
    ProcessMinuteResponse: 'ProcessMinuteResponse' as TaskResponseType,
    ExtensionOfDeadline: 'ExtensionOfDeadline' as TaskResponseType
};