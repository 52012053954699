import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CaseDto } from 'src/app/case/models/caseDto';
import { TranslatedCaseDto } from 'src/app/case/models/translatedCaseDto';
import { CorrespondenceDto } from 'src/app/case/models/correspondenceDto';
import { TransferModalityDto } from 'src/app/case/models/transferModalityDto';
import { FormValidationService } from './form-validation.service';
import { NotificationService } from './notification.service';
import { RelatedRecordDto } from 'src/app/case/models';
import { InterviewDto, InterviewOutcomeDto } from '../../case/models/interviewDto';
import { CaseDto as CaseDtoV1, CaseType } from 'swagger';
import { ActivatedRoute, Params } from '@angular/router';
import { SubscriptionCleaner } from '@shared/helper/subscription-cleaner/subscription-cleaner.component';
import { takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormCreatorService extends SubscriptionCleaner {
  caseType: CaseType;

  constructor(
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private formValidationService: FormValidationService,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
  ) {
    super();

    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.caseType = params['type'];
    });
  }

  createFamilyMemberForm() {
    const familyMemberForm = this.formBuilder.group({
      givenName: [],
      surname: [],
      maidenName: [],
      dateOfBirth: [],
      placeOfBirth: [],
      alias: [],
      languageSpoken: [],
      relationship: [],
      nationalityCountryCode: [],
      physicalDescription: [],
      sex: [],
      placeOfBirthRegionCity: [],
    });
    this.formValidationService.setValidatorsForFamilyInformation(familyMemberForm, this.caseType);
    return familyMemberForm;
  }

  createPersonalDetailsForm(caseType: CaseType) {
    if (caseType === CaseType.Readmission || caseType === CaseType.Return) {
      const personalDetailsForm = this.formBuilder.group({
        id: [],
        caseStatus: [],
        givenName: [],
        languageSpoken: [],
        surname: [],
        sex: [],
        maidenName: [],
        civilStatus: [],
        nationalityCountryCode: [],
        placeOfBirth: [],
        dateOfBirth: [],
        physicalDescription: [],
        alias: [],
        lastAddress: [],
        photograph: [],
        submittingAgencyReference: [],
        indicationOfDangerousPerson: [],
        stateOfHealth: [],
        observations: [],
        acceleratedProcedure: [],
        interviewRequest: [],
        readmissionInError: [],
        placeOfBirthRegionCity: [],
      });
      this.formValidationService.setValidatorsForPersonalDetails(personalDetailsForm, caseType);
      return personalDetailsForm;
    }
    if (caseType === CaseType.Transit) {
      const personalDetailsForm = this.formBuilder.group({
        id: [],
        caseStatus: [],
        givenName: [],
        languageSpoken: [],
        surname: [],
        sex: [],
        maidenName: [],
        travelDocumentType: [],
        nationalityCountryCode: [],
        photograph: [null],
        placeOfBirth: [],
        dateOfBirth: [],
        submittingAgencyReference: [],
        physicalDescription: [],
        alias: [],
        documentNo: [],
        placeOfBirthRegionCity: [],
      });
      this.formValidationService.setValidatorsForPersonalDetails(personalDetailsForm, caseType);
      return personalDetailsForm;
    }

    this.notificationService.showError(this.translate.instant('ERROR'), this.translate.instant('CASE_ERROR.UNKNOWN_CASE_TYPE'));
    return this.formBuilder.group({});
  }

  createBiometricsForm() {
    const form = this.formBuilder.group({
      id: [],
      twoThumbs: [],
      leftFourFingers: [],
      rightFourFingers: [],
    });
    return form;
  }

  createTransferModalitiesForm() {
    const transferModalitiesForm = this.formBuilder.group({
      id: [],
      typeOfTransfer: [],
      proposedReturnDate: [],
      proposedReturnTime: [],
      transferDocumentType: [],
      transferDocumentNo: [],
      returneeIssuingAuthority: [],
      returneePlaceOfIssuance: [],
      returneeExpirationDate: [],
      escortPossible: [{ disabled: false }],
      escortFullName: [],
      escortNationalityCountryCode: [],
      escortPassportNo: [],
      escortIssuanceDate: [],
      escortExpirationDate: [],
      escortIssuingAuthority: [],
      escortPlaceOfIssuance: [],
    });
    this.formValidationService.setValidatorsForTransferModalities(transferModalitiesForm, this.caseType);
    return transferModalitiesForm;
  }

  createMeansOfEvidenceForm() {
    const meansOfEvidenceForm = this.formBuilder.group({
      meansOfEvidenceDocumentType: [],
      documentNo: [],
      dateOfIssuance: [],
      expirationDate: [],
      placeOfIssuance: [],
      issuingAuthority: [],
      document: [],
    });
    this.formValidationService.setValidatorsForMeansOfEvidence(meansOfEvidenceForm, this.caseType);
    return meansOfEvidenceForm;
  }

  createTransitOperationForm() {
    const transitOperationForm = this.formBuilder.group({
      id: [],
      admissionGuaranteed: [],
      typeOfTransit: [],
      otherStatesOfTransit: [],
      anyReasonOfRefusal: [],
      finalDestinationCountryCode: [],
      proposedCrossingPoint: [],
      proposedTransferDate: [],
      proposedTransferTime: [],
      escortPossible: [],
      escortDetails: [],
      observations: [],
      proposedDateOfTransfer: [],
    });
    this.formValidationService.setValidatorsForTransitOperation(transitOperationForm);
    return transitOperationForm;
  }

  createCorrespondenceForm() {
    return this.formBuilder.group({
      subject: [],
      body: [],
      isNationalCorrespondence: [false],
      receivingAgencyIds: [],
      receivingDepartmentIds: [],
      attachments: [null],
    });
  }

  createInterviewForm() {
    const interviewForm = this.formBuilder.group({
      id: [],
      physicalInterview: [],
      proposedStartDate: [],
      proposedStartTime: [],
      proposedFinishTime: [],
      address: [],
      meetingUrl: [],
      interviewOutcome: this.formBuilder.group({
        comment: [],
        attachments: [],
      }),
    });
    interviewForm.disable();

    return interviewForm;
  }

  createCommentForm() {
    return this.formBuilder.group({
      body: [],
      attachments: [null],
    });
  }

  patchTransitOperation(caseDto: CaseDto, fullForm: FormGroup | null) {
    if (!fullForm) {
      return;
    }

    const form = fullForm.get('transitOperation')! as FormGroup;

    form.patchValue({
      id: caseDto.transitOperation?.id,
      admissionGuaranteed: caseDto.transitOperation?.admissionGuaranteed,
      typeOfTransit: caseDto.transitOperation?.typeOfTransit?.toString(),
      otherStatesOfTransit: caseDto.transitOperation?.otherStatesOfTransit!.map(String),
      anyReasonOfRefusal: caseDto.transitOperation?.anyReasonOfRefusal,
      finalDestinationCountryCode: caseDto.transitOperation?.finalDestinationCountryCode,
      proposedCrossingPoint: caseDto.transitOperation?.proposedCrossingPoint,
      escortPossible: caseDto.transitOperation?.escortPossible,
      escortDetails: caseDto.transitOperation?.escortDetails,
      observations: caseDto.transitOperation?.observations,
      proposedTransferDate: caseDto.transitOperation?.proposedTransferDate,
      proposedTransferTime: caseDto.transitOperation?.proposedTransferTime,
    });
    form.disable();
  }

  patchPersonalDetails(caseDto: CaseDto, fullForm: FormGroup | null, caseType: CaseType) {
    if (!fullForm) {
      return;
    }
    const form = fullForm.get('personalDetails')! as FormGroup;

    if (caseType === CaseType.Readmission || caseType === CaseType.Return) {
      form.patchValue({
        id: caseDto.id,
        caseStatus: caseDto.caseStatus,
        givenName: caseDto.givenName,
        languageSpoken: caseDto.languageSpoken,
        surname: caseDto.surname,
        sex: caseDto.sex?.toString(),
        maidenName: caseDto.maidenName,
        civilStatus: caseDto.civilStatus?.toString(),
        nationalityCountryCode: caseDto.nationalityCountryCode,
        placeOfBirth: caseDto.placeOfBirth?.toString(),
        dateOfBirth: this.setNullDate(caseDto.dateOfBirth!),
        physicalDescription: caseDto.physicalDescription,
        alias: caseDto.alias,
        photograph: caseDto.photograph,
        lastAddress: caseDto.lastAddress,
        submittingAgencyReference: caseDto.submittingAgencyReference,
        indicationOfDangerousPerson: caseDto.indicationOfDangerousPerson,
        stateOfHealth: caseDto.stateOfHealth,
        observations: caseDto.observations,
        acceleratedProcedure: caseDto.acceleratedProcedure,
        interviewRequest: caseDto.interviewRequest,
        readmissionInError: caseDto.readmissionInError,
        placeOfBirthRegionCity: caseDto.placeOfBirthRegionCity,
      });
    }

    if (caseType === CaseType.Transit) {
      form.patchValue({
        id: caseDto.id,
        caseStatus: caseDto.caseStatus,
        givenName: caseDto.givenName,
        languageSpoken: caseDto.languageSpoken,
        surname: caseDto.surname,
        sex: caseDto.sex?.toString(),
        maidenName: caseDto.maidenName,
        photograph: caseDto.photograph,
        travelDocumentType: caseDto.travelDocumentType,
        nationalityCountryCode: caseDto.nationalityCountryCode,
        placeOfBirth: caseDto.placeOfBirth?.toString(),
        dateOfBirth: this.setNullDate(caseDto.dateOfBirth),
        submittingAgencyReference: caseDto.submittingAgencyReference,
        physicalDescription: caseDto.physicalDescription,
        alias: caseDto.alias,
        documentNo: caseDto.documentNo,
        placeOfBirthRegionCity: caseDto.placeOfBirthRegionCity,
      });
    }

    form.disable();
  }

  patchTransferModalities(caseDto: CaseDtoV1, fullForm: FormGroup | null) {
    if (!fullForm) {
      return;
    }

    const form = fullForm.get('transferModalities')! as FormGroup;

    form.patchValue({
      id: caseDto.transferModality?.id,
      typeOfTransfer: caseDto.transferModality?.typeOfTransfer?.toString(),
      proposedReturnDate: caseDto.transferModality?.proposedReturnDate,
      proposedReturnTime: caseDto.transferModality?.proposedReturnTime,
      transferDocumentType: caseDto.transferModality?.transferDocumentType?.toString(),
      transferDocumentNo: caseDto.transferModality?.transferDocumentNo,
      returneeIssuingAuthority: caseDto.transferModality?.returneeIssuingAuthority,
      returneePlaceOfIssuance: caseDto.transferModality?.returneePlaceOfIssuance,
      returneeExpirationDate: caseDto.transferModality?.returneeExpirationDate,
      escortPossible: caseDto.transferModality?.escortPossible,
      escortFullName: caseDto.transferModality?.escortFullName,
      escortNationalityCountryCode: caseDto.transferModality?.escortNationalityCountryCode,
      escortPassportNo: caseDto.transferModality?.escortPassportNo,
      escortIssuanceDate: caseDto.transferModality?.escortIssuanceDate,
      escortExpirationDate: caseDto.transferModality?.escortExpirationDate,
      escortIssuingAuthority: caseDto.transferModality?.escortIssuingAuthority,
      escortPlaceOfIssuance: caseDto.transferModality?.escortPlaceOfIssuance,
    });
    form.disable();
  }

  patchFamilyInformation(caseDto: CaseDto, fullForm: FormGroup | null) {
    if (!fullForm) {
      return;
    }

    const form = fullForm.get('familyInformation')! as FormArray;
    form.clear();
    caseDto.familyMembers?.forEach((member) => {
      const familyMember = this.setFamilyMemberFormValue(member);
      form.push(familyMember);
    });
  }

  patchMeansOfEvidence(caseDto: CaseDto, fullForm: FormGroup | null) {
    if (!fullForm) {
      return;
    }

    const form = fullForm.get('meansOfEvidence')! as FormArray;
    form.clear();
    caseDto.meansOfEvidences?.forEach((evidence) => {
      const evidenceForm = this.setMeansOfEvidenceFormValue(evidence);
      form.push(evidenceForm);
    });
  }

  patchCorrespondence(caseDto: CaseDto, fullForm: FormGroup | null) {
    if (!fullForm) {
      return;
    }

    const form = fullForm.get('correspondences')! as FormArray;
    form.clear();
    caseDto.correspondences?.forEach((correspondence) => {
      const correspondenceForm = this.setCorrespondenceFormValue(correspondence);
      form.push(correspondenceForm);
    });
  }

  patchRelatedRecords(caseDto: CaseDto, fullForm: FormGroup | null) {
    if (!fullForm) {
      return;
    }

    const form = fullForm.get('relatedRecords')! as FormArray;
    form.clear();
    caseDto.relatedRecords?.forEach((record) => {
      const recordForm = this.setRelatedRecordFormValue(record);
      form.push(recordForm);
    });
  }

  patchBiometrics(caseDto: CaseDto, fullForm: FormGroup | null) {
    if (!fullForm) {
      return;
    }

    const form = fullForm.get('biometrics')! as FormGroup;

    form.patchValue({
      id: caseDto.biometrics?.id,
      twoThumbs: caseDto.biometrics?.twoThumbs,
      leftFourFingers: caseDto.biometrics?.leftFourFingers,
      rightFourFingers: caseDto.biometrics?.rightFourFingers,
    });

    form.disable();
  }

  patchInterview(interview: InterviewDto | null, fullForm: FormGroup | null) {
    if (!fullForm || !interview) {
      return;
    }

    const form = fullForm.get('interview')! as FormGroup;

    form.patchValue({
      id: interview.id,
      physicalInterview: interview.physicalInterview,
      proposedStartDate: interview.proposedStartDate,
      proposedStartTime: interview.proposedStartTime,
      proposedFinishTime: interview.proposedFinishTime,
      address: interview.address,
      meetingUrl: interview.meetingUrl,
      interviewOutcome: this.patchInterviewOutcome(interview.interviewOutcome, form),
    });
    form.disable();
  }

  patchInterviewOutcome(interviewOutcome: InterviewOutcomeDto | null, interviewForm: FormGroup | null) {
    if (!interviewForm || !interviewOutcome) {
      return;
    }

    const form = interviewForm.get('interviewOutcome')! as FormGroup;

    form.patchValue({
      comment: interviewOutcome.comment,
      attachments: interviewOutcome.attachments,
    });
    return form;
  }
  setFamilyMemberFormValue(familyMember: any) {
    const familyMemberForm = this.formBuilder.group({
      id: [familyMember.id],
      givenName: [familyMember.givenName],
      surname: [familyMember.surname],
      maidenName: [familyMember.maidenName],
      dateOfBirth: [this.setNullDate(familyMember.dateOfBirth)],
      placeOfBirth: [familyMember.placeOfBirth?.toString()],
      alias: [familyMember.alias],
      languageSpoken: [familyMember.languageSpoken],
      relationship: [familyMember.relationship?.toString()],
      nationalityCountryCode: [familyMember.nationalityCountryCode],
      physicalDescription: [familyMember.physicalDescription],
      sex: [familyMember.sex?.toString()],
      placeOfBirthRegionCity: [familyMember.placeOfBirthRegionCity],
    });
    familyMemberForm.disable();
    return familyMemberForm;
  }

  setMeansOfEvidenceFormValue(evidence: any) {
    const meansOfEvidenceForm = this.formBuilder.group({
      id: [evidence.id],
      meansOfEvidenceDocumentType: [evidence.meansOfEvidenceDocumentType?.toString()],
      documentNo: [evidence.documentNo],
      dateOfIssuance: [this.setNullDate(evidence.dateOfIssuance)],
      expirationDate: [this.setNullDate(evidence.expirationDate)],
      placeOfIssuance: [evidence.placeOfIssuance],
      issuingAuthority: [evidence.issuingAuthority],
      documentSizeKb: [evidence.documentSizeKb],
      document: [evidence.document],
      documentExists: [evidence.documentExists],
    });
    meansOfEvidenceForm.disable();
    return meansOfEvidenceForm;
  }

  setNullDate(dateStr: string | null) {
    if (!dateStr) {
      return null;
    }

    const date = new Date(Date.parse(dateStr));

    if (date.getFullYear() == 1) {
      return null;
    } else {
      return date;
    }
  }

  setDate(unformattedDate: Date) {
    let date: Date | null = new Date(unformattedDate);
    let time = null;
    if (date.toString() == 'Invalid Date') {
      date = null;
    }
    if (date && date.getFullYear() == 1) {
      date = null;
    } else {
      time = this.datePipe.transform(date, 'HH:mm');
    }
    return { date, time };
  }

  setCorrespondenceFormValue(correspondenceDto: CorrespondenceDto) {
    const correspondenceForm = this.formBuilder.group({
      id: correspondenceDto.id,
      subject: correspondenceDto.subject,
      body: correspondenceDto.body,
      sender: correspondenceDto.sender,
      attachments: [correspondenceDto.attachments],
      createdDate: correspondenceDto.createdDate,
      sentByCountryCode: correspondenceDto.sentByCountryCode,
    });

    return correspondenceForm;
  }

  setRelatedRecordFormValue(relatedRecordDto: RelatedRecordDto) {
    const recordForm = this.formBuilder.group({
      id: relatedRecordDto.id,
      caseId: relatedRecordDto.caseId,
      requestingStateCaseReferenceNo: relatedRecordDto.requestingStateCaseReferenceNo,
      requestedStateCaseReferenceNo: relatedRecordDto.requestedStateCaseReferenceNo,
      maidenName: relatedRecordDto.maidenName,
      givenName: relatedRecordDto.givenName,
      surname: relatedRecordDto.surname,
      caseStatus: relatedRecordDto.caseStatus,
    });

    return recordForm;
  }

  setTransferModalityFormValue(transferModalityDto: TransferModalityDto) {
    const returnDateAndTime = this.setDate(transferModalityDto?.proposedReturnDate!);

    const transferModalitiesForm = this.formBuilder.group({
      id: transferModalityDto.id,
      typeOfTransfer: transferModalityDto.typeOfTransfer?.toString(),
      proposedReturnDate: returnDateAndTime.date,
      proposedReturnTime: returnDateAndTime.time,
      transferDocumentType: transferModalityDto.transferDocumentType?.toString(),
      transferDocumentNo: transferModalityDto.transferDocumentNo,
      returneeIssuingAuthority: transferModalityDto.returneeIssuingAuthority,
      returneePlaceOfIssuance: transferModalityDto.returneePlaceOfIssuance,
      returneeExpirationDate: this.setDate(transferModalityDto.returneeExpirationDate!).date,
      escortPossible: transferModalityDto.escortPossible,
      escortFullName: transferModalityDto.escortFullName,
      escortNationalityCountryCode: transferModalityDto.escortNationalityCountryCode,
      escortPassportNo: transferModalityDto.escortPassportNo,
      escortIssuanceDate: this.setDate(transferModalityDto.escortIssuanceDate!).date,
      escortExpirationDate: this.setDate(transferModalityDto.escortExpirationDate!).date,
      escortIssuingAuthority: transferModalityDto.escortIssuingAuthority,
      escortPlaceOfIssuance: transferModalityDto.escortPlaceOfIssuance,
    });

    this.formValidationService.checkValidityOfTransferModalities(transferModalitiesForm);
    return transferModalitiesForm;
  }

  generateFullForm(caseType: CaseType): FormGroup | null {
    let form: FormGroup;

    if (caseType === CaseType.Readmission) {
      form = this.formBuilder.group({
        personalDetails: this.createPersonalDetailsForm(caseType),
        familyInformation: this.formBuilder.array([]),
        relatedRecords: this.formBuilder.array([]),
        meansOfEvidence: this.formBuilder.array([]),
        biometrics: this.createBiometricsForm(),
        correspondences: this.formBuilder.array([]),
        transferModalities: this.createTransferModalitiesForm(),
        interview: this.createInterviewForm(),
      });
      return form;
    }

    if (caseType === CaseType.Return) {
      form = this.formBuilder.group({
        personalDetails: this.createPersonalDetailsForm(caseType),
        familyInformation: this.formBuilder.array([]),
        relatedRecords: this.formBuilder.array([]),
        meansOfEvidence: this.formBuilder.array([]),
        biometrics: this.createBiometricsForm(),
        transferModalities: this.createTransferModalitiesForm(),
      });
      return form;
    }

    if (caseType === CaseType.Transit) {
      form = this.formBuilder.group({
        personalDetails: this.createPersonalDetailsForm(caseType),
        transitOperation: this.createTransitOperationForm(),
        correspondences: this.formBuilder.array([]),
      });
      return form;
    }
    return null;
  }

  fillFullForm(caseDto: CaseDto, fullForm: FormGroup | null, caseType: CaseType) {
    if (!fullForm) {
      return;
    }

    this.patchPersonalDetails(caseDto, fullForm, caseType);

    if (caseType === CaseType.Readmission || caseType === CaseType.Return) {
      this.patchFamilyInformation(caseDto, fullForm);
      this.patchBiometrics(caseDto, fullForm);
      this.patchMeansOfEvidence(caseDto, fullForm);
      this.patchRelatedRecords(caseDto, fullForm);
      this.patchTransferModalities(caseDto as unknown as CaseDtoV1, fullForm);
      if (caseType === CaseType.Readmission) {
        this.patchCorrespondence(caseDto, fullForm);
        this.patchInterview(caseDto.interview, fullForm);
      }
    }
    if (caseType === CaseType.Transit) {
      this.patchTransitOperation(caseDto, fullForm);
      this.patchCorrespondence(caseDto, fullForm);
    }
  }

  fillTranslatedFieldsInForm(fullForm: FormGroup | null, caseType: CaseType, translatedCase: TranslatedCaseDto | null) {
    if (!fullForm || !translatedCase) {
      return;
    }
    const personalDetailsForm = fullForm.get('personalDetails')! as FormGroup;
    const familyInformationFormArray = fullForm.get('familyInformation') as FormArray;
    const meansOfEvidenceFormArray = fullForm.get('meansOfEvidence') as FormArray;
    const transitOperationForm = fullForm.get('transitOperation') as FormGroup;
    const transferModalityForm = fullForm.get('transferModalities')! as FormGroup;

    personalDetailsForm.patchValue(translatedCase);

    if (caseType === CaseType.Readmission || caseType === CaseType.Return) {
      if (translatedCase?.translatedFamilyMembers) {
        familyInformationFormArray.patchValue(translatedCase.translatedFamilyMembers);
      }

      if (translatedCase?.translatedMeansOfEvidences) {
        meansOfEvidenceFormArray.patchValue(translatedCase.translatedMeansOfEvidences);
      }

      if (translatedCase?.translatedTransferModality) {
        transferModalityForm.patchValue(translatedCase.translatedTransferModality);
      }
    }

    if (caseType === CaseType.Transit) {
      if (translatedCase?.translatedTransitOperation) {
        transitOperationForm.patchValue(translatedCase.translatedTransitOperation);
      }
    }
  }

  setEditTranslationTransferModalitiesForm(transferModalitiesForm: FormGroup) {
    const transferModalityFormFieldsToTranslate = [
      'id',
      'transferDocumentNo',
      'returneeIssuingAuthority',
      'returneePlaceOfIssuance',
      'escortIssuingAuthority',
      'escortPlaceOfIssuance',
    ];

    this.enableAndCleanFormGroupForTranslation(transferModalitiesForm, transferModalityFormFieldsToTranslate);

    return transferModalitiesForm;
  }

  setEditTranslationForm(caseType: CaseType, fullForm: FormGroup) {
    if (caseType === CaseType.Readmission || caseType === CaseType.Return) {
      const personalDetails = fullForm.get('personalDetails') as FormGroup;
      const familyInformation = fullForm.get('familyInformation') as FormArray;
      const meansOfEvidence = fullForm.get('meansOfEvidence') as FormArray;
      const personalDetailsFormFieldsToTranslate = [
        'languageSpoken',
        'placeOfBirth',
        'alias',
        'physicalDescription',
        'stateOfHealth',
        'lastAddress',
        'indicationOfDangerousPerson',
        'observations',
        'placeOfBirthRegionCity',
      ];
      const familyInformationFormFieldsToTranslate = [
        'id',
        'placeOfBirth',
        'alias',
        'languageSpoken',
        'physicalDescription',
        'placeOfBirthRegionCity',
      ];
      const meansOfEvidenceFormFieldsToTranslate = ['id', 'placeOfIssuance', 'issuingAuthority'];
      const evidences = meansOfEvidence.controls;
      const familyMembers = familyInformation.controls;

      this.enableAndCleanFormGroupForTranslation(personalDetails, personalDetailsFormFieldsToTranslate);
      evidences.forEach((evidenceForm: any) => this.enableAndCleanFormGroupForTranslation(evidenceForm, meansOfEvidenceFormFieldsToTranslate));

      familyMembers.forEach((memberForm: any) => this.enableAndCleanFormGroupForTranslation(memberForm, familyInformationFormFieldsToTranslate));
    }

    if (caseType === CaseType.Transit) {
      const personalDetails = fullForm.get('personalDetails') as FormGroup;
      const transitOperation = fullForm.get('transitOperation') as FormGroup;
      const personalDetailsFormFieldsToTranslate = [
        'languageSpoken',
        'travelDocumentType',
        'placeOfBirth',
        'alias',
        'physicalDescription',
        'placeOfBirthRegionCity',
      ];
      const transitOperationFormFieldsToTranslate = ['id', 'proposedCrossingPoint', 'escortDetails', 'observations'];
      this.enableAndCleanFormGroupForTranslation(personalDetails, personalDetailsFormFieldsToTranslate);
      this.enableAndCleanFormGroupForTranslation(transitOperation, transitOperationFormFieldsToTranslate);
    }

    return fullForm;
  }

  private enableAndCleanFormGroupForTranslation(formGroup: FormGroup, formFields: string[]) {
    formFields.forEach((fieldName) => {
      formGroup.get(fieldName)?.enable();
      if (fieldName !== 'id') {
        formGroup.get(fieldName)?.setValue('');
      }
    });
  }
}
