import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CaseService as ApiCaseService,
  CaseDto,
  CaseEventDto,
  CaseListDto,
  CaseStatus,
  CreateCorrespondenceDto,
  CreateOutcomeDto,
  TransferModalityDto,
} from 'swagger';

@Injectable({
  providedIn: 'root',
})
export class CaseService {
  constructor(private apiCaseService: ApiCaseService) {}

  public getCase(id: string): Observable<CaseDto> {
    return this.apiCaseService.getCase(id);
  }

  public archiveCase(id: string): Observable<boolean> {
    return this.apiCaseService.archiveCase(id);
  }

  public partialArchiveCase(id: string): Observable<boolean> {
    return this.apiCaseService.partialArchiveCase(id);
  }

  public getCaseEvents(Id: string, language: string, descending?: boolean, searchPhrase?: string): Observable<CaseEventDto[]> {
    return this.apiCaseService.getCaseEvents(Id, descending, searchPhrase, language);
  }

  public createTransferModality(transferModality: TransferModalityDto): Observable<boolean> {
    if (transferModality?.caseId) {
      return this.apiCaseService.postTransferModalities(transferModality.caseId, transferModality);
    }
    throw Error('caseId of transferModailty is undefined or null');
  }

  public updateTransferModality(transferModality: TransferModalityDto): Observable<boolean> {
    if (transferModality?.caseId && transferModality?.id) {
      return this.apiCaseService.putTransferModalities(transferModality.caseId, transferModality);
    }
    throw Error('caseId or id of transferModailty is undefined or null');
  }

  public getCaseByReference(reference: string, countryCode: string): Observable<string> {
    return this.apiCaseService.getCaseIdByReference(reference, countryCode);
  }

  public downloadCase(id: string, language: string, translatedCase = false): Observable<Blob> {
    return this.apiCaseService.downloadCase(id, language, translatedCase);
  }

  public downloadCasesToCsv(): Observable<Blob> {
    return this.apiCaseService.downloadCases();
  }

  public updateReturnCaseStatus(id: string, caseStatus: CaseStatus): Observable<boolean> {
    return this.apiCaseService.updateReturnTypeCaseStatus(id, caseStatus);
  }

  public downloadCaseIncludingFile(id: string, language: string, translatedCase = false): Observable<Blob> {
    return this.apiCaseService.downloadCaseIncludingFiles(id, language, translatedCase);
  }

  public createCorrespondence(correspondence: CreateCorrespondenceDto): Observable<boolean> {
    if (correspondence?.caseId) {
      return this.apiCaseService.caseCorrespondence(correspondence.caseId as string, correspondence);
    }
    throw Error('caseId of correspondence is undefined or null');
  }

  public copyCase(caseId: string, countryCode: string): Observable<boolean> {
    return this.apiCaseService.copyCase(caseId, countryCode);
  }

  public createCaseOutcome(createOutcomeDto: CreateOutcomeDto): Observable<any> {
    return this.apiCaseService.createOutcome(createOutcomeDto);
  }

  public shareOutcome(caseId: string): Observable<any> {
    return this.apiCaseService.shareOutcome(caseId);
  }

  public deleteOutcome(caseId: string): Observable<any> {
    return this.apiCaseService.deleteOutcome(caseId);
  }

  public updateCaseStatus(caseId: string, caseStatus: CaseStatus): Observable<boolean> {
    return this.apiCaseService.updateCaseStatus(caseId, caseStatus);
  }

  public getRelatableCasesBySearchValue(searchValue: string, receivingCountryCode: string, caseId: string | undefined): Observable<CaseListDto[]> {
    return this.apiCaseService.getRelatableCasesBySearchValue(searchValue, receivingCountryCode, caseId);
  }
}
