import { Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';
import { FileService as ApiFileService, FileDto } from 'swagger';
import FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class File2Service {
  public constructor(private apiFileService: ApiFileService) {}

  public getFileContent(id: string): Observable<Blob> {
    return this.apiFileService.getContent(id);
  }

  public getFile(id: string): Observable<FileDto> {
    return this.apiFileService.get(id);
  }

  public downloadFile(file: FileDto): void {
    if (file?.id == undefined) {
      throw new ReferenceError('ID of the file is undefined');
    }

    this.getFileContent(file.id)
      .pipe(take(1))
      .subscribe((data: Blob) => {
        FileSaver.saveAs(data, file.fileName);
      });
  }
}
