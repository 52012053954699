import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { File2Service, FileService, NotificationService, UtilService, UtilityService } from '@shared/services';
import { FileDto } from 'swagger';
import { MatDialog } from '@angular/material/dialog';
import { PhotoResultsComponent } from 'src/app/case/dialogs/photo-results/photo-results.component';
import { IcaoResult } from 'src/app/case/models';
import { ConfirmationDialogComponent } from '@shared/dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-file-upload-image',
  templateUrl: './file-upload-image.component.html',
  styleUrls: ['./file-upload-image.component.scss']
})
export class FileUploadImageComponent {
  @Output() fileOutput: EventEmitter<FileDto | null> = new EventEmitter<FileDto | null>();

  @Input() fileInput: FileDto | undefined | null;
  @Input() viewOnly = false;
  @Input() description: string | null;
  @Input() icaoValidation = true;
  @Input() icon = 'person';

  icaoResult: IcaoResult;
  errorMessage: string;

  validFileExtensions = '.jpg,.jpeg,.png';

  constructor(
    private utilService: UtilService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private utilityService: UtilityService,
    private file2Service: File2Service,
    private dialog: MatDialog,
    private fileService: FileService,
  ) { }

  public getFileUri(): string | null {
    if (this.fileInput) {
        return this.utilityService.getDataUriFromFile(this.fileInput);
    }
    return null;
  }

  public addFile(event: any): void {
    const file: File = event.target.files[0];

    const errorMessage = this.utilService.validateFileUpload(file, this.validFileExtensions);
    if (errorMessage !== null) {
      this.notificationService.showError(this.translate.instant(errorMessage), this.translate.instant('ERROR'));
      return;
    }

    this.utilService
      .convertFileToFileDto(file)
      .subscribe({
        next: (fileDto) => {
            this.fileInput = fileDto;
            this.fileOutput.emit(fileDto);
        },
      })
      .add(() => {
        if (this.icaoValidation) {
          const body: FormData = new FormData();
          body.append('image', file);
          this.fileService.runIcaoTests(body).subscribe({
            error: () => {
              this.notificationService.showError(this.translate.instant('ATTACHMENT_ERROR.INVALID_PICTURE'), this.translate.instant('ERROR'));
              this.removeFile();
            },
            next: (icaoResult) => {
              this.icaoResult = icaoResult;
            },
          });
        }
      });
  }

  public removeFile(): void {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('CONFIRMATION_DIALOG.ARE_YOU_SURE_DELETE_FILE', {fileName: this.fileInput?.fileName })
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.fileInput = null;
          this.fileOutput.emit(null);
        }
      });
  }

  public downloadFile(event: MouseEvent): void {
    event.preventDefault();
    const file = this.fileInput;

    if (file?.id) {
      this.file2Service.downloadFile(file);
    } else {
      this.utilityService.downloadFile(file!);
    }
  }

  public openPhotoResultsDialog(): void {
    this.dialog.open(PhotoResultsComponent, {
      width: '500px',
      data: this.icaoResult,
    });
  }
}
