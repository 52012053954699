<table *ngIf="data">
    <tr>
        <th>{{"SYSTEM_STATUS.INSTALLATION" | translate}}</th>
        <th>{{"SYSTEM_STATUS.MINUTES_SINCE_LAST_RESPONSE" | translate}}</th>
        <th>{{"SYSTEM_STATUS.STATUS" | translate}}</th>
    </tr>
    <tr *ngFor="let system of data.systemStatuses as SystemStatusListDto">
        <th>{{system.targetInstallation}}</th>
        <th>{{system.timeSinceReturnedDate}}</th>
        <th><mat-icon [ngStyle]="{'color': system.statusColor}">circle</mat-icon></th>
    </tr>
</table>