import { createAction, props } from '@ngrx/store';
import { CaseDto } from '../../models';
import { TranslatedCaseDto } from '../../models/translatedCaseDto';

export const loadCurrentCaseDataSuccess = createAction('[Current Case Data API] Load Success', props<{ currentCaseData: CaseDto }>());

export const loadCurrentCaseDataFailure = createAction('[Current Case Data API] Load Fail', props<{ error: string }>());

export const loadTranlatedCaseSuccess = createAction('[Translated Case API] Load Success', props<{ translatedCase: TranslatedCaseDto }>());

export const loadTranlatedCaseFailure = createAction('[Translated Case API] Load Fail', props<{ error: string }>());
