import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageAdministrationComponent } from './language-administration.component';
import { LanguageAdministrationTableComponent } from './language-administration-table/language-administration-table.component';
import { AuthGuard } from '@shared/services/authguard';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';

const routes: Routes = [
  {
    path: '',
    component: LanguageAdministrationComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [LanguageAdministrationComponent, LanguageAdministrationTableComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    RouterModule.forChild(routes),
  ]
})
export class LanguageAdministrationModule { }
