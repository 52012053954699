/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateCommentDto } from './createCommentDto';
import { FileDto } from './fileDto';

export interface CreateCorrespondenceDto { 
    caseId?: string;
    subject?: string;
    body?: string;
    sender?: string;
    isNationalCorrespondence?: boolean;
    sentByAgencyId?: string;
    sentByDepartmentId?: string;
    sentByPreferredUsername?: string;
    receivingAgencyIds?: Array<string>;
    receivingDepartmentIds?: Array<string>;
    attachments?: Array<FileDto>;
    comments?: Array<CreateCommentDto>;
    sentByCountryCode?: string;
}