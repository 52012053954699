/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TravelDetailsDto } from './travelDetailsDto';

export interface CalendarEventDto { 
    id?: string;
    start?: Date;
    end?: Date;
    title?: string;
    allDay?: boolean;
    cssClass?: string;
    draggable?: boolean;
    travelDetails?: TravelDetailsDto;
}