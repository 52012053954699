<h1>{{ 'UI.WORKFLOW.WORKFLOW_ADMINISTRATION' | translate }}</h1>
<h3>{{ 'UI.WORKFLOW.LIST_OF_WORKFLOWS' | translate }}</h3>
<div class="d-flex justify-content-end my-2">
  <button mat-raised-button color="primary" (click)="onCreate()">{{ 'UI.WORKFLOW.CREATE_WORKFLOW' | translate }}</button>
</div>
<section class="overflow-auto shadow" tabindex="0">
  <table *ngIf="ready" mat-table [dataSource]="dataSource" class="w-100 text-nowrap" matSort>
    <!-- Default column -->
    <ng-container matColumnDef="isDefault">
      <th class="px-3" mat-header-cell *matHeaderCellDef>{{ 'UI.DEFAULT' | translate }}</th>
      <td class="px-3" mat-cell *matCellDef="let row">
        <mat-icon class="material-icons-outlined"> {{ row.isDefault ? 'checked' : 'unchecked' }} </mat-icon>
      </td>
    </ng-container>

    <!-- Requesting country name column -->
    <ng-container matColumnDef="requestingCountryName">
      <th class="px-3" mat-header-cell *matHeaderCellDef>{{ 'UI.WORKFLOW.SENDING_STATE' | translate }}</th>
      <td class="px-3" mat-cell *matCellDef="let row">{{ row.requestingCountry?.name }}</td>
    </ng-container>

    <!-- Case type column -->
    <ng-container matColumnDef="caseType">
      <th class="px-3" mat-header-cell *matHeaderCellDef>{{ 'UI.ENUM.CASE_TYPE' | translate }}</th>
      <td class="px-3" mat-cell *matCellDef="let row">{{ 'CaseType.' + row.caseType | translate }}</td>
    </ng-container>

    <!-- Receiving group column -->
    <ng-container matColumnDef="assignToGroup">
      <th class="px-3" mat-header-cell *matHeaderCellDef>{{ 'UI.WORKFLOW.RECEIVING_ENTITY' | translate }}</th>
      <td class="px-3" mat-cell *matCellDef="let row">{{ row.assignToGroup.formattedName ?? row.assignToGroup.name }}</td>
    </ng-container>

    <!-- Regular deadline -->
    <ng-container matColumnDef="regularDeadline">
      <th class="px-3" mat-header-cell *matHeaderCellDef>{{ 'UI.WORKFLOW.DEADLINE_REGULAR' | translate }}</th>
      <td class="px-3" mat-cell *matCellDef="let row">
        <button mat-icon-button color="primary" (click)="openRegulerDeadline(row.workflowDeadlineTemplateDto)">
          <mat-icon class="material-icons-outlined">visibility</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Accelerated deadline column -->
    <ng-container matColumnDef="acceleratedDeadline">
      <th class="px-3" mat-header-cell *matHeaderCellDef>{{ 'UI.WORKFLOW.DEADLINE_ACCELERATED' | translate }}</th>
      <td class="px-3" mat-cell *matCellDef="let row">
        <button
          mat-icon-button
          color="primary"
          *ngIf="showAcceleratedDeadlineButton(row)"
          (click)="openAcceleratedDeadline(row.workflowDeadlineTemplateDto)"
        >
          <mat-icon class="material-icons-outlined">visibility</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Action column -->
    <ng-container matColumnDef="actions" [stickyEnd]="true">
      <th class="px-3" mat-header-cell *matHeaderCellDef [class.invisible]="false"></th>
      <td class="px-3" mat-cell *matCellDef="let row">
        <button mat-stroked-button color="primary" (click)="onEdit(row)">
          <mat-icon class="material-icons-outlined">edit</mat-icon>
          {{ 'USER_ADMINISTRATION.EDIT' | translate }}
        </button>

        <button *ngIf="!row.isDefault" mat-stroked-button color="warn" (click)="onDelete(row.id)">
          <mat-icon>delete</mat-icon>
          {{ 'USER_ADMINISTRATION.DELETE' | translate }}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.row-color]="row.isDefault"></tr>
  </table>
</section>
