/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BiometricDto } from './biometricDto';
import { CaseType } from './caseType';
import { CivilStatus } from './civilStatus';
import { CorrespondenceDto } from './correspondenceDto';
import { CreateTransitOperationDto } from './createTransitOperationDto';
import { FamilyMemberDto } from './familyMemberDto';
import { FileDto } from './fileDto';
import { MeansOfEvidenceDto } from './meansOfEvidenceDto';
import { SecondaryCaseStatus } from './secondaryCaseStatus';
import { SexType } from './sexType';
import { TransferModalityDto } from './transferModalityDto';

export interface UpdateCaseDto { 
    id?: string;
    givenName?: string;
    surname?: string;
    maidenName?: string;
    civilStatus?: CivilStatus;
    photograph?: FileDto;
    sex?: SexType;
    dateOfBirth?: string;
    placeOfBirth?: string;
    placeOfBirthRegionCity?: string;
    acceleratedProcedure?: boolean;
    interviewRequest?: boolean;
    readmissionInError?: boolean;
    physicalDescription?: string;
    alias?: string;
    languageSpoken?: string;
    nationalityCountryCode?: string;
    lastAddress?: string;
    submittingAgencyReference?: string;
    stateOfHealth?: string;
    observations?: string;
    indicationOfDangerousPerson?: string;
    caseType?: CaseType;
    saveAsDraft?: boolean;
    secondaryCaseStatus?: SecondaryCaseStatus;
    travelDocumentType?: string;
    documentNo?: string;
    assignedUserId?: string;
    assignedGroups?: Array<string>;
    transitOperation?: CreateTransitOperationDto;
    transferModality?: TransferModalityDto;
    biometrics?: BiometricDto;
    correspondences?: Array<CorrespondenceDto>;
    familyMembers?: Array<FamilyMemberDto>;
    meansOfEvidences?: Array<MeansOfEvidenceDto>;
    relatedRecords?: Array<string>;
    caseAllocationRemark?: string;
}