/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Country } from './country';
import { ModelCase } from './modelCase';
import { TransitType } from './transitType';

export interface TransitOperation { 
    id?: string;
    caseId?: string;
    _case?: ModelCase;
    admissionGuaranteed?: boolean;
    typeOfTransit?: TransitType;
    anyReasonOfRefusal?: boolean;
    proposedCrossingPoint?: string;
    proposedDateOfTransfer?: Date;
    escortPossible?: boolean;
    escortDetails?: string;
    observations?: string;
    isAnonymized?: boolean;
    finalDestinationCountryCode?: string;
    finalDestinationCountry?: Country;
    otherStatesOfTransit?: Array<Country>;
}