import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CaseListDto } from 'swagger';
import { CaseStateService } from 'src/app/case/state';

@Component({
  selector: 'app-search-result-list',
  templateUrl: './search-result-list.component.html',
  styleUrls: ['./search-result-list.component.scss'],
})
export class SearchResultListComponent {
  @Output() selectedCasesChange = new EventEmitter<CaseListDto[]>();
  @Input() getCasesChange: CaseListDto[];

  public dataSource: MatTableDataSource<CaseListDto>;

  protected displayedColumns: string[] = [
    'caseStatus',
    'caseType',
    'givenName',
    'surName',
    'maidenName',
    'requestingStateCaseReferenceNo',
    'requestedStateCaseReferenceNo',
    'selectCase',
  ];
  cases: CaseListDto[] = [];

  public selectedRows: { [key: string]: boolean } = {};
  constructor(public caseStateService: CaseStateService) {}

  public ngOnInit(): void {
    this.cases = this.getCasesChange;
    this.dataSource = new MatTableDataSource(this.cases);
  }

  onCaseSelection(event: any, caseDto: CaseListDto) {
    this.selectedRows[caseDto.id!] = event.checked;
  }

  onAddCases() {
    const selectedCases = this.cases.filter((caseListDto: CaseListDto) => this.selectedRows[caseListDto.id!]);
    this.selectedCasesChange.emit(selectedCases);
  }
}
