import { HostListener, Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject, timer } from 'rxjs';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {

  timeoutId: any;
  userInactive: Subject<any> = new Subject();

  constructor(private notifications: NotificationService, private keycloak: KeycloakService) {
    this.checkTimeOut();
    this.userInactive.subscribe();
  }

  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  checkUserActivity() {
    clearTimeout(this.timeoutId);
    this.checkTimeOut();
  }

  checkTimeOut() {
    this.timeoutId = setTimeout(
      () => this.userInactive.next('User has been inactive for 5 seconds'), 5000
    );
  }

  showLogoutDialog() {
    let tokenUpdated = false;
    const toast = this.notifications.showLogoutTimer('You will be logged out in 1 minute, click here to stay logged in.', 'Session expires', 1, 60000);
    toast.onHidden.subscribe(() => {
      this.keycloak.updateToken().then(x => {
        tokenUpdated = x;
      });
    });

    const obs = timer(60000);
    obs.subscribe(() => { if (!tokenUpdated) {this.keycloak.logout();} });
  }
}

