import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MaterialModule } from '../material/material.module';
import { ManageUserComponent } from '../system-administration/dialogs/manage-user/manage-user.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ManageUserRolesComponent } from '../system-administration/dialogs/manage-user-roles/manage-user-roles.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { userReducer } from './state/user.reducer';
import { UserEffects } from './state/user.effects';
import { ManageClientRolesComponent } from '../system-administration/dialogs/manage-client-roles/manage-client-roles.component';

@NgModule({
  declarations: [ManageUserComponent, ManageUserRolesComponent, ManageClientRolesComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MaterialModule,
    MatCheckboxModule,
    StoreModule.forFeature('user', userReducer),
    EffectsModule.forFeature([UserEffects]),
  ],
})
export class UsersModule {}
