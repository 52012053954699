/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ModelCase } from './modelCase';

export interface Biometric { 
    id?: string;
    caseId?: string;
    _case?: ModelCase;
    twoThumbsFileId?: string;
    twoThumbs?: any;
    leftFourFingersFileId?: string;
    leftFourFingers?: any;
    rightFourFingersFileId?: string;
    rightFourFingers?: any;
    isAnonymized?: boolean;
}