import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/core/app-config/app-config.service';
import { CreateDisclaimerDto } from 'src/app/case/models/createDisclaimerDto';
import { DisclaimerDto } from 'src/app/case/models/disclaimerDto';
import { UserPreferencesDto } from 'src/app/case/models/UserPreferencesDto';
import { FlagDto } from 'src/app/case/models/flagDto';
import { ReferenceTypes } from 'src/app/case/models/referenceTypes';
import { PermissionGroupDto } from '../models/permissionGroup';
import { CaseWorkerDto } from '../../case/models';
import { UsersService as ApiUsersService, CaseWorkerListDto, TaskType } from 'swagger';
import { Observable } from 'rxjs';

const country_regex = new RegExp('(?<=/country:)(.*?)(?=/|$)');
const agency_regex = new RegExp('(?<=/agency:)(.*?)(?=/|$)');
const department_regex = new RegExp('(?<=/department:).*');

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiBaseUrl = this.config.getConfig().api.baseUrl;
  usersPath = '/api/users';

  public static loggedInUserId: string;
  public static loggedInUserCountryCode: string;
  public static loggedInUserAgency: string | null;
  public static loggedInUserDepartment: string | null;
  public static loggedInUserAgencyFull: string | null;
  public static loggedInUserDepartmentFull: string | null;
  public static loggedInUserRoles: string[];
  public static loggedInUserPermissions: string[];
  public static loggedInUserName: string;
  public isAzerbaijan = false;

  constructor(
    private keycloakService: KeycloakService,
    private http: HttpClient,
    private config: AppConfigService,
    private apiUsersService: ApiUsersService,
  ) {}

  public initLoggedInUsed(): void {
    const keycloakInstance = this.keycloakService.getKeycloakInstance();
    keycloakInstance.loadUserInfo().then((res) => {
      const user = res as object;
      type ObjectKey = keyof typeof user;
      const groups = user['groups' as ObjectKey] as string[];
      const roles = user['roles' as ObjectKey] as string[];
      const permissions = user['permissions' as ObjectKey] as string[];

      UserService.loggedInUserRoles = roles;
      UserService.loggedInUserPermissions = permissions;
      UserService.loggedInUserId = user['sub' as ObjectKey] as string;
      UserService.loggedInUserName = user['name' as ObjectKey];

      const countryGroup = groups.find((group: string) => group.includes('country'));
      const agencyGroup = groups.find((group: string) => group.includes('agency'));
      const departmentGroup = groups.find((group: string) => group.includes('department'));

      const country = countryGroup?.match(country_regex)?.pop();
      const agency = agencyGroup?.match(agency_regex)?.pop();
      const department = departmentGroup?.match(department_regex)?.pop();

      UserService.loggedInUserCountryCode = country!;
      UserService.loggedInUserAgency = agency ? agency.toLowerCase() : null;
      UserService.loggedInUserDepartment = department ? department.toLowerCase() : null;

      UserService.loggedInUserAgencyFull = agency ? 'agency:' + agency : null;
      UserService.loggedInUserDepartmentFull = department ? 'department:' + department : null;

      // TODO: Handle filtering of country list
      // when creating case in backend.
      if (UserService.loggedInUserCountryCode === 'azerbaijan') {
        this.isAzerbaijan = true;
      }
    });
  }

  public getIsAuthorizedToViewCase(caseId: string): Observable<boolean> {
    return this.apiUsersService.canViewCase(caseId);
  }

  keycloakUserInfo() {
    const instance = this.keycloakService.getKeycloakInstance();
    return instance.loadUserInfo();
  }

  getLoggedInUserCountryCode() {
    return UserService.loggedInUserCountryCode;
  }

  getLoggedInUserDepartment() {
    return UserService.loggedInUserDepartment;
  }

  getLoggedInUserAgency() {
    return UserService.loggedInUserAgency;
  }

  getLoggedInUserDepartmentFull() {
    return UserService.loggedInUserDepartmentFull;
  }

  getLoggedInUserAgencyFull() {
    return UserService.loggedInUserAgencyFull;
  }

  getLoggedInUserRoles() {
    return UserService.loggedInUserRoles;
  }

  getLoggedInUserPermissions() {
    return UserService.loggedInUserPermissions;
  }

  getLoggedInUserId() {
    return UserService.loggedInUserId;
  }

  getLoggedInUserName() {
    return UserService.loggedInUserName;
  }

  loggedInUserHasRole(role: string) {
    if (UserService.loggedInUserRoles != undefined) {
      const found = UserService.loggedInUserRoles.find((r: string) => r.toLocaleLowerCase() === role.toLocaleLowerCase());
      return found != undefined;
    }
    return false;
  }

  getDisclaimer(disclaimerId: string) {
    const path = '/api/users/disclaimer/' + disclaimerId;
    return this.http.get(this.apiBaseUrl + path);
  }

  updateDisclaimer(disclaimer: DisclaimerDto) {
    const path = '/api/users/disclaimer/';
    return this.http.put(this.apiBaseUrl + path, disclaimer);
  }

  createDisclaimer(disclaimer: CreateDisclaimerDto) {
    const path = '/api/users/disclaimer/';
    return this.http.post(this.apiBaseUrl + path, disclaimer);
  }

  getUserPreferences() {
    const path = '/api/users/userpreferences';
    return this.http.get<UserPreferencesDto>(this.apiBaseUrl + path);
  }

  updateUserPreferences(userPreferences: UserPreferencesDto) {
    const path = '/api/users/userpreferences';
    return this.http.put<UserPreferencesDto>(this.apiBaseUrl + path, userPreferences);
  }

  createUserFlag(flag: FlagDto) {
    const path = '/api/users/flag';
    return this.http.post<FlagDto>(this.apiBaseUrl + path, flag);
  }

  getAllUserCaseFlags() {
    const path = '/api/users/flag/';
    return this.http.get<FlagDto[]>(this.apiBaseUrl + path + ReferenceTypes.case);
  }

  deleteCaseFlag(caseId: string) {
    const path = '/api/users/flag/';
    return this.http.delete(this.apiBaseUrl + path + caseId);
  }

  getCaseWorkers() {
    const path = '/api/users/caseworkers';
    return this.http.get<any[]>(this.apiBaseUrl + path);
  }

  getAllPermissionGroups() {
    const path = '/api/users/permissionGroups';
    return this.http.get<PermissionGroupDto[]>(this.apiBaseUrl + path);
  }

  getPermissionGroupsByAgencyId(agencyId: number) {
    const path = '/api/users/permissionGroups/' + agencyId;
    return this.http.get<PermissionGroupDto[]>(this.apiBaseUrl + path);
  }

  updadeUserPermissions(caseWorkerId: string, permissions: PermissionGroupDto[]) {
    const path = '/api/users/caseworker/' + caseWorkerId;
    return this.http.put(this.apiBaseUrl + path, permissions);
  }

  getTaskRecipients(taskType: TaskType, caseId: string): Observable<CaseWorkerListDto[]> {
    return this.apiUsersService.getTaskReceipients(taskType, caseId);
  }

  getUserToAssignCaseList(isReceivedCases: boolean) {
    const path = `/UserListToAllocateCase/${isReceivedCases}`;
    return this.http.get<CaseWorkerDto[]>(this.apiBaseUrl + this.usersPath + path);
  }
}
