import {EventEmitter, Injectable} from '@angular/core';
import {interval, startWith, Subscription, switchMap} from 'rxjs';
import {NotificationsService} from 'src/app/shared/services/notifications.service';
import {NotificationDto} from './models/notificationDto';

@Injectable({
  providedIn: 'root'
})
export class NotificationsStateService {
  notifications: NotificationDto[];
  refreshStatus = new EventEmitter<boolean>();
  timerSubscription: Subscription;

  get notificationsCount() {
    return this.notifications.length;
  }

  get notificationsCountRead() {
    if (this.notifications) {
      return this.notifications.filter(n => n.isRead).length;
    }
    return 0;
  }

  get notificationsCountNotRead() {
    if (this.notifications) {
      return this.notifications.filter(n => !n.isRead).length;
    }
    return 0;
  }

  constructor(
    private notificationsService: NotificationsService
  ) {
  }

  getUserNotifications() {
    this.notificationsService.getUserNotifications().subscribe({
      next: (notifications) => {
        this.sortNotifications(notifications);
      }
    });
  }

  pollUserNotifications() {
    this.timerSubscription = interval(60000)
      .pipe(
        startWith(0),
        switchMap(() => this.notificationsService.getUserNotifications())
      )
      .subscribe(notifications => this.sortNotifications(notifications));
  }

  private sortNotifications(notifications: NotificationDto[]) {
    this.notifications = notifications.sort((a, b) => {
      if (b.created > a.created) {
        return 1;
      }
      if (b.created < a.created) {
        return -1;
      }
      return 0;
    });
    this.refreshStatus.emit(true);
  }

}
