/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DayOfWeek } from './dayOfWeek';

export interface CreateNonWorkPeriodDto { 
    startDate?: Date;
    endDate?: Date;
    calendarId?: number;
    weekDays?: Array<DayOfWeek>;
}