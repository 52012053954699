/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ProcessMinuteResponseType } from './processMinuteResponseType';
import { Task } from './task';
import { TransmitStatus } from './transmitStatus';

export interface TaskResponse { 
    id?: string;
    taskId?: string;
    task?: Task;
    body?: string;
    processMinuteResponseType?: ProcessMinuteResponseType;
    attachments?: Array<any>;
    createdBy?: string;
    transmitStatus?: TransmitStatus;
    createdDate?: Date;
    isApproved?: boolean;
}