import { Component } from '@angular/core';
import { UtilService } from '@shared/services';
import { first } from 'rxjs';
import { AddTranslationDto, AvailableTranslationDto, TranslationService } from 'swagger';

@Component({
  selector: 'app-language-administration',
  templateUrl: './language-administration.component.html',
})
export class LanguageAdministrationComponent {
  availableTranslations: AvailableTranslationDto[];

  public constructor(
    private translationService: TranslationService,
    private utilService: UtilService,
  ) {}

  public ngOnInit(): void {
    this.fetchAvailableTranslations();
  }

  protected fetchAvailableTranslations(): void {
    this.translationService
      .getActivatedTranslations(false)
      .pipe(first())
      .subscribe((res: AvailableTranslationDto[]) => (this.availableTranslations = res));
  }

  protected importTranslation(event: any): void {
    const file = event.target.files[0];
    event.target.value = '';

    if (file as File) {
      this.utilService
        .fileToBase64(file)
        .pipe(first())
        .subscribe((res: string) => {
          let translationFileBase64: AddTranslationDto = {
            jsonDocumentBase64: res,
          };

          this.translationService
            .postTranslation(translationFileBase64)
            .pipe(first())
            .subscribe(() => window.location.reload());
        });
    }
  }
}
