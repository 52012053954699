<div class="selected-files" *ngFor="let selectedFile of selectedFiles; let i = index">
  <a href="#" (click)="downloadFile($event, i)">
    {{ selectedFile.fileName }} ({{ selectedFile.sizeInBytes! | fileSize }})
  </a>
    <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="!viewOnly"
    (click)="removeFile(i)">
      <mat-icon color="warn">delete</mat-icon>
  </button>
</div>

<div class="buttons" *ngIf="!viewOnly && !(!multiple && selectedFiles.length > 0)">
  <input #fileInput mat-raised-button type="file" (click)="fileInput.value = ''" value="" accept="{{validFileExtensions}}"
  (change)="addFile($event);" [multiple]="multiple" hidden>

  <button type="button" mat-raised-button value="Choose File"
      (click)="fileInput.click()">{{"ACTIONS.UPLOAD" | translate}}</button>
</div>
