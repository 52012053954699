
    <div class="dialog-header">
        <h2 mat-dialog-title> {{ "USER_ADMINISTRATION.ROLES" | translate }}</h2>
    </div>



<mat-dialog-content>
    <mat-form-field appearance="fill" class="w-100" disableOptionCentering="true">
        <mat-label>{{ "USER_ADMINISTRATION.ROLES" | translate }}</mat-label>
        <mat-select [formControl]="states" multiple [(value)]="selectedRoles" disableOptionCentering="true">
            <mat-option *ngFor="let role of allRoles" [value]="role.id">{{role.role}}</mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-button color="primary" mat-dialog-close (click)="close()">
        <mat-icon>cancel</mat-icon> {{"ACTIONS.CANCEL" | translate}}
    </button>
    <button mat-button color="primary" cdkFocusInitial (click)="save()">
        <mat-icon>check_circle</mat-icon> {{"ACTIONS.DONE" | translate}}
    </button>
</mat-dialog-actions>