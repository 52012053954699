/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RelationshipType } from './relationshipType';
import { SexType } from './sexType';

export interface FamilyMemberDto { 
    id?: string;
    givenName?: string;
    surname?: string;
    maidenName?: string;
    dateOfBirth?: string;
    placeOfBirth?: string;
    placeOfBirthRegionCity?: string;
    alias?: string;
    languageSpoken?: string;
    relationship?: RelationshipType;
    nationalityCountryCode?: string;
    physicalDescription?: string;
    sex?: SexType;
}