/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BiometricDto } from './biometricDto';
import { CaseStatus } from './caseStatus';
import { CaseType } from './caseType';
import { CivilStatus } from './civilStatus';
import { CorrespondenceDto } from './correspondenceDto';
import { FamilyMemberDto } from './familyMemberDto';
import { FileDto } from './fileDto';
import { InterviewDto } from './interviewDto';
import { MeansOfEvidenceDto } from './meansOfEvidenceDto';
import { OutcomeDto } from './outcomeDto';
import { RelatedRecordDto } from './relatedRecordDto';
import { SecondaryCaseStatus } from './secondaryCaseStatus';
import { SexType } from './sexType';
import { TransferModalityDto } from './transferModalityDto';
import { TransitOperationDto } from './transitOperationDto';
import { TransmitStatus } from './transmitStatus';
import { UserGroupDto } from './userGroupDto';

export interface CaseDto { 
    id?: string;
    requestingStateCaseReferenceNo?: string;
    requestedStateCaseReferenceNo?: string;
    givenName?: string;
    surname?: string;
    maidenName?: string;
    civilStatus?: CivilStatus;
    sex?: SexType;
    dateOfBirth?: string;
    placeOfBirth?: string;
    placeOfBirthRegionCity?: string;
    acceleratedProcedure?: boolean;
    interviewRequest?: boolean;
    readmissionInError?: boolean;
    physicalDescription?: string;
    returneeFullName?: string;
    alias?: string;
    languageSpoken?: string;
    nationalityCountryCode?: string;
    lastAddress?: string;
    submittingAgencyReference?: string;
    stateOfHealth?: string;
    observations?: string;
    indicationOfDangerousPerson?: string;
    submittedBy?: string;
    receivingCountryCode?: string;
    sentByCountryCode?: string;
    caseType?: CaseType;
    caseStatus?: CaseStatus;
    secondaryCaseStatus?: SecondaryCaseStatus;
    travelDocumentType?: string;
    documentNo?: string;
    createdDate?: Date;
    photographFileId?: string;
    photograph?: FileDto;
    deadline?: Date;
    transitOperation?: TransitOperationDto;
    outcome?: OutcomeDto;
    biometrics?: BiometricDto;
    assignedUserId?: string;
    transmitStatus?: TransmitStatus;
    assignedGroups?: Array<string>;
    transferModality?: TransferModalityDto;
    correspondences?: Array<CorrespondenceDto>;
    familyMembers?: Array<FamilyMemberDto>;
    relatedRecords?: Array<RelatedRecordDto>;
    meansOfEvidences?: Array<MeansOfEvidenceDto>;
    isRequestingCountry?: boolean;
    interview?: InterviewDto;
    caseAllocationRemark?: string;
    reactivated?: boolean;
    receivedByAgency?: UserGroupDto;
    submittedByAgency?: UserGroupDto;
    hasTranslationCompleted?: boolean;
    receivedByGroupId?: string;
    submittedByGroupId?: string;
}