import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogData } from './confirmation-dialog.interface';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
  ) {
    if (this.data) {
      if (!this.data.title) {
        this.data.title = this.translate.instant('CONFIRMATION_DIALOG.CONFIRMATION');
      }

      if (this.data.appendDefaultMessage) {
        const defaultMessage = this.translate.instant('CONFIRMATION_DIALOG.ARE_YOU_SURE');
        this.data.message = defaultMessage + (this.data.message ? ' ' + this.data.message : '') + '?';
      }
    }
  }

  public approve(): void {
    this.dialogRef.close(true);
  }

  public reject(): void {
    this.dialogRef.close(false);
  }
}
