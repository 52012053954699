import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { NotificationDto } from '../models/notificationDto';
import { NotificationsStateService } from '../notifications-state.service';

@Component({
  selector: 'app-notification-box',
  templateUrl: './notification-box.component.html',
  styleUrls: ['./notification-box.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-360deg)' })),
      transition('rotated => default', animate('1000ms ease-out')),
      transition('default => rotated', animate('1000ms ease-in'))
    ])
  ]
})

export class NotificationBoxComponent implements OnInit {
  get notifications() { return this.notificationsStateService.notifications; }
  get notificationsCount() { return this.notificationsStateService.notificationsCount; }
  get notificationsCountRead() { return this.notificationsStateService.notificationsCountRead; }
  get notificationsCountNotRead() { return this.notificationsStateService.notificationsCountNotRead; }
  notificationsFiltered: NotificationDto[];
  state = 'default';
  loaded = false;
  typeOfFilter = 'all';

  constructor (
    private notificationsStateService: NotificationsStateService,
    private notificationsService: NotificationsService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private notifyUserService: NotificationService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.notificationsStateService.refreshStatus.asObservable().subscribe(res => {
      if (res) {
        this.notificationsFiltered = this.notifications;
        this.loaded = true;
      }
    });

  }

  setAllRead() {
    this.notificationsService.setAllUserNotificationsToRead().subscribe(() => {
      this.notificationsStateService.getUserNotifications();
      this.notifications.forEach(n => n.isRead = true);
      this.notificationsFiltered = this.notifications;
    });
  }

  refreshNotifications() {
    this.state = (this.state === 'default' ? 'rotated' : 'default');
    this.notificationsStateService.getUserNotifications();
  }

  filterRead(value: boolean | null) {
    if (value != null) {
      this.notificationsFiltered = this.notifications.filter(n => this.searchNotification(n, String(value)));
      this.typeOfFilter = (value ? 'read' : 'unread');
    }
    else {
      this.typeOfFilter = 'all';
      this.notificationsFiltered = this.notifications;
    }
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    const newNotifications = this.notifications.map((n) => {
      const notificationTypeStr = this.translate.instant('NotificationType.' + n.type);

      return {
        ...n
        , caseReference: n.caseReference.toLowerCase()
        , createdStr: this.parseDate(n.created!)
        , notificationTypeStr: notificationTypeStr.toLowerCase()
      };
    });

    this.notificationsFiltered = newNotifications.filter(n => {
      const values = JSON.stringify(Object.values(n));
      return values.includes(filterValue.trim().toLowerCase());

    });
  }

  parseDate(unformattedDate: Date) {
    return this.datePipe.transform(unformattedDate, 'yyyy-MM-dd HH:mm');
  }

  searchNotification(notification: NotificationDto, filterValue: string) {
    for (const [key, value] of Object.entries(notification)) {
      if (value !== null && value.toString().includes(filterValue)) {
        return true;
      }
    }
    return false;
  }

  deleteAllNotifications() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('ACTIONS.DELETE_ALL').toLowerCase() +
        ' ' + this.translate.instant('NOTIFICATIONS.NOTIFICATIONS').toLowerCase(),
        appendDefaultMessage: true
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.notificationsService.deleteAllUserNotifications()
          .subscribe({
            error: (err: Error) => {
              this.notifyUserService.showError(this.translate.instant('ERROR'), '');
            },
            complete: () => {
              this.notifyUserService.showSuccess(this.translate.instant('ACTION_SUCCESFULLY_COMPLETED'), '');
              this.refreshNotifications();
            }
          });
      }
    });
  }

}
