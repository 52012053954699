<mat-toolbar color="primary" class="wrapper">

  <div>
    <button mat-button (click)="toggleSidenav.emit()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <div class="middle-section">
    <span class="fi fi-{{flagCountryCode}} flag-size"></span>
    <div>
      <p id="header-top-title"> {{"HEADER.TOP_TITLE" | translate}} </p>
      <p id="header-bottom-title"> {{"HEADER.BOTTOM_TITLE" | translate}} </p>
    </div>
    <span *ngIf="!agencyEmblem" class="fi fi-{{fallbackFlagCountryCode}} flag-size"></span>
    <img *ngIf="agencyEmblem" [src]="agencyEmblem" alt="Agency emblem">
  </div>

  <div>
    <button mat-icon-button matTooltip="{{'HEADER.SYSTEM_STATUS' | translate}}" (click)="openSystemStatus()">
      <mat-icon *ngIf="systemStatusList" [ngStyle]="{'color': systemStatusList.statusColor}">circle</mat-icon> 
    </button>

    <select #selLang (change)="switchLang(selLang.value)">
      <option *ngIf="translate.currentLang" disabled selected hidden>
        {{ translate.currentLang | languageCodeToName }}
      </option>
      <option *ngIf="!translate.currentLang" disabled selected hidden>
        {{ translate.getDefaultLang()| languageCodeToName }}
      </option>
      <option *ngFor="let language of translate.getLangs()" [value]="language">
        {{ language | languageCodeToName }}
      </option>
    </select>

    <button mat-button routerLink="/help-center">
      <mat-icon>help</mat-icon>
    </button>

    <button mat-button [matMenuTriggerFor]="menu1">
      <mat-icon>notifications_none</mat-icon>
      <span [matBadgeHidden]="notificationsCount == 0" [matBadge]="notificationsCount" matBadgeOverlap="false"
        matBadgeColor="accent"></span>
    </button>

    <mat-menu #menu1="matMenu" class="notification-menu">
      <app-notification-box></app-notification-box>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="menu2">
      <mat-icon>perm_identity</mat-icon>
    </button>
    <mat-menu #menu2="matMenu">
      <span *ngIf="loggedInUserName" class="user-name">{{loggedInUserName}}</span>
      <button mat-menu-item (click)="openNotificationPreferences()">
        {{"NOTIFICATION_PREFERENCES.NOTIFICATION_PREFERENCES" | translate}}</button>
      <button mat-menu-item (click)="changePassword()">
        {{"ACTIONS.CHANGE_PASSWORD" | translate}}</button>
      <button mat-menu-item (click)="logout()">{{"ACTIONS.LOG_OUT" | translate }}</button>
    </mat-menu>
  </div>

</mat-toolbar>
