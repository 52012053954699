import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translationSort',
})
export class TranslationSortPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(items: any[], currentLanguage = 'en', translationKey = 'COUNTRY', translationProperty = 'code'): any[] {
    if (!items || !items.length) {
      return items;
    }
    const sortedItems = [...items];

    sortedItems.sort((a, b) => {
      const translationA = this.translateService.instant(translationKey + '.' + a[translationProperty], { locale: currentLanguage });
      const translationB = this.translateService.instant(translationKey + '.' + b[translationProperty], { locale: currentLanguage });

      return translationA.localeCompare(translationB, currentLanguage, { sensitivity: 'base' });
    });

    return sortedItems;
  }
}
