import { Component, Input } from '@angular/core';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { NotificationDto } from '../models/notificationDto';
import { NotificationsStateService } from '../notifications-state.service';
import { RoutingService, UtilService } from '@shared/services';
import { NotificationCategory, NotificationType } from 'swagger';

@Component({
  selector: 'app-notification-row',
  templateUrl: './notification-row.component.html',
  styleUrls: ['./notification-row.component.scss'],
})
export class NotificationRowComponent {
  NoticationCategory = NotificationCategory;
  NotificationTypes = NotificationType;
  @Input() notification: NotificationDto;

  constructor (
    private notificationsStateService: NotificationsStateService,
    private notificationsService: NotificationsService,
    private utilService: UtilService,
    private routingService: RoutingService
  ) { }

  getNotificationTypeClass(type: NotificationType): string {
    switch (type) {
      case NotificationType.CaseCreated:
        return 'case-added';
      case NotificationType.CorrespondenceAdded:
        return 'correspondence-added';
      case NotificationType.TransferModalitiesAdded:
        return 'transfermodalities-added';
      case NotificationType.TransferModalitiesUpdated:
        return 'transfermodalities-updated';
      case NotificationType.OutcomeMade:
      case NotificationType.OutcomeDetermined:
        return 'decision-added';
      case NotificationType.GeneralTaskAdded:
      case NotificationType.TranslationTaskAdded:
      case NotificationType.ProcessMinuteTaskAdded:
      case NotificationType.RequestTaskAdded:
        return 'task-added';
      case NotificationType.CaseReallocated:
        return 'case-reallocated';
      case NotificationType.CaseUpdated:
        return 'case-updated';
      case NotificationType.NewReturnIndication:
        return 'new-return-indication';
      case NotificationType.NewTransitIndication:
        return 'new-transit-indication';
      case NotificationType.GeneralTaskCompleted:
        return 'general-task-completed';
      case NotificationType.TranslationTaskCompleted:
        return 'translation-task-completed';
      case NotificationType.CaseFailedTransmission:
      case NotificationType.CorrespondenceFailedTransmission:
      case NotificationType.TransferModalityFailedTransmission:
      case NotificationType.CaseOutcomeFailedTransmission:
      case NotificationType.InterviewFailedTransmission:
        return 'failed-transmission';
      case NotificationType.NewCaseCorrespondenceReply:
        return 'new-case-correspondence-reply';
      case NotificationType.NewCaseWithInterviewRequest:
        return 'new-case-with-interview-request-added';
      case NotificationType.NewCaseWithReadmissionInError:
        return 'new-case-with-readmission-in-error-added';
      case NotificationType.ProcessMinuteTaskCompleted:
        return 'process-minute-task-completed';
      case NotificationType.RequestTaskCompleted:
        return 'request-task-completed';
      case NotificationType.Register:
      case NotificationType.SendVerifyEmail:
      case NotificationType.SendResetPassword:
      case NotificationType.VerifyEmail:
        return 'new-keycloak-event';
      case NotificationType.InterviewScheduled:
      case NotificationType.InterviewRescheduled:
        return 'interview-scheduled-status';
      case NotificationType.InterviewConfirmed:
        return 'approved-status';
      case NotificationType.InterviewCompleted:
        return 'interview-completed-status';
      case NotificationType.CasePutOnHold:
        return 'on-hold-status';
      case NotificationType.CaseReactivated:
        return 'reactivated-status';
      case NotificationType.CaseAnonymizationReminder:
      case NotificationType.CaseDeadlineReminder:
      case NotificationType.CaseAllocationReminder:
      case NotificationType.TaskDeadlineReminder:
      case NotificationType.TransitIndicationReminder:
        return 'reminder-status';
      case NotificationType.OutOfSyncCaseClosed:
        return 'out-of-sync';
      case NotificationType.ProcessMinuteTaskUpdated:
      case NotificationType.GeneralTaskUpdated:
      case NotificationType.RequestTaskUpdated:
      case NotificationType.TranslationTaskUpdated:
        return 'task-updated';
      default:
        return '';
    }
  }
  setRead(notification: NotificationDto, value: boolean) {
    notification.isRead = value;
    this.notificationsService.updateNotification(notification).subscribe((res) => {
      if (res) {
        this.notificationsStateService.getUserNotifications();
      }
    });
  }

  goToCase(notification: NotificationDto) {
    this.routingService.goToCase(notification.caseId!);
  }

  deleteNotification(id: any) {
    this.notificationsService.deleteNotification(id).subscribe(() => {
      this.notificationsStateService.getUserNotifications();
    });
  }
}
