/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CaseStatus } from './caseStatus';
import { CaseType } from './caseType';
import { CivilStatus } from './civilStatus';
import { CountryDto } from './countryDto';
import { InterviewStatus } from './interviewStatus';
import { SecondaryCaseStatus } from './secondaryCaseStatus';
import { SexType } from './sexType';
import { UserGroupDto } from './userGroupDto';

export interface CaseListDto { 
    id?: string;
    givenName?: string;
    surname?: string;
    maidenName?: string;
    civilStatus?: CivilStatus;
    sex?: SexType;
    dateOfBirth?: string;
    placeOfBirth?: string;
    placeOfBirthRegionCity?: string;
    acceleratedProcedure?: boolean;
    interviewRequest?: boolean;
    readmissionInError?: boolean;
    physicalDescription?: string;
    alias?: string;
    languageSpoken?: string;
    nationalityCountryCode?: string;
    lastAddress?: string;
    submittingAgencyReference?: string;
    stateOfHealth?: string;
    observations?: string;
    indicationOfDangerousPerson?: string;
    readonly returneeFullName?: string;
    assignedUserId?: string;
    deadline?: Date;
    submittedBy?: string;
    receivingCountryCode?: string;
    receivingCountry?: CountryDto;
    sentByCountryCode?: string;
    sentByCountry?: CountryDto;
    caseType?: CaseType;
    caseStatus?: CaseStatus;
    travelDocumentType?: string;
    documentNo?: string;
    createdDate?: Date;
    assignedCaseWorkerName?: string;
    taskProgress?: string;
    generalTasks?: string;
    processMinutes?: string;
    requests?: string;
    translation?: string;
    internalStatus?: SecondaryCaseStatus;
    requestingStateCaseReferenceNo?: string;
    requestedStateCaseReferenceNo?: string;
    requestedAgency?: UserGroupDto;
    requestingAgency?: UserGroupDto;
    interviewStatus?: InterviewStatus;
    caseAllocationRemark?: string;
    receivedByGroupId?: string;
}