/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FileDto } from './fileDto';
import { TranslatedComment } from './translatedComment';
import { UserGroupDto } from './userGroupDto';

export interface CommentDto { 
    id?: string;
    correspondenceId?: string;
    parentId?: string;
    body?: string;
    sentByUser?: string;
    sentByCountryCode?: string;
    sentByAgencyId?: string;
    sentByAgency?: UserGroupDto;
    sentByDepartmentId?: string;
    sentByDepartment?: UserGroupDto;
    userId?: string;
    attachments?: Array<FileDto>;
    createdAt?: Date;
    translatedComment?: TranslatedComment;
}