import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '@shared/shared.module';
import { AuthGuard } from '@shared/services/authguard';

import { RouterModule, Routes } from '@angular/router';
import { WorkflowComponent } from './pages';
import { WorkflowAdminComponent, WorkflowCreateComponent, WorkflowDeadlineTemplateComponent, WorkflowEditComponent } from './components/';

const routes: Routes = [
  {
    path: '',
    component: WorkflowComponent,
    canActivate: [AuthGuard],
  },
];

const components = [WorkflowAdminComponent, WorkflowCreateComponent, WorkflowEditComponent, WorkflowDeadlineTemplateComponent];
const pages = [WorkflowComponent];

@NgModule({
  declarations: [pages, components, WorkflowComponent, WorkflowCreateComponent, WorkflowEditComponent, WorkflowAdminComponent],
  imports: [CommonModule, MaterialModule, SharedModule, RouterModule.forChild(routes)],
  providers: [AuthGuard],
})
export class WorkflowModule {}
