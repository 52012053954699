/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type CaseStatus = 'Processing' | 'Submitted' | 'Saved' | 'Approved' | 'Rejected' | 'Cancelled' | 'ReturnSuccessful' | 'ReturnFailed' | 'Closed' | 'TransitComplete' | 'TransitIncomplete' | 'Archived' | 'Received' | 'OnHold' | 'Reactivated' | 'ReSubmitted' | 'ReturnScheduled';

export const CaseStatus = {
    Processing: 'Processing' as CaseStatus,
    Submitted: 'Submitted' as CaseStatus,
    Saved: 'Saved' as CaseStatus,
    Approved: 'Approved' as CaseStatus,
    Rejected: 'Rejected' as CaseStatus,
    Cancelled: 'Cancelled' as CaseStatus,
    ReturnSuccessful: 'ReturnSuccessful' as CaseStatus,
    ReturnFailed: 'ReturnFailed' as CaseStatus,
    Closed: 'Closed' as CaseStatus,
    TransitComplete: 'TransitComplete' as CaseStatus,
    TransitIncomplete: 'TransitIncomplete' as CaseStatus,
    Archived: 'Archived' as CaseStatus,
    Received: 'Received' as CaseStatus,
    OnHold: 'OnHold' as CaseStatus,
    Reactivated: 'Reactivated' as CaseStatus,
    ReSubmitted: 'ReSubmitted' as CaseStatus,
    ReturnScheduled: 'ReturnScheduled' as CaseStatus
};