import { AppConfig } from './app-config.model';
import { Inject, Injectable, InjectionToken } from '@angular/core';

export const APP_CONFIG: InjectionToken<AppConfig> = new InjectionToken<AppConfig>('APP_CONFIG');

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  constructor(@Inject(APP_CONFIG) private readonly appConfig: AppConfig) {}

  getConfig(): AppConfig {
    return this.appConfig;
  }
}
