import { createAction, props } from '@ngrx/store';
import { UserPreferencesDto } from 'src/app/case/models/UserPreferencesDto';

export const loadUserPreferences = createAction(
  '[User Preferences Page Action] Load'
);

export const updateUserPreferences = createAction(
  '[User Preferences Page Action] Update User Preferences',
  props<{ userPreferences: UserPreferencesDto }>()
);

export const loadCaseFlags = createAction(
  '[User Case Flags Page Action] Load'
);

export const loadUserPermissions = createAction(
  '[User Permissions Page Action] Load'
);

export const loadKeycloakUserInformation = createAction(
  '[Keycloak User Information Page Action] Load'
);

export const loadInitialData = createAction(
  '[Initial Data Page Action] Load'
);