<div class="dialog-header">
    <h2 *ngIf="!user" mat-dialog-title> {{"USER_ADMINISTRATION.ADD_USER" | translate}}</h2>
    <h2 *ngIf="user" mat-dialog-title> {{"ACTIONS.EDIT_USER" | translate}}</h2>
</div>

<form [formGroup]="newUser" (ngSubmit)="onSubmit()" *ngIf="userRoles$ | async as userRoles">
    <mat-dialog-content>
        <div class="container">
            <mat-form-field>
                <mat-label>
                    {{ "USER_ADMINISTRATION.FIRST_NAME" | translate }}
                </mat-label>
                <input matInput type="text" formControlName="firstName">
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>
                    {{ "USER_ADMINISTRATION.LAST_NAME" | translate }}
                </mat-label>
                <input matInput type="text" formControlName="lastName">
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>
                    {{ "USER_ADMINISTRATION.EMAIL" | translate }}
                </mat-label>
                <input matInput type="text" formControlName="email">
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>
                    {{ "USER_ADMINISTRATION.USERNAME" | translate }}
                </mat-label>
                <input matInput type="text" formControlName="username">
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>
                    {{ "USER_ADMINISTRATION.ENABLE_ACCOUNT" | translate }}
                </mat-label>
                <mat-select matNativeControl formControlName="enabled">
                    <mat-option *ngFor="let option of enabledDropdown" [value]="option.value">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br>
            <app-multi-dropdown *ngIf="userRoles.includes(roleTypes.AdminUser)" groupName={{user?.groupName}}
                groupId={{user?.groupId}} (selectedGroupId)="selectedGroupHandler($event)"></app-multi-dropdown>
            <br />
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="center">
        <button *ngIf="!user" mat-button color="primary" cdkFocusInitial type="submit">
            <mat-icon class="add-person-icon">person_add_alt_1</mat-icon> {{"ACTIONS.ADD_USER" | translate}}
        </button>
        <button *ngIf="user" mat-button color="primary" cdkFocusInitial type="submit">
            <mat-icon class="add-person-icon">save</mat-icon> {{"ACTIONS.SAVE" | translate}}
        </button>
        <button mat-button color="primary" mat-dialog-close (click)="close()">
            <mat-icon>cancel</mat-icon> {{"ACTIONS.CANCEL" | translate}}
        </button>
    </mat-dialog-actions>

</form>