/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MeansOfEvidenceDocumentType } from './meansOfEvidenceDocumentType';
import { ModelCase } from './modelCase';

export interface MeansOfEvidence { 
    id?: string;
    documentNo?: string;
    dateOfIssuance?: string;
    expirationDate?: string;
    meansOfEvidenceDocumentType?: MeansOfEvidenceDocumentType;
    issuingAuthority?: string;
    placeOfIssuance?: string;
    isAnonymized?: boolean;
    caseId?: string;
    _case?: ModelCase;
    documentFileId?: string;
    document?: any;
}