<div class="dialog-header">
    <h2 mat-dialog-title> {{ "USER_ADMINISTRATION.ROLES" | translate }}</h2>
</div>

<form [formGroup]="roles">
    <div *ngFor="let item of roles.value | keyvalue">
        <mat-checkbox [checked]="getRoleValue(item)" (change)="onChange($event, item)" [disabled]="disableRole(item)">
            {{ formatRole(item.key) | spaceSeparator }}
        </mat-checkbox>
    </div>
</form>
<br>

<div class="dialog-header">
    <h2 mat-dialog-title>{{ "USER_ADMINISTRATION.PERMISSION_GROUPS" | translate }}</h2>
</div>

<form [formGroup]="defaultRolesForm" *ngIf="defaultRoles$ | async as defaultRoles">
    <div *ngIf="userCheckedDefaultRoles$ | async as userCheckedDefaultRoles">
        <div *ngFor="let defaultRole of defaultRoles">
            <mat-checkbox [checked]="isChecked(userCheckedDefaultRoles, defaultRole)"
                (change)="onDefaultRoleChange($event, defaultRole)">
                {{ defaultRole.formattedName ?? defaultRole.name }}
            </mat-checkbox>
        </div>
    </div>
</form>
<br>

<mat-dialog-actions align="center">
    <button mat-button color="primary" cdkFocusInitial (click)="close()">
        <mat-icon>check_circle</mat-icon> {{"ACTIONS.DONE" | translate}}
    </button>
</mat-dialog-actions>
