import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { KeycloakUserService } from 'src/app/shared/services/keycloak-user.service';
import { UserService } from 'src/app/shared/services/user.service';
import { KeycloakUserRole } from '../../models/keycloakRole';
import { KeycloakUser } from '../../models/keycloakUser';
import {RoleTypes} from '@shared/models/roles';

@Component({
  selector: 'app-manage-user-roles',
  templateUrl: './manage-user-roles.component.html',
  styleUrls: ['./manage-user-roles.component.scss']
})
export class ManageUserRolesComponent implements OnInit {
  @Input() user: KeycloakUser;

  availableRoles: KeycloakUserRole[];
  currentRoles: KeycloakUserRole[];
  currentDefaultRoles: KeycloakUserRole[];

  selectedRoles: FormGroup;
  roles: FormGroup;
  defaultRolesForm: FormGroup;
  loggedInUserIsPrimary = false;

  userCheckedDefaultRoles$: Observable<KeycloakUserRole[]>;
  defaultRoles$ = this.keycloakUserService.getKeycloakDefaultRoles();

  constructor(
    private keycloakUserService: KeycloakUserService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ManageUserRolesComponent>,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.userCheckedDefaultRoles$ = this.keycloakUserService.getKeycloakUserDefaultRoles(this.user.id!);
    this.roles = this.formBuilder.group({});

    this.keycloakUserService.getKeycloakRealmRoles()
      .subscribe(res => {
        this.availableRoles = res as KeycloakUserRole[];

        this.availableRoles.forEach(element => {
          this.roles.addControl(element.name, new FormControl(false));
        });

        this.keycloakUserService.getKeycloakUserRoles(this.user.id!)
          .subscribe(res => {
            this.currentRoles = res as KeycloakUserRole[];
            Object.keys(this.roles.controls).forEach(availableRole => {
              this.currentRoles.forEach(currentRole => {
                if (currentRole.name == availableRole) {
                  this.roles.controls[availableRole].setValue(true);
                  return;
                }
              });
            });
          });
      });

    this.defaultRolesForm = this.formBuilder.group({});

    this.keycloakUserService.getKeycloakDefaultRoles()
      .subscribe(res => {
        const defaultRoles = res as KeycloakUserRole[];

        defaultRoles.forEach(element => {
          this.defaultRolesForm.addControl(element.formattedName ?? element.name, new FormControl(false));
        });

        this.keycloakUserService.getKeycloakUserDefaultRoles(this.user.id!)
          .subscribe(res => {
            this.currentDefaultRoles = res as KeycloakUserRole[];
            Object.keys(this.defaultRolesForm.controls).forEach(availableRole => {
              this.currentDefaultRoles.forEach(currentRole => {
                if (currentRole.name == availableRole) {
                  this.defaultRolesForm.controls[availableRole].setValue(true);
                  return;
                }
              });
            });
          });
      });
  }

  isChecked(userCheckedDefaultRoles: KeycloakUserRole[], item: KeycloakUserRole) {
    const checked = userCheckedDefaultRoles.find(checkedDefaultRole => checkedDefaultRole.id == item.id);
    return checked != null;
  }
  
  getRoleValue(role: any): boolean {
    return role.value;
  }

  getRoleFromName(name: string): KeycloakUserRole | undefined {
    if (this.availableRoles == undefined) {
      return undefined;
    }

    return this.availableRoles.find(item => item.name == name);
  }

  disableRole(role: any): boolean {
    return this.user.id == this.userService.getLoggedInUserId() && role.key == RoleTypes.PrimaryUser;
  }

  onChange(event: any, role: any) {
    const roles: KeycloakUserRole[] = [];
    const roleToChange = this.getRoleFromName(role.key) as KeycloakUserRole;
    roles.push(roleToChange);

    if (event.checked) {
      this.keycloakUserService.addKeycloakUserRoles(this.user.id!, roles)
        .subscribe();
    } else {
      this.keycloakUserService.deleteKeycloakUserRoles(this.user.id!, roles)
        .subscribe();
    }
  }

  onDefaultRoleChange(event: any, role: any) {
    const roles: KeycloakUserRole[] = [];
    roles.push(role);

    if (event.checked) {
      this.keycloakUserService.addKeycloakUserDefaultRoles(this.user.id!, roles)
        .subscribe();
    } else {
      this.keycloakUserService.deleteKeycloakUserDefaultRoles(this.user.id!, roles)
        .subscribe();
    }
  }

  close() {
    this.dialogRef.close();
  }

  formatRole(str: any) {
    str = str.replace('_', ' ');
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }
}
