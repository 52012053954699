import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppConfig } from './app/core/app-config/app-config.model';
import { APP_CONFIG } from './app/core/app-config/app-config.service';
import { environment } from './environments/environment';

Promise.all([
  fetch('/assets/data/appConfig.json')
    .then(response => response.json() as unknown as AppConfig)
]).then((values) => {
  const config = values[0];
  
  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic([
    { provide: APP_CONFIG, useValue: config },
  ]).bootstrapModule(AppModule)
    .catch();
});
