/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TransmitStatus = 'Pending' | 'Failed' | 'Transmitted' | 'Success';

export const TransmitStatus = {
    Pending: 'Pending' as TransmitStatus,
    Failed: 'Failed' as TransmitStatus,
    Transmitted: 'Transmitted' as TransmitStatus,
    Success: 'Success' as TransmitStatus
};