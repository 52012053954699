import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HelpCenterService as ApiService, QuestionAndAnswerDto, UserManualListDto } from 'swagger';

@Injectable({
  providedIn: 'root',
})
export class HelpCenterService {

  public constructor(private apiService: ApiService) { }

  public getFaqs(languageCode: string): Observable<QuestionAndAnswerDto[]> {
    return this.apiService.getFAQs(languageCode);
  }

  public getSupportInfoList(languageCode: string): Observable<QuestionAndAnswerDto[]> {
    return this.apiService.getSupportInfoList(languageCode);
  }

  public getUserManuals(languageCode: string): Observable<UserManualListDto[]> {
    return this.apiService.getUserManualsList(languageCode);
  }
}
