<div class="dialog-header">
    <h2 mat-dialog-title> {{"ICAO.ICAO_RESULTS" |translate}}</h2>
</div>

<mat-dialog-content>
    <table mat-table [dataSource]="icao.results">
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> {{"ICAO.DESCRIPTION" |translate}} </th>
            <td mat-cell *matCellDef="let icaoResult"> {{icaoResult.name | translate}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> {{"ICAO.STATUS" |translate}} </th>
            <td mat-cell *matCellDef="let icaoResult">
                <span *ngIf="icaoResult.success">
                    <mat-icon class="green-icon">done</mat-icon>
                </span>
                <span *ngIf="!icaoResult.success">
                    <mat-icon class="red-icon">close</mat-icon>
                </span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-button color="primary" mat-dialog-close>
        <mat-icon>check_circle</mat-icon> {{"ACTIONS.DONE" | translate}}
    </button>
</mat-dialog-actions>
