/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TranslationSectionType = 'Case' | 'TransferModalities' | 'Correspondences' | 'CaseOutcome';

export const TranslationSectionType = {
    Case: 'Case' as TranslationSectionType,
    TransferModalities: 'TransferModalities' as TranslationSectionType,
    Correspondences: 'Correspondences' as TranslationSectionType,
    CaseOutcome: 'CaseOutcome' as TranslationSectionType
};