import {inject} from '@angular/core';
import { PermissionTypes } from '@shared/models/permissionTypes';
import { KeycloakUserService } from '@shared/services';

export const workshopAdminGuard = () => {
  const service = inject(KeycloakUserService)

  service.hasUserPermissionAccess(PermissionTypes.WorkshopAdmin).then((result: boolean) => {
    return result;
  });
}
