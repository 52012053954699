import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoaderService} from './loader.service';
import { ErrorService } from './error.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(
    private loaderService: LoaderService,
    private errorService: ErrorService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown> > {
    this.loaderService.handleRequest('plus');
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if(error.status === 0){
            this.errorService.handleError('plus');
          }
           return throwError(() => error);
        }),
        finalize(this.finalize.bind(this))
      );
  }

  finalize = (): void => this.loaderService.handleRequest();

}