import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './navigation/header/header.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { MaterialModule } from '../material/material.module';
import { ToolbarComponent } from './navigation/toolbar/toolbar.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { NotificationsModule } from '../notifications/notifications.module';
import { SystemstatustooltipComponent } from './navigation/toolbar/tooltips/systemstatustooltip/systemstatustooltip.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ToolbarComponent,
    SystemstatustooltipComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    RouterModule,
    NotificationsModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ToolbarComponent
  ]
})
export class CoreModule { }
