import { Injectable, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { LoginService, NotificationService, TaskService, UserService } from '@shared/services';
import { CasePageActions } from './actions';
import { Store } from '@ngrx/store';
import { State } from './index';
import {
  CaseDto as CaseDtoV1,
  CaseStatus,
  CaseType,
  InterviewStatus,
  OutcomeDto,
  SecondaryCaseStatus,
  TaskDto,
  TaskStatus,
  TaskType,
  TranslationSectionType,
} from 'swagger';
import { BehaviorSubject, Observable, Subject, filter, first, map, mergeMap, timeout } from 'rxjs';
import { CaseService } from '@shared/services/case.service';

@Injectable({
  providedIn: 'root',
})
export class CaseStateService implements OnDestroy {
  public caseStateDataLoaded = false;
  public viewCase = false; // If case exist then set it to true
  public editCase = false;
  public hasIndication = false;
  public isSharedOutcome = false;
  public sentByCountryCode: string | null;
  public caseStatus: CaseStatus | null;
  public secondarycaseStatus: SecondaryCaseStatus | null;
  public caseId: string | null = null;
  public caseType: CaseType | null;
  public isSubmitted = false;
  public isClosed = false;
  public isTranslatedVersion = false;
  public isModalitiesUpdated = false;
  public receivingCountryCode = '';
  public isRequestingCountry: boolean | null = null;
  public hasInterviewBoxChecked = false;
  public hasInterview = false;
  public caseReceivedByGroupId: string | undefined;
  public caseSubmittedByGroupId: string | undefined;
  private destroy$ = new Subject<boolean>();
  public caseData: CaseDtoV1 | null;
  public caseOutcome: OutcomeDto | undefined;
  public caseSubject$: BehaviorSubject<CaseDtoV1 | undefined> = new BehaviorSubject<CaseDtoV1 | undefined>(undefined);
  public case$: Observable<CaseDtoV1 | undefined> = this.caseSubject$.asObservable();
  private tasksSubject$: BehaviorSubject<TaskDto[] | undefined> = new BehaviorSubject<TaskDto[] | undefined>(undefined);
  public tasks$: Observable<TaskDto[] | undefined> = this.tasksSubject$.asObservable();

  public constructor(
    private actions: Actions,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private caseService: CaseService,
    private readonly store: Store<State>,
    private userService: UserService,
    private taskService: TaskService,
    private loginService: LoginService,
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public caseOutcomeExists() : boolean {
    return this.caseOutcome != undefined ;
  }

  public userIsInCaseAssignedGroupOrSentByGroup(): boolean {
    return this.loginService.loggedInUserGroupId == this.caseReceivedByGroupId || this.loginService.loggedInUserGroupId == this.caseSubmittedByGroupId;
  }

  public setExistingCaseStateValues(): void {
    this.actions.pipe(ofType(CasePageActions.updateCurrentCaseDataStateV1)).subscribe({
      error: () => {
        this.notificationService.showError(this.translate.instant('ERROR'), '');
      },
      next: (currentCaseDataAction) => {
        if (currentCaseDataAction && currentCaseDataAction.currentCaseData) {
          this.isModalitiesUpdated = currentCaseDataAction.currentCaseData.transferModality?.isUpdated ?? false;
          this.caseStatus = currentCaseDataAction.currentCaseData.caseStatus ?? null;
          this.secondarycaseStatus = currentCaseDataAction.currentCaseData.secondaryCaseStatus ?? null;
          this.isSharedOutcome = currentCaseDataAction.currentCaseData.outcome?.isShared == true;
          this.sentByCountryCode = currentCaseDataAction.currentCaseData.sentByCountryCode!;
          this.isRequestingCountry = currentCaseDataAction.currentCaseData.isRequestingCountry ?? null;
          this.hasInterviewBoxChecked = currentCaseDataAction.currentCaseData.interviewRequest ?? false;
          this.hasInterview = currentCaseDataAction.currentCaseData.interview != null;
          this.caseReceivedByGroupId = currentCaseDataAction.currentCaseData.receivedByGroupId;
          this.caseSubmittedByGroupId = currentCaseDataAction.currentCaseData.submittedByGroupId;
          this.caseOutcome = currentCaseDataAction.currentCaseData.outcome;

          if (this.caseStatus !== CaseStatus.Saved) {
            this.isSubmitted = true;
          }
          if (this.caseStatus == CaseStatus.Closed) {
            this.isClosed = true;
          }
          if (
            this.caseStatus == CaseStatus.ReturnSuccessful ||
            this.caseStatus == CaseStatus.ReturnFailed ||
            this.caseStatus == CaseStatus.TransitComplete ||
            this.caseStatus == CaseStatus.TransitIncomplete
          ) {
            this.hasIndication = true;
          }
          this.caseStateDataLoaded = true;
        }
        if (
          this.caseStatus == CaseStatus.ReturnSuccessful ||
          this.caseStatus == CaseStatus.ReturnFailed ||
          this.caseStatus == CaseStatus.TransitComplete ||
          this.caseStatus == CaseStatus.TransitIncomplete
        ) {
          this.hasIndication = true;
        }
        this.caseStateDataLoaded = true;
      },
    });
  }

  public resetValues(): void {
    this.caseStateDataLoaded = false;
    this.viewCase = false;
    this.editCase = false;
    this.hasIndication = false;
    this.isModalitiesUpdated = false;
    this.sentByCountryCode = null;
    this.caseStatus = null;
    this.secondarycaseStatus = null;
    this.caseId = null;
    this.caseType = null;
    this.isSubmitted = false;
    this.isTranslatedVersion = false;
    this.receivingCountryCode = '';
    this.isRequestingCountry = null;
    this.hasInterviewBoxChecked = false;
    this.hasInterview = false;
    this.caseData = null;
    this.caseSubject$.next(undefined);
  }

  public refreshCaseData(): void {
    if (this.caseId == null) {
      return;
    }
    this.caseService
      .getCase(this.caseId)
      .pipe(first())
      .subscribe((caseData) => {
        this.caseSubject$.next(caseData);
        this.refreshTasks();
        this.store.dispatch(CasePageActions.updateCurrentCaseDataStateV1({ currentCaseData: caseData as unknown as CaseDtoV1 }));
      });
  }

  public refreshTasks(): void {
    this.case$
      .pipe(
        timeout({ first: 10_000 }),
        filter((e: CaseDtoV1 | undefined) => e !== undefined),
        mergeMap((e: CaseDtoV1 | undefined) => this.taskService.getTasksByCaseId(e?.id as string).pipe(first())),
        first(),
      )
      .subscribe((e: TaskDto[]) => {
        this.tasksSubject$.next(e);
      });
  }

  public signalCase(caseDto: CaseDtoV1): void {
    this.caseSubject$.next(caseDto);
  }

  public selectIsTranslatable$(translationSectionType: TranslationSectionType): Observable<boolean> {
    return this.tasks$.pipe(
      filter((e: TaskDto[] | undefined) => e !== undefined),
      map(
        (e: TaskDto[] | undefined) =>
          e?.filter((e) => e.taskStatus !== TaskStatus.Completed)?.some((e) => e.taskData?.translationSection == translationSectionType) ?? false,
      ),
    );
  }

  public selectIsTranslationSectionCompleted$(translationSectionType: TranslationSectionType): Observable<boolean> {
    return this.tasks$.pipe(
      filter((e: TaskDto[] | undefined) => e !== undefined),
      map(
        (e: TaskDto[] | undefined) =>
          e?.filter((e) => e.taskStatus === TaskStatus.Completed)?.some((task) => task.taskData?.translationSection == translationSectionType) ??
          false,
      ),
    );
  }

  public selectIsCaseTranslationLanguage$(): Observable<string | undefined> {
    return this.tasks$.pipe(
      filter((e: TaskDto[] | undefined) => e !== undefined),
      map(
        (e: TaskDto[] | undefined) =>
          e?.filter((e) => e.taskStatus !== TaskStatus.Completed)?.find((task) => task.type === TaskType.Translate)?.taskData?.translatedTo,
      ),
    );
  }

  public getCaseStatusClass(caseStatus: CaseStatus = this.caseStatus!): string {
    switch (caseStatus) {
      case CaseStatus.Processing:
        return 'processing-status';
      case CaseStatus.Rejected:
      case CaseStatus.Cancelled:
        return 'rejected-status';
      case CaseStatus.Approved:
        return 'approved-status';
      case CaseStatus.Submitted:
      case CaseStatus.ReSubmitted:
        return 'submitted-status';
      case CaseStatus.Saved:
        return 'saved-status';
      case CaseStatus.ReturnSuccessful:
        return 'return-successful-status';
      case CaseStatus.ReturnFailed:
        return 'return-failed-status';
      case CaseStatus.TransitComplete:
        return 'transit-complete-status';
      case CaseStatus.TransitIncomplete:
        return 'transit-incomplete-status';
      case CaseStatus.Closed:
        return 'closed-status';
      case CaseStatus.Received:
        return 'received-status';
      case CaseStatus.OnHold:
        return 'on-hold-status';
      case CaseStatus.Reactivated:
        return 'reactivated-status';
      case CaseStatus.ReturnScheduled:
        return 'return-scheduled-status';
      case CaseStatus.Archived:
        return 'archived-status';
      default:
        return '';
    }
  }

  public getInterviewStatusClass(interviewStatus: InterviewStatus): string {
    switch (interviewStatus) {
      case InterviewStatus.Requested:
        return 'interview-requested-status';
      case InterviewStatus.Scheduled:
        return 'interview-scheduled-status';
      case InterviewStatus.Completed:
        return 'interview-completed-status';

      default:
        return '';
    }
  }

  public getStatusTranslation(caseStatus: CaseStatus = this.caseStatus!): string {
    return 'CaseStatus.' + caseStatus;
  }

  public isHoldCaseButtonVisible(): boolean | null {
    return this.isRequestingCountry && this.hasInterviewBoxChecked && this.caseStatus == CaseStatus.Processing;
  }

  public isReactivateCaseButtonVisible(): boolean | null {
    return this.isRequestingCountry && this.caseStatus == CaseStatus.OnHold;
  }

  public isResubmitCaseButtonVisible(): boolean | null {
    return this.isRequestingCountry && this.caseStatus == CaseStatus.Rejected;
  }

  public isReturnIndicationButtonVisible(): boolean {
    return (
      this.userService.getLoggedInUserCountryCode() == this.receivingCountryCode &&
      this.caseType === CaseType.Readmission &&
      this.isSharedOutcome &&
      this.caseStatus !== CaseStatus.Rejected &&
      this.caseStatus !== CaseStatus.Closed &&
      this.caseStatus !== CaseStatus.ReturnSuccessful &&
      !(this.caseStatus === CaseStatus.ReturnFailed && !this.isModalitiesUpdated)
    );
  }

  public isTransitIndicationButtonVisible(): boolean {
    return (
      this.userService.getLoggedInUserCountryCode() == this.receivingCountryCode &&
      this.caseType === CaseType.Transit &&
      this.isSharedOutcome &&
      !this.hasIndication &&
      this.caseStatus !== CaseStatus.Rejected &&
      this.caseStatus !== CaseStatus.Closed
    );
  }

  public isCloseCaseButtonVisible(): boolean {
    if (
      this.caseStatus == CaseStatus.ReturnFailed ||
      this.caseStatus == CaseStatus.ReturnSuccessful ||
      this.caseStatus == CaseStatus.TransitComplete ||
      this.caseStatus == CaseStatus.TransitIncomplete ||
      (this.caseStatus == CaseStatus.Rejected && this.isSharedOutcome)
    ) {
      return true;
    } else {
      return false;
    }
  }

  public isArchiveCaseButtonVisible(): boolean {
    return this.caseStatus === CaseStatus.Closed || this.isPartialArchived();
  }

  public isPartialArchived(): boolean {
    return this.caseStatus === CaseStatus.Archived;
  }

  public isAddTaskButtonVisible(): boolean {
    return (
      this.viewCase &&
      this.caseType !== CaseType.Return &&
      this.caseStatus !== CaseStatus.Saved &&
      this.caseStatus !== CaseStatus.Closed &&
      !this.isPartialArchived()
    );
  }

  public isTranslateCaseButtonVisible(): boolean {
    return this.viewCase && this.caseStatus !== CaseStatus.Saved;
  }

  public isSetOutcomeButtonVisible(): Observable<boolean> {
    return this.case$.pipe(
      first(),
      map((caseDto: CaseDtoV1 | undefined) => {
        let showOutcomeButtons = false;

        if (
          this.viewCase &&
          !caseDto?.isRequestingCountry &&
          !this.hasIndication &&
          this.caseStatus !== CaseStatus.Closed &&
          caseDto?.outcome === null
        ) {
          showOutcomeButtons = true;
        }

        return showOutcomeButtons;
      }),
    );
  }

  public isShareOutcomeButtonVisible(): boolean {
    return (
      this.caseType !== CaseType.Return &&
      this.caseStatus !== CaseStatus.Closed &&
      this.userService.getLoggedInUserCountryCode() === this.receivingCountryCode
    );
  }

  public isDownloadButtonVisible(): boolean {
    return this.viewCase;
  }

  public isSaveCaseButtonVisible(): boolean {
    return this.viewCase && this.caseStatus !== CaseStatus.Saved;
  }

  public isSaveCaseAsDraftButtonVisible(): boolean {
    return !this.viewCase;
  }

  public isEditCaseButtonVisible(): boolean {
    return this.viewCase && this.caseStatus === CaseStatus.Saved;
  }

  public isSubmitCaseButtonVisible(): boolean {
    return (!this.viewCase && this.caseStatus !== CaseStatus.Saved) || (this.viewCase && this.caseStatus == CaseStatus.Saved);
  }

  public isTransferModalitiesDisabled(): boolean {
    return (
      this.caseStatus !== CaseStatus.Approved &&
      this.caseStatus !== CaseStatus.ReturnScheduled &&
      !this.hasIndication &&
      this.caseStatus !== CaseStatus.Closed
    );
  }
}
