import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CaseDto, CreateCaseParameter, UpdateCaseDto } from 'src/app/case/models/caseDto';
import { CreateCorrespondenceDto } from 'src/app/case/models/createCorrespondenceDto';
import { AppConfigService } from 'src/app/core/app-config/app-config.service';
import { FormGroup } from '@angular/forms';
import { TransferModalityDto } from 'src/app/case/models/transferModalityDto';
import { IndicationTypeDto } from 'src/app/case/models/indicationTypeDto';
import { TransitOperationDto } from 'src/app/case/models';
import { CreateInterviewDto, InterviewDto } from '../../case/models/interviewDto';
import { CaseService as ApiV1CaseService, ArchiveDto, CaseStatus, CaseType, CreateCommentDto, CreateOutcomeDto } from 'swagger';
import { Observable } from 'rxjs/internal/Observable';
import { UtilService } from './util.service';
import { CaseListDto } from 'swagger/model/caseListDto';

@Injectable({
  providedIn: 'root',
})
export class CasesService {
  apiBaseUrl = this.config.getConfig().api.baseUrl;
  casesPath = '/api/case';
  cases$ = this.http.get(new URL(this.casesPath, this.apiBaseUrl).href);

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private apiV1CaseService: ApiV1CaseService,
    private utilityService: UtilService,
  ) {}

  getCasesBySearch(searchParameters:{ [key: string]: any }): Observable<CaseListDto[]> {
    let url = `${this.apiBaseUrl + this.casesPath}/CaseSearch`;

    if (searchParameters) {
      const params = new URLSearchParams(searchParameters).toString();
      url += `?${params}`;
    }

    return this.http.get<CaseListDto[]>(url);
  }

  getAllCases(): Observable<CaseListDto[]> {
    return this.apiV1CaseService.getAllCases();
  }

  getCase(caseId: string) {
    const path = '/api/case/' + caseId;
    return this.http.get<CaseDto>(this.apiBaseUrl + path);
  }

  deleteCase(caseId: string) {
    return this.http.delete(this.apiBaseUrl + this.casesPath + '/' + caseId);
  }

  public getExport(id: string): Observable<ArchiveDto> {
    return this.apiV1CaseService.getExportCase(id);
  }

  downloadRelatedRecord(requestingStateCaseRef: string, language: string) {
    const path = `/api/case/relatedrecord/${requestingStateCaseRef}/download/${language}`;
    return this.http.get(this.apiBaseUrl + path);
  }

  archiveCase(caseId: string) {
    const path = '/api/case/' + caseId + '/archivecase/';
    return this.http.put<CaseDto>(this.apiBaseUrl + path, null);
  }

  createCase(body: CreateCaseParameter) {
    return this.http.post(this.apiBaseUrl + this.casesPath, body);
  }

  allocateCases(body: any) {
    const path = '/api/case/caseallocation';
    return this.http.put(this.apiBaseUrl + path, body);
  }

  updateCase(body: any, caseId: string) {
    return this.http.put(this.apiBaseUrl + this.casesPath + '/' + caseId, body);
  }

  updateCaseStatus(caseStatus: CaseStatus, caseId: string) {
    return this.http.put<boolean>(this.apiBaseUrl + this.casesPath + '/' + caseId + '/status/' + caseStatus, null);
  }

  setIndication(indicationType: IndicationTypeDto) {
    const path = '/api/case/' + indicationType.caseId + '/indication';
    return this.http.post(this.apiBaseUrl + path, indicationType);
  }

  closeCase(caseId: string) {
    const path = '/api/case/' + caseId + '/closecase';
    return this.http.post(this.apiBaseUrl + path, null);
  }

  createCorrespondence(correspondence: CreateCorrespondenceDto) {
    const path = '/api/case/' + correspondence.caseId + '/correspondence';
    return this.http.post(this.apiBaseUrl + path, correspondence);
  }

  createTransferModalities(transferModalities: TransferModalityDto) {
    const path = '/api/case/' + transferModalities.caseId + '/transfermodalities';
    return this.http.post(this.apiBaseUrl + path, transferModalities);
  }

  updateTransferModalities(transferModalities: TransferModalityDto) {
    const path = '/api/case/' + transferModalities.caseId + '/transfermodalities';
    return this.http.put(this.apiBaseUrl + path, transferModalities);
  }

  getCaseIdFromRequestingStateReferenceNumberOrSubmittingAgencyReferenceNumber(reference: string) {
    const path = '/api/case/idfromreference?caseReference=' + reference;
    return this.http.get<string>(this.apiBaseUrl + path);
  }

  getMeansOfEvidence(id: number) {
    const path = '/api/case/meansofevidence/' + id;
    return this.http.get(this.apiBaseUrl + path);
  }

  createComment(comment: CreateCommentDto) {
    const path = '/api/case/correspondence/' + comment.correspondenceId;
    return this.http.post(this.apiBaseUrl + path, comment);
  }

  createInterview(caseId: string, createInterviewDto: CreateInterviewDto) {
    const path = `/api/case/${caseId}/interview`;
    return this.http.post<boolean>(this.apiBaseUrl + path, createInterviewDto);
  }

  updateInterview(interviewDto: InterviewDto) {
    const path = '/api/case/interview';
    return this.http.put<boolean>(this.apiBaseUrl + path, interviewDto);
  }

  createCaseBody(fullForm: FormGroup, caseType: CaseType, receivingCountryCode: string, saveAsDraft: boolean) {
    if (caseType === CaseType.Readmission || caseType === CaseType.Return) {
      const body: CreateCaseParameter = {
        assignedGroups: null,
        assignedUserId: null,
        deadline: null,
        documentNo: null,
        secondaryCaseStatus: null,
        transitOperation: null,
        travelDocumentType: null,
        givenName: fullForm.value.personalDetails.givenName,
        surname: fullForm.value.personalDetails.surname,
        maidenName: fullForm.value.personalDetails.maidenName,
        civilStatus: fullForm.value.personalDetails.civilStatus,
        photograph: fullForm.value.personalDetails.photograph,
        sex: fullForm.value.personalDetails.sex,
        dateOfBirth: this.utilityService.getDateString(fullForm.value.personalDetails.dateOfBirth),
        placeOfBirth: fullForm.value.personalDetails.placeOfBirth,
        acceleratedProcedure: fullForm.value.personalDetails.acceleratedProcedure,
        interviewRequest: fullForm.value.personalDetails.interviewRequest,
        readmissionInError: fullForm.value.personalDetails.readmissionInError ?? false,
        physicalDescription: fullForm.value.personalDetails.physicalDescription,
        alias: fullForm.value.personalDetails.alias,
        languageSpoken: fullForm.value.personalDetails.languageSpoken,
        nationalityCountryCode: fullForm.value.personalDetails.nationalityCountryCode,
        lastAddress: fullForm.value.personalDetails.lastAddress,
        submittingAgencyReference: fullForm.value.personalDetails.submittingAgencyReference,
        stateOfHealth: fullForm.value.personalDetails.stateOfHealth,
        observations: fullForm.value.personalDetails.observations,
        indicationOfDangerousPerson: fullForm.value.personalDetails.indicationOfDangerousPerson,
        caseType: caseType,
        receivingCountryCode: receivingCountryCode,
        familyMembers: this.handleFamilyMembers(fullForm.value.familyInformation),
        relatedRecords: fullForm.value.relatedRecords,
        meansOfEvidences: this.handleMeansOfEvidence(fullForm.value.meansOfEvidence),
        biometrics: fullForm.value.biometrics,
        saveAsDraft: saveAsDraft,
        placeOfBirthRegionCity: fullForm.value.personalDetails.placeOfBirthRegionCity,
      };
      return body;
    }
    if (caseType === CaseType.Transit) {
      const body: CreateCaseParameter = {
        acceleratedProcedure: null,
        assignedGroups: null,
        assignedUserId: null,
        biometrics: null,
        civilStatus: null,
        deadline: null,
        familyMembers: null,
        indicationOfDangerousPerson: null,
        interviewRequest: null,
        lastAddress: null,
        meansOfEvidences: null,
        observations: null,
        readmissionInError: null,
        relatedRecords: null,
        secondaryCaseStatus: null,
        stateOfHealth: null,
        givenName: fullForm.value.personalDetails.givenName,
        surname: fullForm.value.personalDetails.surname,
        maidenName: fullForm.value.personalDetails.maidenName,
        photograph: fullForm.value.personalDetails.photograph,
        sex: fullForm.value.personalDetails.sex,
        dateOfBirth: this.utilityService.getDateString(fullForm.value.personalDetails.dateOfBirth),
        placeOfBirth: fullForm.value.personalDetails.placeOfBirth,
        physicalDescription: fullForm.value.personalDetails.physicalDescription,
        alias: fullForm.value.personalDetails.alias,
        languageSpoken: fullForm.value.personalDetails.languageSpoken,
        submittingAgencyReference: fullForm.value.personalDetails.submittingAgencyReference,
        nationalityCountryCode: fullForm.value.personalDetails.nationalityCountryCode,
        caseType: CaseType.Transit,
        travelDocumentType: fullForm.value.personalDetails.travelDocumentType,
        documentNo: fullForm.value.personalDetails.documentNo,
        receivingCountryCode: receivingCountryCode,
        saveAsDraft: saveAsDraft,
        placeOfBirthRegionCity: fullForm.value.personalDetails.placeOfBirthRegionCity,
        transitOperation: this.createTransitOperationBody(fullForm.value.transitOperation),
      };
      return body;
    }

    return null;
  }

  createUpdateCaseBody(fullForm: FormGroup, caseType: CaseType, saveAsDraft: boolean) {
    if (caseType === CaseType.Readmission || caseType === CaseType.Return) {
      const body: UpdateCaseDto = {
        id: fullForm.value.personalDetails.id,
        givenName: fullForm.value.personalDetails.givenName,
        surname: fullForm.value.personalDetails.surname,
        maidenName: fullForm.value.personalDetails.maidenName,
        civilStatus: fullForm.value.personalDetails.civilStatus,
        photograph: fullForm.value.personalDetails.photograph,
        sex: fullForm.value.personalDetails.sex,
        dateOfBirth: this.utilityService.getDateString(fullForm.value.personalDetails.dateOfBirth),
        placeOfBirth: fullForm.value.personalDetails.placeOfBirth,
        acceleratedProcedure: fullForm.value.personalDetails.acceleratedProcedure,
        interviewRequest: fullForm.value.personalDetails.interviewRequest,
        readmissionInError: fullForm.value.personalDetails.readmissionInError ?? false,
        physicalDescription: fullForm.value.personalDetails.physicalDescription,
        alias: fullForm.value.personalDetails.alias,
        languageSpoken: fullForm.value.personalDetails.languageSpoken,
        nationalityCountryCode: fullForm.value.personalDetails.nationalityCountryCode,
        lastAddress: fullForm.value.personalDetails.lastAddress,
        submittingAgencyReference: fullForm.value.personalDetails.submittingAgencyReference,
        stateOfHealth: fullForm.value.personalDetails.stateOfHealth,
        observations: fullForm.value.personalDetails.observations,
        indicationOfDangerousPerson: fullForm.value.personalDetails.indicationOfDangerousPerson,
        caseType: caseType,
        correspondences: fullForm.value.correspondences,
        familyMembers: this.handleFamilyMembers(fullForm.value.familyInformation),
        relatedRecords: fullForm.value.relatedRecords,
        meansOfEvidences: this.handleMeansOfEvidence(fullForm.value.meansOfEvidence),
        biometrics: fullForm.value.biometrics,
        secondaryCaseStatus: null,
        travelDocumentType: null,
        documentNo: null,
        assignedUserId: null,
        assignedGroups: null,
        transitOperation: null,
        transferModality: null,
        placeOfBirthRegionCity: fullForm.value.personalDetails.placeOfBirthRegionCity,
        caseAllocationRemark: null,
        saveAsDraft: saveAsDraft,
      };
      return body;
    }

    if (caseType === CaseType.Transit) {
      const body: UpdateCaseDto = {
        id: fullForm.value.personalDetails.id,
        givenName: fullForm.value.personalDetails.givenName,
        surname: fullForm.value.personalDetails.surname,
        maidenName: fullForm.value.personalDetails.maidenName,
        photograph: fullForm.value.personalDetails.photograph,
        sex: fullForm.value.personalDetails.sex,
        dateOfBirth: this.utilityService.getDateString(fullForm.value.personalDetails.dateOfBirth),
        placeOfBirth: fullForm.value.personalDetails.placeOfBirth,
        physicalDescription: fullForm.value.personalDetails.physicalDescription,
        alias: fullForm.value.personalDetails.alias,
        languageSpoken: fullForm.value.personalDetails.languageSpoken,
        submittingAgencyReference: fullForm.value.personalDetails.submittingAgencyReference,
        nationalityCountryCode: fullForm.value.personalDetails.nationalityCountryCode,
        caseType: CaseType.Transit,
        travelDocumentType: fullForm.value.personalDetails.travelDocumentType,
        documentNo: fullForm.value.personalDetails.documentNo,
        transitOperation: this.createTransitOperationBody(fullForm.value.transitOperation),
        correspondences: fullForm.value.correspondences,
        civilStatus: null,
        acceleratedProcedure: null,
        interviewRequest: null,
        readmissionInError: null,
        lastAddress: null,
        stateOfHealth: null,
        observations: null,
        indicationOfDangerousPerson: null,
        secondaryCaseStatus: null,
        assignedUserId: null,
        assignedGroups: null,
        transferModality: null,
        biometrics: null,
        familyMembers: null,
        meansOfEvidences: null,
        relatedRecords: null,
        placeOfBirthRegionCity: fullForm.value.personalDetails.placeOfBirthRegionCity,
        caseAllocationRemark: null,
        saveAsDraft: saveAsDraft,
      };
      return body;
    }

    return null;
  }

  private createTransitOperationBody(transitOperation: any) {
    const transitOperationValues: TransitOperationDto = transitOperation;
    if (transitOperation.proposedTransferDate) {
      transitOperationValues.proposedTransferDate = this.utilityService.getDateString(transitOperation.proposedTransferDate) ?? undefined;
    }
    return transitOperationValues;
  }

  private handleMeansOfEvidence(meansOfEvidence: any[]) {
    for (const index in meansOfEvidence) {
      if (meansOfEvidence[index].documentNo === null) {
        meansOfEvidence[index].documentNo = '';
      }

      if (
        meansOfEvidence[index].expirationDate !== 'Invalid date' &&
        meansOfEvidence[index].expirationDate &&
        meansOfEvidence[index].expirationDate !== '1970-01-01'
      ) {
        meansOfEvidence[index].expirationDate = this.utilityService.getDateString(new Date(meansOfEvidence[index].expirationDate));
      } else {
        meansOfEvidence[index].expirationDate = undefined;
      }
      if (
        meansOfEvidence[index].dateOfIssuance !== 'Invalid date' &&
        meansOfEvidence[index].dateOfIssuance &&
        meansOfEvidence[index].dateOfIssuance !== '1970-01-01'
      ) {
        meansOfEvidence[index].dateOfIssuance = this.utilityService.getDateString(new Date(meansOfEvidence[index].dateOfIssuance));
      } else {
        meansOfEvidence[index].dateOfIssuance = undefined;
      }
    }

    return meansOfEvidence;
  }

  private handleFamilyMembers(familyMembers: any[]): any {
    for (const index in familyMembers) {
      if (
        familyMembers[index].dateOfBirth !== 'Invalid date' &&
        familyMembers[index].dateOfBirth &&
        familyMembers[index].dateOfBirth !== '1970-01-01'
      ) {
        familyMembers[index].dateOfBirth = this.utilityService.getDateString(new Date(familyMembers[index].dateOfBirth));
      } else {
        familyMembers[index].dateOfBirth = undefined;
      }
    }

    return familyMembers;
  }
}
