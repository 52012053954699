/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type GraphType = 'CaseStatusPerTimeGraph' | 'CaseTypePerTimeGraph' | 'CaseProcessingTimePerTimeGraph' | 'CaseTypePerCountryGraph' | 'CasesPerCaseStatusGraph' | 'ReadmissionCaseTypeGraph';

export const GraphType = {
    CaseStatusPerTimeGraph: 'CaseStatusPerTimeGraph' as GraphType,
    CaseTypePerTimeGraph: 'CaseTypePerTimeGraph' as GraphType,
    CaseProcessingTimePerTimeGraph: 'CaseProcessingTimePerTimeGraph' as GraphType,
    CaseTypePerCountryGraph: 'CaseTypePerCountryGraph' as GraphType,
    CasesPerCaseStatusGraph: 'CasesPerCaseStatusGraph' as GraphType,
    ReadmissionCaseTypeGraph: 'ReadmissionCaseTypeGraph' as GraphType
};