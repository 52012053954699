import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkflowService as ApiWorkflowService, CreateWorkflowTemplateDto, UpdateWorkflowTemplateDto, WorkflowTemplateDto } from 'swagger';

@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  public constructor(private apiWorkflowService: ApiWorkflowService) {}

  public createWorkflowTemplate(createWorkflowTemplateDto: CreateWorkflowTemplateDto): Observable<WorkflowTemplateDto> {
    return this.apiWorkflowService.createWorkflowTemplate(createWorkflowTemplateDto);
  }

  public deleteWorkflowTemplateById(id: string): Observable<unknown> {
    return this.apiWorkflowService.deleteWorkflowTemplateById(id);
  }

  public getWorkflowTemplates(): Observable<WorkflowTemplateDto[]> {
    return this.apiWorkflowService.getWorkflowTemplates();
  }

  public getWorkflowTemplateId(id: string): Observable<WorkflowTemplateDto> {
    return this.apiWorkflowService.getWorkflowTemplateById(id);
  }

  public updateWorkflowTemplate(updateWorkflowTemplateDto: UpdateWorkflowTemplateDto): Observable<WorkflowTemplateDto> {
    return this.apiWorkflowService.updateWorkflowTemplate(updateWorkflowTemplateDto);
  }
}
