<div class="dialog-header">
  <h2 mat-dialog-title>{{ 'Workshop.SetPasswordsTitle' | translate }}</h2>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-form-field>
      <input matInput placeholder="Password" type="text" formControlName="password" />
    </mat-form-field>
    <mat-error *ngIf="form.get('password')?.touched && form.get('password')?.hasError('pattern')">
      Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long.
    </mat-error>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button mat-button color="primary" cdkFocusInitial type="submit">
      <mat-icon class="next-icon">arrow_forward</mat-icon> {{ 'ACTIONS.NEXT' | translate }}
    </button>
    <button mat-button color="primary" mat-dialog-close (click)="close()"><mat-icon>cancel</mat-icon> {{ 'ACTIONS.CANCEL' | translate }}</button>
  </mat-dialog-actions>
</form>
