<div class="dialog-header">
  <p>{{ 'RELATED_RECORDS.INFORMATION' | translate }}</p>
</div>
<mat-dialog-content>
  <div *ngIf="cases.length > 0">
    <app-search-result-list [getCasesChange]="cases"
      (selectedCasesChange)="onSelectedCasesChange($event)"></app-search-result-list>
  </div>
  <div *ngIf="showNoResultsMessage()">
    <div class="no-results-message">
      <p style="color: red;">No results found</p>
    </div>
  </div>
</mat-dialog-content>
<mat-form-field class="search">
  <mat-label>{{ 'RELATED_RECORDS.PARAMS_HINT' | translate }}</mat-label>
  <input [(ngModel)]="searchValue" (keyup)="setFilterValue($event)" matInput
    placeholder="{{ 'ACTIONS.SEARCH' | translate }}" #input>
  <mat-hint>{{ 'RELATED_RECORDS.SEARCH_WITH_PARAMS_HINT' | translate }}</mat-hint>
</mat-form-field>
<mat-dialog-actions align="center">
  <button mat-button color="primary" (click)="getCases()">
    <mat-icon>search</mat-icon> {{ 'ACTIONS.SEARCH' | translate }} </button>
  <button mat-button color="primary" mat-dialog-close (click)="close()">
    <mat-icon>cancel</mat-icon> {{ 'ACTIONS.CANCEL' | translate }} </button>
</mat-dialog-actions>