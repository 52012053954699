/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CountryDto } from './countryDto';
import { TaskDataDto } from './taskDataDto';
import { TaskRequestType } from './taskRequestType';
import { TaskResponseDto } from './taskResponseDto';
import { TaskResponseType } from './taskResponseType';
import { TaskStatus } from './taskStatus';
import { TaskType } from './taskType';
import { UserGroupDto } from './userGroupDto';

export interface TaskDto { 
    id?: string;
    name?: string;
    description?: string;
    type?: TaskType;
    taskStatus?: TaskStatus;
    taskData?: TaskDataDto;
    caseId?: string;
    sentByCountryCode?: string;
    createdBy?: string;
    createdByGroupId?: string;
    createdByGroup?: UserGroupDto;
    createdByGroupIsAgency?: boolean;
    receivingCountryCode?: string;
    deadline?: Date;
    createdDate?: Date;
    assignedCaseWorkerIds?: Array<string>;
    agencyId?: string;
    agency?: UserGroupDto;
    departmentId?: string;
    department?: UserGroupDto;
    responseType?: TaskResponseType;
    requestType?: TaskRequestType;
    taskResponses?: Array<TaskResponseDto>;
    userHasRespondPermission?: boolean;
    sentByCountry?: CountryDto;
    receivingCountry?: CountryDto;
}