import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { BackendError, ErrorSeverity } from 'src/app/case/models/errorMessage';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './notification.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        let error: BackendError = {
          message: '',
          severity: null,
          code: '',
        };

        if (err.error instanceof ErrorEvent) {
          error = this.handleUnknownError(err.error.message);
        } else if (err.error instanceof Blob) {
          if (err.error.type === 'application/json') {
            err.error.text().then((text: string) => {
              const errorObject = JSON.parse(text);
              error = this.handleBackendBlobError(err, errorObject.message);
            });
          } else {
            error = this.handleBackendError(err);
          }
        } else {
          switch (err.status) {
            case 0:
              error = this.handleUnknownError();
              break;
            case 413:
              error = this.handleBackendError(err, 'ERRORS.HTTP.STATUS_413');
              break;
            default:
              error = this.handleBackendError(err);
              break;
          }
        }

        return throwError(() => error);
      }),
    );
  }

  private showBackendError(error: BackendError) {
    this.notificationService.showError(error.message, error.title);
  }

  private handleUnknownError(message = ''): BackendError {
    if (!message) {
      message = this.translateService.instant('ERRORS.GENERIC_ERROR');
    } else {
      message = `An error occurred: ${message}`;
    }

    const error: BackendError = {
      title: 'ERROR',
      message: message,
      severity: ErrorSeverity.FATAL,
      code: 'UNKNOWN_ERROR',
    };

    this.showBackendError(error);

    return error;
  }

  private handleBackendBlobError(err: any, message: string): BackendError {
    const translatedMessage = this.translateService.instant(message);

    const error: BackendError = {
      title: err.error?.title || 'ERROR',
      message: translatedMessage !== message ? translatedMessage : err.message,
      severity: ErrorSeverity.ERROR,
      code: err.error?.identifierCode ? err.error.identifierCode : 'BACKEND_ERROR',
    };

    this.showBackendError(error);

    return error;
  }

  private handleBackendError(err: any, translationCode = ''): BackendError {
    const message = err.message ? err.error.message : err.error ? err.error : err.message;
    const translatedMessage = this.translateService.instant(translationCode == '' ? message : translationCode);

    const error: BackendError = {
      title: err.error?.title || 'ERROR',
      message: translatedMessage,
      severity: ErrorSeverity.ERROR,
      code: err.error?.identifierCode ? err.error.identifierCode : 'BACKEND_ERROR',
    };

    this.showBackendError(error);

    return error;
  }
}
