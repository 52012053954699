import { Component } from '@angular/core';
import { ErrorService } from '../../shared/services/error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  noConnectionError = true;

  constructor(public errorService: ErrorService) {
    this.errorService.showFailedConnectionError.subscribe(this.showErrorMessage.bind(this));
  }

  showErrorMessage = (state: boolean): void => {
    this.noConnectionError = state;
  };
}