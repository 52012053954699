import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PermissionTypes } from '@shared/models/permissionTypes';
import { UserState } from './user.reducer';

const country_regex = new RegExp('(?<=/country:)(.*?)(?=/|$)');
const agency_regex = new RegExp('(?<=/agency:)(.*?)(?=/|$)');
const department_regex = new RegExp('(?<=/department:).*');

const selectUserFeatureState = createFeatureSelector<UserState>('user');

const countryGroup = (groups: string[]) => groups.find((group) => group.includes('country'));
const agencyGroup = (groups: string[]) => groups.find((group) => group.includes('agency'));
const departmentGroup = (groups: string[]) => groups.find((group) => group.includes('department'));

const formattedCountryCode = (countryGroup: string | null) => countryGroup?.match(country_regex)?.pop() ?? null;
const formattedAgencyName = (agencyGroup: string | null) => agencyGroup?.match(agency_regex)?.pop() ?? null;
const formattedDepartmentName = (deparmentGroup: string | null) => deparmentGroup?.match(department_regex)?.pop() ?? null;

export const selectUserPreferences = createSelector(selectUserFeatureState, (state) => state.userPreferences);

export const selectIsRequestingCountry = createSelector(selectUserFeatureState, (state) => state.userPreferences?.requestingCountry);

export const selectTabIndex = createSelector(selectIsRequestingCountry, (isRequestingCountry) => {
  return isRequestingCountry ? 1 : 0;
});

export const selectCurrentLanguage = createSelector(selectUserFeatureState, (state) => state.userPreferences?.languagePreference);

export const selectDisabledEmailNotificationPreferences = createSelector(
  selectUserFeatureState,
  (state) => state.userPreferences?.disabledEmailNotificationPreference,
);

export const selectDisabledSystemNotificationPreferences = createSelector(
  selectUserFeatureState,
  (state) => state.userPreferences?.disabledSystemNotificationPreference,
);

export const selectFlaggedCases = createSelector(selectUserFeatureState, (state) => state.userCaseFlags);

export const selectFlaggedCaseByCaseId = (caseId: string) =>
  createSelector(selectFlaggedCases, (caseFlag) => caseFlag.find((i) => i.referenceId == caseId));

export const selectKeycloakUserInfo = createSelector(selectUserFeatureState, (state) => state.keycloakUserInfo);

export const selectUserRoles = createSelector(selectKeycloakUserInfo, (userInfo) => userInfo?.roles ?? []);

export const selectHasUserRole = (role: string) => createSelector(selectKeycloakUserInfo, (userInfo) => userInfo!.roles.includes(role));

export const selectUserCountryCode = createSelector(selectKeycloakUserInfo, (userInfo) => formattedCountryCode(countryGroup(userInfo!.groups)!));

export const selectUserAgency = createSelector(selectKeycloakUserInfo, (userInfo) => formattedAgencyName(agencyGroup(userInfo!.groups)!));

export const selectUserDepartment = createSelector(selectKeycloakUserInfo, (userInfo) => formattedDepartmentName(departmentGroup(userInfo!.groups)!));

export const selectUserPermissions = createSelector(selectKeycloakUserInfo, (state) => state?.permissions ?? []);

export const selectUserName = createSelector(selectKeycloakUserInfo, (userInfo) => userInfo?.preferred_username);

export const selectIsAzerbaijan = createSelector(selectUserCountryCode, (userCountry) => userCountry === 'AZ');

export const userHasNationalCorrespondencePermission = createSelector(
  selectUserPermissions,
  (permissions) => (permissions && permissions.includes(PermissionTypes.CaseCorrespondenceAddInternational)) ?? false,
);
