import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, from, map, mergeMap, of } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { UserApiActions, UserPageActions } from './actions';

@Injectable()
export class UserEffects {
  constructor(private $actions: Actions, private userService: UserService) {}

  loadPreferences$ = createEffect(() => {
    return this.$actions.pipe(
      ofType(UserPageActions.loadUserPreferences),
      mergeMap(() =>
        this.userService.getUserPreferences().pipe(
          map((userPreferences) => UserApiActions.loadUserPreferencesSuccess({ userPreferences })),
          catchError((error) => of(UserApiActions.loadUserPreferencesFailure({ error })))
        )
      )
    );
  });

  loadUpdatedUserPreferences$ = createEffect(() => {
    return this.$actions.pipe(
      ofType(UserApiActions.updateUserPreferencesSuccess),
      mergeMap(() =>
        this.userService.getUserPreferences().pipe(
          map((userPreferences) => UserApiActions.loadUserPreferencesSuccess({ userPreferences })),
          catchError((error) => of(UserApiActions.loadUserPreferencesFailure({ error })))
        )
      )
    );
  });

  updateUserPreferences$ = createEffect(() => {
    return this.$actions.pipe(
      ofType(UserPageActions.updateUserPreferences),
      concatMap((action) =>
        this.userService.updateUserPreferences(action.userPreferences).pipe(
          map((userPreferences) => UserApiActions.updateUserPreferencesSuccess({ userPreferences })),
          catchError((error) => of(UserApiActions.updateUserPreferencesFailure({ error })))
        )
      )
    );
  });

  loadUserCaseFlags$ = createEffect(() => {
    return this.$actions.pipe(
      ofType(UserPageActions.loadInitialData),
      mergeMap(() =>
        this.userService.getAllUserCaseFlags().pipe(
          map((caseFlags) => UserApiActions.loadUserCaseFlagsSuccess({ userCaseFlags: caseFlags })),
          catchError((error) => of(UserApiActions.loadUserCaseFlagsFailure({ error })))
        )
      )
    );
  });

  loadUpdatedCaseFlags$ = createEffect(() => {
    return this.$actions.pipe(
      ofType(UserPageActions.loadCaseFlags),
      mergeMap(() =>
        this.userService.getAllUserCaseFlags().pipe(
          map((caseFlags) => UserApiActions.loadUserCaseFlagsSuccess({ userCaseFlags: caseFlags })),
          catchError((error) => of(UserApiActions.loadUserCaseFlagsFailure({ error })))
        )
      )
    );
  });

  loadKeycloakUserInfo$ = createEffect(() => {
    return this.$actions.pipe(
      ofType(UserPageActions.loadInitialData),
      mergeMap(() =>
        from(this.userService.keycloakUserInfo()).pipe(
          map((keycloakUserInfo) => UserApiActions.loadKeycloakUserInfoSuccess({ keycloakUserInfo })),
          catchError((error) => of(UserApiActions.loadKeycloakUserInfoFailure({ error })))
        )
      )
    );
  });
}
