import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { KeycloakUserService } from 'src/app/shared/services/keycloak-user.service';
import { UserService } from 'src/app/shared/services/user.service';
import { KeycloakUserRole } from '../../models/keycloakRole';
import { KeycloakUser } from '../../models/keycloakUser';
import { RoleTypes } from '@shared/models/roles';

@Component({
  selector: 'app-manage-client-roles',
  templateUrl: './manage-client-roles.component.html',
  styleUrls: ['./manage-client-roles.component.scss'],
})
export class ManageClientRolesComponent implements OnInit {
  @Input() user: KeycloakUser;

  allRoles: any[];
  currentRoles: any[];
  selectedRoles: string[];

  roles: FormGroup;
  defaultRolesForm: FormGroup;
  loggedInUserIsPrimary = false;
  states = new FormControl();

  constructor(
    private keycloakUserService: KeycloakUserService,
    private dialogRef: MatDialogRef<ManageClientRolesComponent>,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.keycloakUserService.getAllKeycloakClientRoles(this.user.id!).subscribe((res) => {
      this.allRoles = res as any[];
      this.currentRoles = this.allRoles.filter((i) => i.checked === true);
      this.selectedRoles = this.currentRoles.flatMap((i) => i.id);
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    try {
      //Removed roles
      const removeRoles: KeycloakUserRole[] = [];
      this.currentRoles.forEach((role) => {
        if (!this.selectedRoles.includes(role.id)) {
          var role = this.allRoles.find((r) => r.id == role.id);
          let keycloakRole: KeycloakUserRole = {
            id: role.id,
            containerId: role.clientId,
            name: role.role,
            formattedName: role.client,
            composite: false,
            clientRole: false,
          };
          removeRoles.push(keycloakRole);
        }
      });

      if (removeRoles.length > 0) {
        this.keycloakUserService.deleteKeycloakUserDefaultRoles(this.user.id!, removeRoles).subscribe();
      }

      //Added roles
      const addRoles: KeycloakUserRole[] = [];
      this.selectedRoles.forEach((roleId) => {
        if (!this.currentRoles.find((i) => i.id === roleId)) {
          var role: any = this.allRoles.find((r) => r.id == roleId);
          let keycloakRole: KeycloakUserRole = {
            id: roleId,
            containerId: role.clientId,
            name: role.role,
            formattedName: role.client,
            composite: false,
            clientRole: false,
          };
          addRoles.push(keycloakRole);
        }
      });

      if (addRoles.length > 0) {
        this.keycloakUserService.addKeycloakUserDefaultRoles(this.user.id!, addRoles).subscribe();
      }

      this.dialogRef.close(true);
    } catch {
      this.dialogRef.close(false);
    }
  }
}
