/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CaseStatus } from './caseStatus';
import { CaseType } from './caseType';
import { CountryDto } from './countryDto';

export interface BaseDataDto { 
    countries: Array<CountryDto>;
    targetCountries: Array<CountryDto>;
    caseTypes: Array<CaseType>;
    returnCaseStatuses?: Array<CaseStatus>;
}