import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GroupRepresentationDto } from 'src/app/case/models/groupRepresentationDto';
import { AppConfigService } from 'src/app/core/app-config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class SystemManagementService {
  apiBaseUrl = this.config.getConfig().api.baseUrl;

  constructor(private http: HttpClient,
    private config: AppConfigService) { }

  getAgenciesByCountryName(countryName: string) {
    const path = '/api/keycloak/agencies/' + countryName;
    return this.http.get<GroupRepresentationDto[]>(this.apiBaseUrl + path);
  }

  getDepartmentsByCountryAndAgencyName(countryName: string, agencyName: string) {
    const path = '/api/keycloak/departments/' + countryName + '/' + agencyName;
    return this.http.get<GroupRepresentationDto[]>(this.apiBaseUrl + path);
  }

  getTaskGroupRecipientsByTaskType(isNational: boolean, taskType: number, caseId: string | null) {
    const path = '/api/keycloak/TaskGroupRecipients/' + isNational + "/" + taskType + "/" + caseId;
    return this.http.get<GroupRepresentationDto[]>(this.apiBaseUrl + path);
  }
}
