import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/core/app-config/app-config.service';
import { KeycloakUserRole } from 'src/app/system-administration/models/keycloakRole';
import { KeycloakUser } from 'src/app/system-administration/models/keycloakUser';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { RoleTypes } from '@shared/models/roles';
import { PermissionTypes } from '@shared/models/permissionTypes';
import { GroupRepresentationDto } from 'src/app/case/models/groupRepresentationDto';
import { UsersService } from 'swagger';

@Injectable({
  providedIn: 'root',
})
export class KeycloakUserService {
  apiBaseUrl = this.config.getConfig().api.baseUrl + '/api';

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private router: Router,
    protected readonly keycloak: KeycloakService,
    private userService: UsersService,
  ) {}

  getRegularKeycloakUsers() {
    return this.userService.getRegularKeycloakUsers();
  }

  getSAUAKeycloakUsers() {
    return this.userService.getSAUAKeycloakUsers();
  }

  getAllGroups() {
    const path = '/users/keycloak/GetGroups';
    return this.http.get<any[]>(this.apiBaseUrl + path);
  }

  getAllDepartmentsByAgencyId(id: string) {
    const path = '/users/keycloak/GetDepartments/' + id;
    return this.http.get<any[]>(this.apiBaseUrl + path);
  }

  createKeycloakUser(user: KeycloakUser) {
    const path = '/users/keycloak';
    return this.http.post(this.apiBaseUrl + path, user);
  }

  updateKeycloakUser(user: KeycloakUser) {
    const path = '/users/keycloak';
    return this.http.put(this.apiBaseUrl + path, user);
  }

  deleteKeycloakUser(id: string) {
    const path = '/users/keycloak/';
    return this.http.delete(this.apiBaseUrl + path + id);
  }

  getKeycloakRealmRoles() {
    const path = '/users/keycloak/realm-roles';
    return this.http.get(this.apiBaseUrl + path);
  }

  getAllKeycloakClientRoles(userId: string) {
    const path = '/users/keycloak/' + userId + '/all-roles';
    return this.http.get(this.apiBaseUrl + path);
  }

  getKeycloakDefaultRoles() {
    const path = '/users/keycloak/default-roles';
    return this.http.get<KeycloakUserRole[]>(this.apiBaseUrl + path);
  }

  getKeycloakUserRoles(userId: string) {
    const path: string = '/users/keycloak/' + userId + '/roles';
    return this.http.get(this.apiBaseUrl + path);
  }

  getKeycloakUserDefaultRoles(userId: string) {
    const path: string = '/users/keycloak/' + userId + '/default-roles';
    return this.http.get<KeycloakUserRole[]>(this.apiBaseUrl + path);
  }

  addKeycloakUserRoles(userId: string, roles: KeycloakUserRole[]) {
    const path: string = '/users/keycloak/' + userId + '/roles';
    return this.http.post(this.apiBaseUrl + path, roles);
  }

  addKeycloakUserDefaultRoles(userId: string, roles: KeycloakUserRole[]) {
    const path: string = '/users/keycloak/' + userId + '/default-roles';
    return this.http.post(this.apiBaseUrl + path, roles);
  }

  getCorrespondenceRecipients(isInternational: boolean, caseId: string) {
    const path: string = '/keycloak/correspondencerecipients/' + isInternational + '/' + caseId;
    return this.http.get<GroupRepresentationDto[]>(this.apiBaseUrl + path);
  }

  deleteKeycloakUserRoles(userId: string, roles: KeycloakUserRole[]) {
    const path: string = '/users/keycloak/' + userId + '/roles';

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: roles,
    };

    return this.http.delete(this.apiBaseUrl + path, httpOptions);
  }

  deleteKeycloakUserDefaultRoles(userId: string, roles: KeycloakUserRole[]) {
    const path: string = '/users/keycloak/' + userId + '/default-roles';

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: roles,
    };

    return this.http.delete(this.apiBaseUrl + path, httpOptions);
  }

  changePassword() {
    this.keycloak.login({
      action: 'UPDATE_PASSWORD',
    });
  }

  async hasUserRoleAccess(role: string) {
    let roles: string[] = [];
    let permissions: string[] = [];
    const keycloakInstance = this.keycloak.getKeycloakInstance();
    const hasAccess = await keycloakInstance
      .loadUserInfo()
      .then((res) => {
        const user = res as object;
        type ObjectKey = keyof typeof user;
        permissions = user['permissions' as ObjectKey] as string[];
        roles = user['roles' as ObjectKey] as string[];
        return roles.includes(role);
      })
      .catch(() => {
        return false;
      });

    if (!hasAccess) {
      this.routeBasedOnRoleAndPermission(roles, permissions);
    }
    return hasAccess;
  }

  async hasUserPermissionAccess(permission: string) {
    let permissions: string[] = [];
    let roles: string[] = [];
    const keycloakInstance = this.keycloak.getKeycloakInstance();
    const hasAccess = await keycloakInstance
      .loadUserInfo()
      .then((res) => {
        const user = res as object;
        type ObjectKey = keyof typeof user;
        permissions = user['permissions' as ObjectKey] as string[];
        roles = user['roles' as ObjectKey] as string[];
        return permissions.includes(permission);
      })
      .catch(() => {
        return false;
      });

    if (!hasAccess) {
      this.routeBasedOnRoleAndPermission(roles, permissions);
    }
    return hasAccess;
  }

  routeBasedOnRoleAndPermission(roles: string[], permissions: string[]) {
    switch (true) {
      case roles.includes(PermissionTypes.RegularUser) && permissions.includes(PermissionTypes.CaseStatusDashboardView):
        this.router.navigate(['/cases']);
        break;
      case roles.includes(PermissionTypes.RegularUser) && permissions.includes(PermissionTypes.StatisticsView):
        this.router.navigate(['/statistics']);
        break;
      case roles.includes(RoleTypes.PrimaryUser):
        this.router.navigate(['/system-administration/user-management']);
        break;
      case roles.includes(RoleTypes.AdminUser):
        this.router.navigate(['/system-administration/group-management']);
        break;
      case roles.includes(RoleTypes.WorkshopAdmin):
        this.router.navigate(['/workshop-administration']);
        break;
      default:
        this.router.navigate(['/unauthorized']);
        break;
    }
  }
}
