import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatKeycloakGroupName'
})
export class KeycloakGroupPipe implements PipeTransform {

  transform(keycloakGroupName: string | null): string {

    if (keycloakGroupName == null) {
      return '';
    }

    const formattedName = keycloakGroupName.substring(keycloakGroupName.indexOf(':') + 1) ?? keycloakGroupName;
    return formattedName;
  }

}
