/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TranslatedCorrespondenceDto } from './translatedCorrespondenceDto';
import { TranslatedFamilyMemberDto } from './translatedFamilyMemberDto';
import { TranslatedMeansOfEvidenceDto } from './translatedMeansOfEvidenceDto';
import { TranslatedOutcomeDto } from './translatedOutcomeDto';
import { TranslatedTransferModalityDto } from './translatedTransferModalityDto';
import { TranslatedTransitOperationDto } from './translatedTransitOperationDto';

export interface TranslatedCaseDto { 
    id?: string;
    translatedLanguage?: string;
    placeOfBirth?: string;
    placeOfBirthRegionCity?: string;
    physicalDescription?: string;
    alias?: string;
    languageSpoken?: string;
    lastAddress?: string;
    stateOfHealth?: string;
    observations?: string;
    indicationOfDangerousPerson?: string;
    travelDocumentType?: string;
    translatedTransitOperation?: TranslatedTransitOperationDto;
    translatedFamilyMembers?: Array<TranslatedFamilyMemberDto>;
    translatedMeansOfEvidences?: Array<TranslatedMeansOfEvidenceDto>;
    translatedTransferModality?: TranslatedTransferModalityDto;
    translatedCorrespondences?: Array<TranslatedCorrespondenceDto>;
    translatedOutcome?: TranslatedOutcomeDto;
}