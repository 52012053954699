import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationService as ApiApplicationService, BaseDataDto } from 'swagger';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  public constructor(private apiApplicationService: ApiApplicationService) {}

  public getBaseData(): Observable<BaseDataDto> {
    return this.apiApplicationService.getBaseData();
  }
}
