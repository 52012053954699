import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkflowEditDialogData } from './workflow-edit.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CaseType, DeadlineCountType, UpdateWorkflowTemplateDto, UserGroupAttribute, UserGroupDto, WorkflowTemplateDto } from 'swagger';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, UtilityService } from '@shared/services';
import { EnumObject } from '@shared/models';

@Component({
  selector: 'app-workflow-edit',
  templateUrl: './workflow-edit.component.html',
  styleUrls: ['../../../../../assets/bootstrap-utilities.min.css'],
})
export class WorkflowEditComponent {
  deadlineCountTypes: EnumObject[] = this.utilityService.convertToEnumObject(DeadlineCountType, 'DeadlineCountType', this.translateService);
  userGroups: UserGroupDto[];
  departmentsOfSelectedAgency: UserGroupDto[] | null;
  ready = false;
  workflowTemplate: WorkflowTemplateDto;
  workflowForm: FormGroup;

  public constructor(
    public dialogRef: MatDialogRef<WorkflowEditComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: WorkflowEditDialogData,
    private formBuilder: FormBuilder,
    protected translateService: TranslateService,
    private notificationService: NotificationService,
    private utilityService: UtilityService,
  ) {
    this.init();
  }

  public init() {
    const workflowTemplate = this.dialogData.workflowTemplate;

    const selectedGroup = this.dialogData.userGroups.find((ug: UserGroupDto) => ug.id === this.dialogData.workflowTemplate.assignToGroupId);

    this.workflowForm = this.formBuilder.group({
      id: [{ value: workflowTemplate.id, disabled: true }],
      requestingCountryName: [{ value: workflowTemplate.requestingCountry?.name, disabled: true }, Validators.required],
      caseType: [{ value: workflowTemplate.caseType, disabled: true }, Validators.required],
      assignToGroupId: [workflowTemplate.assignToGroupId, Validators.required],
      assignToDepartmentId: [selectedGroup?.parentId != null ? selectedGroup?.id : null],
      default: [workflowTemplate.isDefault],
      createdByUserId: [workflowTemplate.createdByUserId, Validators.required],
      deadline: this.formBuilder.group({
        id: [{ value: workflowTemplate.workflowDeadlineTemplateDto?.id, disabled: true }],
        regularDays: [workflowTemplate.workflowDeadlineTemplateDto?.regularDays, [Validators.required, Validators.min(1)]],
        regularCountType: [workflowTemplate.workflowDeadlineTemplateDto?.regularCountType],
        regularAsCalendarDays: [workflowTemplate.workflowDeadlineTemplateDto?.regularAsCalendarDays],
        acceleratedDays: [workflowTemplate.workflowDeadlineTemplateDto?.acceleratedDays, [Validators.required, Validators.min(1)]],
        acceleratedCountType: [workflowTemplate.workflowDeadlineTemplateDto?.acceleratedCountType],
        acceleratedAsCalendarDays: [workflowTemplate.workflowDeadlineTemplateDto?.acceleratedAsCalendarDays],
      }),
    });

    if (selectedGroup?.parentId != null) {
      this.workflowForm.get('assignToGroupId')?.setValue(selectedGroup.parentId);
      this.departmentsOfSelectedAgency =
        this.dialogData.userGroups.find((ug: UserGroupDto) => ug.id === selectedGroup?.parentId)?.childGroups ?? null;
    }

    if (workflowTemplate.isDefault) {
      this.workflowForm.controls['createdByUserId'].disable();
    }

    this.userGroups = this.dialogData.userGroups.filter((ug: UserGroupDto) => !ug.parentId && this.groupIsPublic(ug));

    this.setDepartmentsOfSelectedAgencyId(this.workflowForm.get('assignToGroupId')?.value);

    this.workflowTemplate = workflowTemplate;
    this.ready = true;
  }

  private groupIsPublic(userGroupDto: UserGroupDto): boolean {
    return (
      userGroupDto.userGroupAttributes?.findIndex((attribute: UserGroupAttribute) => attribute.key === 'public' && attribute.value === 'true') !== -1
    );
  }

  public onAgencySelectionChange(event: any): void {
    this.setDepartmentsOfSelectedAgencyId(event.value);

    // If agency is selected, reset department value
    this.workflowForm.get('assignToDepartmentId')?.setValue(null);
  }

  private setDepartmentsOfSelectedAgencyId(id: string): void {
    const selectedAgency = this.userGroups.find((ug: UserGroupDto) => ug.id === id);

    if (selectedAgency?.childGroups) {
      this.departmentsOfSelectedAgency = selectedAgency?.childGroups.filter((ug: UserGroupDto) => this.groupIsPublic(ug));
    } else {
      this.departmentsOfSelectedAgency = null;
    }
  }

  private getResultData(): UpdateWorkflowTemplateDto {
    return {
      id: this.workflowTemplate.id,
      assignToGroupId: this.workflowForm.get('assignToDepartmentId')?.value ?? this.workflowForm.get('assignToGroupId')?.value,
      updateWorkflowDeadlineTemplateDto: {
        regularCountType: this.workflowForm.get(['deadline', 'regularCountType'])?.value,
        regularAsCalendarDays: this.workflowForm.get(['deadline', 'regularAsCalendarDays'])?.value,
        regularDays: this.workflowForm.get(['deadline', 'regularDays'])?.value,
        acceleratedCountType: this.workflowForm.get(['deadline', 'acceleratedCountType'])?.value,
        acceleratedAsCalendarDays: this.workflowForm.get(['deadline', 'acceleratedAsCalendarDays'])?.value,
        acceleratedDays: this.workflowForm.get(['deadline', 'acceleratedDays'])?.value,
      },
    };
  }

  public showAcceleratedFields(): boolean {
    return (this.workflowForm.get('caseType')?.value as CaseType) == CaseType.Readmission;
  }

  public onClose(save = false): void {
    if (!this.showAcceleratedFields()) {
      this.workflowForm.get('deadline')?.get('acceleratedDays')?.disable();
      this.workflowForm.get('deadline')?.get('acceleratedCountType')?.disable();
      this.workflowForm.get('deadline')?.get('acceleratedAsCalendarDays')?.disable();
    }

    if (save != true) {
      this.dialogRef.close();
    } else {
      this.workflowForm.invalid
        ? this.notificationService.showError(this.translateService.instant('UI.WORKFLOW.ERRONEOUS_WORKFLOW'))
        : this.dialogRef.close(this.getResultData());
    }
  }
}
