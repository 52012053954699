import { createAction, props } from '@ngrx/store';
import { CaseDto } from '../../models/caseDto';
import { CaseDto as CaseDtoV1 } from 'swagger';

export const updateTranslationCaseState = createAction('[Case State Page] Set Translation Case State', props<{ isTranslationMode: boolean }>());

export const setDefaultCaseState = createAction('[Case State Page] Set initial Case State');

export const updateCurrentCaseDataState = createAction('[Case State Page] Set Case Data State', props<{ currentCaseData: CaseDto }>());

export const updateCurrentCaseDataStateV1 = createAction('[Case State Page] Set Case Data State', props<{ currentCaseData: CaseDtoV1 }>());

export const loadCurrentCaseData = createAction('[Case State Page] Load Case Data', props<{ caseId: string }>());

export const loadTranslatedCase = createAction('[Translated Case Page Action] Load', props<{ caseId: string }>());

export const loadCaseTasks = createAction('[Case Tasks Page Action] Load', props<{ caseId: string }>());
