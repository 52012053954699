/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type CivilStatus = 'Divorced' | 'Married' | 'Single' | 'Widowed' | 'Unknown';

export const CivilStatus = {
    Divorced: 'Divorced' as CivilStatus,
    Married: 'Married' as CivilStatus,
    Single: 'Single' as CivilStatus,
    Widowed: 'Widowed' as CivilStatus,
    Unknown: 'Unknown' as CivilStatus
};