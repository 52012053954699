<div class="dialog-header center">
    <h2 mat-dialog-title> {{"DISCLAIMER_OF_LIABILITY" | translate}}</h2>
</div>

<mat-dialog-content>
    <div class="container">

        <h1>By using this system you agree....
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.
        </h1>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button *ngIf="!data.onlyText" mat-button color="primary" cdkFocusInitial mat-dialog-close="true">
        <mat-icon class="check-icon">check</mat-icon> {{"ACTIONS.ACCEPT" | translate}}
    </button>
    <button *ngIf="!data.onlyText" mat-button color="primary" mat-dialog-close="false">
        <mat-icon>cancel</mat-icon> {{"ACTIONS.DECLINE" | translate}}
    </button>
    <button mat-button color="primary" (click)="print()">
        <mat-icon>print</mat-icon> {{"ACTIONS.PRINT" | translate}}
    </button>
</mat-dialog-actions>