/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GroupRepresentationDto } from '../model/groupRepresentationDto';
import { TaskRequestType } from '../model/taskRequestType';
import { TaskType } from '../model/taskType';
import { UserGroupDto } from '../model/userGroupDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class KeycloakService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param countryName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgenciesByCountry(countryName: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getAgenciesByCountry(countryName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getAgenciesByCountry(countryName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getAgenciesByCountry(countryName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (countryName === null || countryName === undefined) {
            throw new Error('Required parameter countryName was null or undefined when calling getAgenciesByCountry.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Keycloak/Agencies/${encodeURIComponent(String(countryName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param isInternational 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCorrespondenceRecipients(isInternational: boolean, caseId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserGroupDto>>;
    public getCorrespondenceRecipients(isInternational: boolean, caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserGroupDto>>>;
    public getCorrespondenceRecipients(isInternational: boolean, caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserGroupDto>>>;
    public getCorrespondenceRecipients(isInternational: boolean, caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (isInternational === null || isInternational === undefined) {
            throw new Error('Required parameter isInternational was null or undefined when calling getCorrespondenceRecipients.');
        }

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getCorrespondenceRecipients.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserGroupDto>>('get',`${this.basePath}/api/Keycloak/CorrespondenceRecipients/${encodeURIComponent(String(isInternational))}/${encodeURIComponent(String(caseId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNationalAgencies(observe?: 'body', reportProgress?: boolean): Observable<Array<GroupRepresentationDto>>;
    public getNationalAgencies(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GroupRepresentationDto>>>;
    public getNationalAgencies(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GroupRepresentationDto>>>;
    public getNationalAgencies(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<GroupRepresentationDto>>('get',`${this.basePath}/api/Keycloak/NationalAgencies`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param isNational 
     * @param taskType 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTaskGroupRecipientsByTaskType(isNational: boolean, taskType: TaskType, caseId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserGroupDto>>;
    public getTaskGroupRecipientsByTaskType(isNational: boolean, taskType: TaskType, caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserGroupDto>>>;
    public getTaskGroupRecipientsByTaskType(isNational: boolean, taskType: TaskType, caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserGroupDto>>>;
    public getTaskGroupRecipientsByTaskType(isNational: boolean, taskType: TaskType, caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (isNational === null || isNational === undefined) {
            throw new Error('Required parameter isNational was null or undefined when calling getTaskGroupRecipientsByTaskType.');
        }

        if (taskType === null || taskType === undefined) {
            throw new Error('Required parameter taskType was null or undefined when calling getTaskGroupRecipientsByTaskType.');
        }

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getTaskGroupRecipientsByTaskType.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserGroupDto>>('get',`${this.basePath}/api/Keycloak/TaskGroupRecipients/${encodeURIComponent(String(isNational))}/${encodeURIComponent(String(taskType))}/${encodeURIComponent(String(caseId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param isNational 
     * @param taskType 
     * @param caseId 
     * @param taskRequestType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTaskGroupRecipientsByTaskType_1(isNational: boolean, taskType: TaskType, caseId: string, taskRequestType: TaskRequestType, observe?: 'body', reportProgress?: boolean): Observable<Array<UserGroupDto>>;
    public getTaskGroupRecipientsByTaskType_1(isNational: boolean, taskType: TaskType, caseId: string, taskRequestType: TaskRequestType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserGroupDto>>>;
    public getTaskGroupRecipientsByTaskType_1(isNational: boolean, taskType: TaskType, caseId: string, taskRequestType: TaskRequestType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserGroupDto>>>;
    public getTaskGroupRecipientsByTaskType_1(isNational: boolean, taskType: TaskType, caseId: string, taskRequestType: TaskRequestType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (isNational === null || isNational === undefined) {
            throw new Error('Required parameter isNational was null or undefined when calling getTaskGroupRecipientsByTaskType_1.');
        }

        if (taskType === null || taskType === undefined) {
            throw new Error('Required parameter taskType was null or undefined when calling getTaskGroupRecipientsByTaskType_1.');
        }

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getTaskGroupRecipientsByTaskType_1.');
        }

        if (taskRequestType === null || taskRequestType === undefined) {
            throw new Error('Required parameter taskRequestType was null or undefined when calling getTaskGroupRecipientsByTaskType_1.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserGroupDto>>('get',`${this.basePath}/api/Keycloak/TaskGroupRecipients/${encodeURIComponent(String(isNational))}/${encodeURIComponent(String(taskType))}/${encodeURIComponent(String(caseId))}/${encodeURIComponent(String(taskRequestType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
