/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Country } from './country';
import { UserGroupAttribute } from './userGroupAttribute';

export interface UserGroupDto { 
    id?: string;
    name?: string;
    logoFileId?: string;
    parentId?: string;
    childGroups?: Array<UserGroupDto>;
    countryCode?: string;
    country?: Country;
    isMainAgency?: boolean;
    isMainConsularAgency?: boolean;
    userLimit?: number;
    isPublic?: boolean;
    checked?: boolean;
    allowedRecipient?: boolean;
    inUse?: boolean;
    userGroupAttributes?: Array<UserGroupAttribute>;
    readonly formattedName?: string;
}