/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type FileTypeType = 'Attachment' | 'Biometric' | 'Portrait' | 'PassPort' | 'DrivingLicense' | 'NationalIdentityCard' | 'UserManual';

export const FileTypeType = {
    Attachment: 'Attachment' as FileTypeType,
    Biometric: 'Biometric' as FileTypeType,
    Portrait: 'Portrait' as FileTypeType,
    PassPort: 'PassPort' as FileTypeType,
    DrivingLicense: 'DrivingLicense' as FileTypeType,
    NationalIdentityCard: 'NationalIdentityCard' as FileTypeType,
    UserManual: 'UserManual' as FileTypeType
};