/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Calendar } from './calendar';
import { CaseWorker } from './caseWorker';
import { Comment } from './comment';
import { Correspondence } from './correspondence';
import { DefaultTimeZone } from './defaultTimeZone';
import { FamilyMember } from './familyMember';
import { Interview } from './interview';
import { ModelCase } from './modelCase';
import { Task } from './task';
import { TransferModality } from './transferModality';
import { TransitOperation } from './transitOperation';

export interface Country { 
    code: string;
    name: string;
    defaultTimeZoneId?: string;
    defaultTimeZone?: DefaultTimeZone;
    casesSentBy?: Array<ModelCase>;
    casesReceiving?: Array<ModelCase>;
    commentsSentBy?: Array<Comment>;
    caseWorkers?: Array<CaseWorker>;
    correspondenceSentBy?: Array<Correspondence>;
    correspondenceReceiving?: Array<Correspondence>;
    interviewReceiving?: Array<Interview>;
    taskSentBy?: Array<Task>;
    taskReceiving?: Array<Task>;
    transitOperationsFinalDestinationCountries?: Array<TransitOperation>;
    transitOperationsTransitCountries?: Array<TransitOperation>;
    caseNationalites?: Array<ModelCase>;
    familyMembers?: Array<FamilyMember>;
    transferModalities?: Array<TransferModality>;
    calendars?: Array<Calendar>;
}