import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SubscriptionCleaner } from '@shared/helper/subscription-cleaner/subscription-cleaner.component';
import {
  BaseDataDto,
  CaseType,
  CountryDto,
  CreateWorkflowTemplateDto,
  DeadlineCountType,
  UpdateWorkflowTemplateDto,
  UserGroupDto,
  WorkflowDeadlineTemplateDto,
  WorkflowTemplateDto,
} from 'swagger';
import { MatDialog } from '@angular/material/dialog';
import { WorkflowCreateComponent } from '../workflow-create/workflow-create.component';
import { Observable, concatMap, filter, first, forkJoin } from 'rxjs';
import { ApplicationService, WorkflowService } from '@shared/services';
import { WorkflowCreateDialogData } from '../workflow-create/workflow-create.interface';
import { WorkflowDeadlineTemplateComponent, WorkflowEditComponent } from '../..';
import { WorkflowEditDialogData } from '../workflow-edit/workflow-edit.interface';
import { ConfirmationDialogComponent } from '@shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from '@shared/dialogs/confirmation-dialog/confirmation-dialog.interface';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowDeadlineTemplateDialogData } from '../workflow-deadline-template/workflow-deadline-template.interface';
import { UserGroupService } from '@shared/services/user-group.service';

const COLUMN_NAMES: string[] = [
  'isDefault',
  'requestingCountryName',
  'caseType',
  'assignToGroup',
  'regularDeadline',
  'acceleratedDeadline',
  'actions',
];

@Component({
  selector: 'app-workflow-admin',
  templateUrl: './workflow-admin.component.html',
  styleUrls: ['./workflow-admin.component.scss', '../../../../../assets/bootstrap-utilities.min.css'],
})
export class WorkflowAdminComponent extends SubscriptionCleaner implements OnInit {
  protected caseTypes: CaseType[];
  protected countries: CountryDto[];
  protected userGroups: UserGroupDto[];
  protected workflowTemplates: WorkflowTemplateDto[];
  protected ready = false;
  protected dataSource: MatTableDataSource<WorkflowTemplateDto>;
  protected displayedColumns: string[];

  public constructor(
    private workflowService: WorkflowService,
    private applicationService: ApplicationService,
    private userGroupService: UserGroupService,
    protected translateService: TranslateService,
    private dialog: MatDialog,
  ) {
    super();
  }

  public ngOnInit(): void {
    forkJoin([
      this.workflowService.getWorkflowTemplates().pipe(first()),
      this.applicationService.getBaseData().pipe(first()),
      this.userGroupService.getUserGroupsByCurrentUserCountry(true, true).pipe(first()),
    ])
      .pipe(first())
      .subscribe((e: [WorkflowTemplateDto[], BaseDataDto, UserGroupDto[]]) => {
        const baseData = e[1];
        this.countries = baseData.targetCountries;
        this.caseTypes = baseData.caseTypes;

        this.workflowTemplates = e[0];
        this.userGroups = e[2];
      })
      .add(() => {
        this.dataSource = new MatTableDataSource<WorkflowTemplateDto>(this.workflowTemplates);
        this.displayedColumns = COLUMN_NAMES;
        this.ready = true;
      });
  }

  private refreshDataSource(): void {
    this.workflowService
      .getWorkflowTemplates()
      .pipe(first())
      .subscribe((e: WorkflowTemplateDto[]) => {
        this.workflowTemplates = e;
        this.dataSource = new MatTableDataSource<WorkflowTemplateDto>(this.workflowTemplates);
      });
  }

  private createWorkflowTemplate$(e: CreateWorkflowTemplateDto): Observable<WorkflowTemplateDto> {
    return this.workflowService.createWorkflowTemplate(e).pipe(first());
  }

  private updateWorkflowTemplate$(e: UpdateWorkflowTemplateDto): Observable<WorkflowTemplateDto> {
    return this.workflowService.updateWorkflowTemplate(e).pipe(first());
  }

  private deleteWorkflowTemplate$(id: string): Observable<unknown> {
    return this.workflowService.deleteWorkflowTemplateById(id);
  }

  public showAcceleratedDeadlineButton(workflowTemplateDto: WorkflowTemplateDto): boolean {
    return (workflowTemplateDto.caseType as CaseType) === CaseType.Readmission;
  }

  public onCreate(): void {
    this.dialog
      .open(WorkflowCreateComponent, {
        data: {
          caseTypes: this.caseTypes,
          countries: this.countries,
          userGroups: this.userGroups,
          registeredWorkflows: this.workflowTemplates,
        } as WorkflowCreateDialogData,
      })
      .afterClosed()
      .pipe(
        first(),
        filter((e) => Boolean(e)),
        concatMap((e: CreateWorkflowTemplateDto) => this.createWorkflowTemplate$(e)),
      )
      .subscribe((e: WorkflowTemplateDto) => {
        if (e) {
          this.refreshDataSource();
        }
      });
  }

  public onEdit(workflowTemplate: WorkflowTemplateDto): void {
    this.dialog
      .open(WorkflowEditComponent, {
        data: { workflowTemplate: workflowTemplate, userGroups: this.userGroups } as WorkflowEditDialogData,
      })
      .afterClosed()
      .pipe(
        first(),
        filter((e) => Boolean(e)),
        concatMap((e: UpdateWorkflowTemplateDto) => this.updateWorkflowTemplate$(e)),
      )
      .subscribe((e: WorkflowTemplateDto) => {
        if (e) {
          this.refreshDataSource();
        }
      });
  }

  public onDelete(id: string): void {
    const index = this.workflowTemplates.findIndex((e: WorkflowTemplateDto) => e.id === id);

    if (index < 0) {
      return;
    }

    const message = this.translateService.instant('UI.CONFIRM_DELETION_QUERY');

    this.dialog
      .open(ConfirmationDialogComponent, {
        data: { message: message } as ConfirmationDialogData,
      })
      .afterClosed()
      .pipe(
        first(),
        filter((e) => e === true),
        concatMap(() => this.deleteWorkflowTemplate$(id)),
      )
      .subscribe()
      .add(() => {
        this.refreshDataSource();
      });
  }

  public openAcceleratedDeadline(e: WorkflowDeadlineTemplateDto): void {
    const dialogData: WorkflowDeadlineTemplateDialogData = {
      days: e.acceleratedDays as number,
      asCalendarDays: e.acceleratedAsCalendarDays as boolean,
      countType: e.acceleratedCountType as DeadlineCountType,
    };

    this.dialog.open(WorkflowDeadlineTemplateComponent, {
      data: dialogData,
    });
  }

  public openRegulerDeadline(e: WorkflowDeadlineTemplateDto): void {
    const dialogData: WorkflowDeadlineTemplateDialogData = {
      days: e.regularDays as number,
      asCalendarDays: e.regularAsCalendarDays as boolean,
      countType: e.regularCountType as DeadlineCountType,
    };

    this.dialog.open(WorkflowDeadlineTemplateComponent, {
      data: dialogData,
    });
  }
}
