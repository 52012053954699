import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { WorkshopAdministrationComponent } from './workshop-administration/workshop-administration.component';
import { AuthGuard } from '@shared/services/authguard';
import { workshopAdminGuard } from '../security/guards/workshop-admin.guard';
import { SharedModule } from '@shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { SetPasswordsComponent } from './dialogs/set-passwords/set-passwords.component';

const routes: Routes = [
  {
    path: '',
    component: WorkshopAdministrationComponent,
    canActivate: [AuthGuard, workshopAdminGuard],
  },
];

@NgModule({
  declarations: [WorkshopAdministrationComponent, SetPasswordsComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    RouterModule.forChild(routes),
  ]
})
export class WorkshopAdministrationModule { }
