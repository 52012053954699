/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CommentDto } from './commentDto';
import { FileDto } from './fileDto';
import { TransmitStatus } from './transmitStatus';
import { UserGroupDto } from './userGroupDto';

export interface CorrespondenceDto { 
    id?: string;
    subject?: string;
    body?: string;
    sender?: string;
    sentByPreferredUsername?: string;
    receivingCountryCode?: string;
    receivingAgencies?: Array<UserGroupDto>;
    receivingDepartments?: Array<UserGroupDto>;
    isNationalCorrespondence?: boolean;
    sentByCountryCode?: string;
    sentByAgencyId?: string;
    sentByAgency?: UserGroupDto;
    sentByDepartmentId?: string;
    sentByDepartment?: UserGroupDto;
    attachments?: Array<FileDto>;
    comments?: Array<CommentDto>;
    transmitStatus?: TransmitStatus;
    createdDate?: Date;
}