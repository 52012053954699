/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TaskStatus = 'InProgress' | 'Pending' | 'OnHold' | 'Cancelled' | 'Completed' | 'Hidden';

export const TaskStatus = {
    InProgress: 'InProgress' as TaskStatus,
    Pending: 'Pending' as TaskStatus,
    OnHold: 'OnHold' as TaskStatus,
    Cancelled: 'Cancelled' as TaskStatus,
    Completed: 'Completed' as TaskStatus,
    Hidden: 'Hidden' as TaskStatus
};