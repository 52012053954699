import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  public numberOfFailedRequests = 0;
  public showFailedConnectionError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  handleError = (state = 'minus'): void => {
    this.numberOfFailedRequests = (state === 'plus') ? this.numberOfFailedRequests + 1 : this.numberOfFailedRequests - 1;
    this.showFailedConnectionError.next(this.numberOfFailedRequests > 0);
  };
}
