<h2 mat-dialog-title>{{ 'UI.WORKFLOW.DEADLINE_DETAILS' | translate }}</h2>
<mat-dialog-content>
  <mat-list>
    <mat-list-item class="mb-4 ps-0">
      <span matListItemTitle class="fw-bold">{{ 'UI.WORKFLOW.NUMBER_OF_DAYS' | translate }}</span>
      <span> {{ dialogData.days }}</span>
    </mat-list-item>
    <mat-list-item class="mb-4 ps-0">
      <span matListItemTitle class="fw-bold">{{ 'UI.WORKFLOW.TYPE_OF_DAYS' | translate }}</span>
      <span> {{ getBusinessDayTypeTranslationKey(dialogData.asCalendarDays) | translate }} </span>
    </mat-list-item>
    <mat-list-item class="mb-4 ps-0">
      <span matListItemTitle class="fw-bold">{{ 'UI.ENUM.COUNT_TYPE' | translate }}</span>
      <span> {{ 'DeadlineCountType.' + dialogData.countType | translate }}</span>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
