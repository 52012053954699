import { Injectable } from '@angular/core';
import { Observable, map, take } from 'rxjs';
import { ApplicationService, BaseDataDto, CountryDto } from 'swagger';

@Injectable({
  providedIn: 'root',
})
export class BaseDataService {
  private baseData: BaseDataDto;

  constructor(private applicationService: ApplicationService) {
    this.fetchData();
  }

  public fetchData(): Observable<BaseDataDto> {
    return this.applicationService.getBaseData().pipe(
      take(1),
      map((response: BaseDataDto) => {
        this.baseData = response;
        return response;
      }),
    );
  }

  getData(): BaseDataDto {
    return { ...this.baseData };
  }

  getAllCountries(): Array<CountryDto> {
    return [...this.baseData.countries];
  }
}
