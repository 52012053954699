import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, map, take } from 'rxjs';
import { State } from 'src/app/state/app.state';
import { FileExtensionType } from '@shared/models/enums/fileExtensionType';
import mime from 'mime';
import { FileDto } from 'swagger';
import { FileTypeType } from '@shared/models/enums';
import { KeyValue } from '@angular/common';
import { MaxFilesizeInBytes } from '@shared/consts/file-formats';
import { UtilityService } from '@shared/services/utility.service';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(
    private sanitizer: DomSanitizer,
    private readonly store: Store<State>,
    private translation: TranslateService,
    private utilityService: UtilityService,
  ) {}

  public getEnumValueByKey(enumType: Record<string, string>, key: string): string {
    return enumType[key];
  }

  public getBase64FromDataUri(dataUri: string): string {
    const match = dataUri.match(/data:([-\w]+\/[-+\w.]+)?(;?\w+=[-\w]+)*(;base64)?,.*/) != null;

    if (match) {
      return dataUri.split(',')[1];
    }

    throw SyntaxError('Syntax of the DataUri is incorrect');
  }

  public getBlobFromDataUri(dataUri: string): Blob {
    const match = dataUri.match(/data:([-\w]+\/[-+\w.]+)?(;?\w+=[-\w]+)*(;base64)?,.*/) != null;

    if (match) {
      const base64 = dataUri.split(',')[1];
      const byteString = window.atob(base64);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: '' });
      return blob;
    }

    throw SyntaxError('Syntax of the DataUri is incorrect');
  }

  public getFileExensionIdFromDataUri(dataUri: string): number {
    const match = dataUri.match(/data:([-\w]+\/[-+\w.]+)?(;?\w+=[-\w]+)*(;base64)?,.*/) != null;

    if (match) {
      // Get the mimeType
      const type = dataUri.match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)?.[0] as string;

      const extension = mime.getExtension(type)!;
      const fileExtension = this.getEnumValueByKey(FileExtensionType, extension);

      return Number.parseInt(fileExtension);
    }

    throw SyntaxError('Syntax of the DataUri is incorrect');
  }

  public sanitizeDataUri(base64: string): SafeUrl {
    const image = this.sanitizer.bypassSecurityTrustUrl(base64);
    return image;
  }

  base64ToFile(base64: string, fileName: string): File {
    const imageBlob = this.getBlobFromDataUri(base64);
    const imageFile = new File([imageBlob], fileName, { type: '' });
    return imageFile;
  }

  fileToBase64(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target!.result!.toString()));
    return result;
  }

  base64FileSize(base64: string) {
    const stringLength = base64.length;
    const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    const sizeInKb = sizeInBytes / 1024;

    return sizeInKb;
  }

  // Query: Is the parameter really a base64 string or a datauri string containing base64?
  public dataUriAsDownload(dataUri: string, fileName?: string): void {
    const downloadLink = document.createElement('a');

    const mimeType = dataUri.split('base64,')[0];
    const removedMimeType = dataUri.split('base64,')[1];
    let filetype = mimeType.substring(mimeType.indexOf('/') + 1).slice(0, -1);

    if (filetype == 'plain') {
      filetype = 'txt';
    }

    if (filetype == 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
      filetype = 'docx';
    }

    if (filetype == 'x-zip-compressed') {
      filetype = 'zip';
    }

    const _fileName = fileName ? fileName : this.randomString(8) + '.' + filetype;

    const blob = this.getBlobFromDataUri(dataUri);
    const url = window.URL.createObjectURL(blob);

    downloadLink.href = url;
    downloadLink.download = _fileName;
    downloadLink.click();
  }

  randomString(length: number) {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  orderAsc = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    a.value = this.translation.instant(a.value);
    b.value = this.translation.instant(b.value);
    return a.value.localeCompare(b.value);
  };

  public validateFileUpload(file: File, formats: string): string | null {
    const fileExtension = file.name?.split('.').pop()?.toLowerCase() ?? '';
    const fileSize = file.size / 1024;
    if (fileExtension === '') {
      return 'ATTACHMENT_ERROR.UNABLE_TO_DETERMINE_FILE_TYPE';
    }

    if (fileSize > MaxFilesizeInBytes) {
      return 'ATTACHMENT_ERROR.MAX_SIZE';
    }

    if (!formats.includes(fileExtension)) {
      return 'ATTACHMENT_ERROR.UNSUPPORTED_FILE_TYPE';
    }
    return null;
  }

  convertFileToFileDto(file: File): Observable<FileDto> {
    return this.fileToBase64(file).pipe(
      take(1),
      map((data) => {
        const fileExtensionId = this.utilityService.getEnumKeyByValue(FileExtensionType, file.name.split('.').pop()!.toLowerCase());
        const fileDto: FileDto = {
          content: data,
          fileName: file.name,
          sizeInBytes: file.size,
          fileExtensionId: Number.parseInt(fileExtensionId),
          fileTypeId: Number.parseInt(FileTypeType.Attachment),
        };
        return fileDto;
      }),
    );
  }

  public getUtcDateTimeString(date: Date, time?: string): string | null {
    // date.toISOString() is ISO 8601 where the timzeone is always UTC
    if (time) {
      const dateTime = this.setTimeInDate(date, time);
      return dateTime ? dateTime.toISOString() : null;
    }

    return date ? date.toISOString() : null;
  }

  public setTimeInDate(date: Date, time: string): Date | null {
    if (time && date) {
      const index = time.indexOf(':');
      const hours = Number.parseInt(time.substring(0, index));
      const minutes = Number.parseInt(time.substring(index + 1));
      date.setHours(hours);
      date.setMinutes(minutes);
      date.setSeconds(0);
      return date;
    }

    return null;
  }

  public getUtcDateString(date: Date): string | null {
    // ISO 8601 where the timzeone is always UTC
    return date ? date.toISOString().substring(0, 10) : null;
  }

  public getDateString(date: Date): string | null {
    return date ? Intl.DateTimeFormat('sv-SE').format(new Date(date)) : null;
  }

  public getTimeZoneByCountry(country: string): string[] {
    const zones = moment.tz.zonesForCountry(country);
    return zones;
  }
}
