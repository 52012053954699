import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PermissionTypes } from '@shared/models/permissionTypes';
import { KeycloakService } from 'keycloak-angular';
import { CreateDisclaimerDto } from 'src/app/case/models/createDisclaimerDto';
import { UserPreferencesDto } from 'src/app/case/models/UserPreferencesDto';
import { DisclaimerComponent } from 'src/app/shared/dialogs/disclaimer/disclaimer.component';
import { UserService } from 'src/app/shared/services/user.service';
import { State } from 'src/app/state/app.state';
import { selectUserPermissions, selectUserPreferences, selectUserRoles } from 'src/app/users/state';
import { UserPageActions } from 'src/app/users/state/actions';
import { RoleTypes } from '@shared/models/roles';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', '../../../../assets/bootstrap-utilities.min.css'],
})
export class HeaderComponent implements OnInit {
  disclaimerIdentifier = 'Disclaimer of liability';
  userPreferencesToUpdate: UserPreferencesDto;
  permissionTypes = PermissionTypes;
  roleTypes = RoleTypes;
  preferredLanguage: string;
  isOptionalTheme = false;

  userRoles$ = this.store.select(selectUserRoles);
  permissions$ = this.store.select(selectUserPermissions);

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private dialog: MatDialog,
    private keycloakService: KeycloakService,
    private store: Store<State>,
  ) {}

  ngOnInit(): void {
    this.store.select(selectUserPreferences).subscribe((userPreferences) => {
      if (userPreferences?.languagePreference) {
        this.userPreferencesToUpdate = { ...userPreferences };
        this.preferredLanguage = userPreferences.languagePreference;
        this.switchToPreferredLanguage();
      }
    });
  }

  onChangedLanguage(lang: string) {
    this.translate.use(lang);
    if (this.userPreferencesToUpdate.languagePreference == lang) {
      return;
    }
    this.userPreferencesToUpdate.languagePreference = lang;
    this.store.dispatch(UserPageActions.updateUserPreferences({ userPreferences: this.userPreferencesToUpdate }));
  }

  toggleTheme() {
    this.isOptionalTheme = !this.isOptionalTheme;
  }

  openDisclaimer() {
    const disclaimerData = {
      onlyText: false,
    };
    const dialogRef = this.dialog.open(DisclaimerComponent, {
      width: '500px',
      disableClose: true,
      data: disclaimerData,
    });

    dialogRef.afterClosed().subscribe((decision) => {
      if (decision === 'true') {
        const createDisclaimerDto: CreateDisclaimerDto = {
          userId: this.userService.getLoggedInUserId(),
          disclaimerIdentifier: this.disclaimerIdentifier,
          isAccepted: true,
        };
        this.userService.createDisclaimer(createDisclaimerDto).subscribe();
      } else {
        this.keycloakService.logout();
      }
    });
  }

  private switchToPreferredLanguage() {
    if (this.preferredLanguage && this.translate.defaultLang !== this.preferredLanguage) {
      this.onChangedLanguage(this.preferredLanguage);
    }
  }
}
