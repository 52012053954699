/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type FileExtensionType = 'PDF' | 'PNG' | 'JPG' | 'JPEG' | 'XLS' | 'XLSX' | 'DOCX' | 'DOC' | 'EPS' | 'SVG' | 'ODT' | 'TXT' | 'RAR' | 'ZIP' | 'HTML' | 'HTM' | 'ODS' | 'PPT' | 'PPTX';

export const FileExtensionType = {
    PDF: 'PDF' as FileExtensionType,
    PNG: 'PNG' as FileExtensionType,
    JPG: 'JPG' as FileExtensionType,
    JPEG: 'JPEG' as FileExtensionType,
    XLS: 'XLS' as FileExtensionType,
    XLSX: 'XLSX' as FileExtensionType,
    DOCX: 'DOCX' as FileExtensionType,
    DOC: 'DOC' as FileExtensionType,
    EPS: 'EPS' as FileExtensionType,
    SVG: 'SVG' as FileExtensionType,
    ODT: 'ODT' as FileExtensionType,
    TXT: 'TXT' as FileExtensionType,
    RAR: 'RAR' as FileExtensionType,
    ZIP: 'ZIP' as FileExtensionType,
    HTML: 'HTML' as FileExtensionType,
    HTM: 'HTM' as FileExtensionType,
    ODS: 'ODS' as FileExtensionType,
    PPT: 'PPT' as FileExtensionType,
    PPTX: 'PPTX' as FileExtensionType
};