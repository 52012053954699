/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FileDto } from './fileDto';

export interface CreateCommentDto { 
    correspondenceId?: string;
    parentId?: string;
    body?: string;
    sentByUser?: string;
    sentByCountry?: string;
    sentByAgencyId?: string;
    sentByDepartmentId?: string;
    userId?: string;
    attachments?: Array<FileDto>;
    createdAt?: Date;
}