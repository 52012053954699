import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { NotificationPreferencesComponent } from 'src/app/notifications/dialogs/notification-preferences/notification-preferences.component';
import { NotificationsStateService } from 'src/app/notifications/notifications-state.service';
import { DisclaimerComponent } from 'src/app/shared/dialogs/disclaimer/disclaimer.component';
import { LoginService } from 'src/app/shared/services/login.service';
import { UserService } from 'src/app/shared/services/user.service';
import { KeycloakUserService } from '@shared/services';
import { ApplicationService, SystemStatusListDto } from 'swagger';
import { SystemstatustooltipComponent } from './tooltips/systemstatustooltip/systemstatustooltip.component';
import { take } from 'rxjs';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() public changeLanguage = new EventEmitter<any>();
  @Output() toggleTheme = new EventEmitter<void>();
  agencyEmblem: SafeUrl | null;
  systemStatusList: SystemStatusListDto;
  flagCountryCode: string = 'eu';
  fallbackFlagCountryCode: string = 'eu';

  get isAzerbaijan() {
    return this.userService.isAzerbaijan;
  }

  get loggedInUserName() {
    return this.userService.getLoggedInUserName();
  }

  get notificationsCount() {
    return this.notificationsStateService.notificationsCountNotRead;
  }

  constructor(
    public translate: TranslateService,
    private keycloakService: KeycloakService,
    private notificationsStateService: NotificationsStateService,
    private loginService: LoginService,
    private userService: UserService,
    private keycloakUserService: KeycloakUserService,
    private dialog: MatDialog,
    private applicationService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.notificationsStateService.pollUserNotifications();
    this.setFlagCountryCode();

    this.agencyEmblem = this.loginService.loggedInUserAgencyEmblem;
    this.fetchSystemStatus();
  }

  setFlagCountryCode(): void {
    const countryCode = this.loginService.loggedInUserCountryCode;
    if (countryCode) {
      this.flagCountryCode = countryCode.toLowerCase();
    }
  }

  switchLang(lang: string) {
    this.changeLanguage.emit(lang);
  }

  logout(): void {
    this.keycloakService.logout();
  }

  openSystemStatus(): void {
    this.dialog.open(SystemstatustooltipComponent, {
      data: this.systemStatusList,
    });
  }

  openDisclaimer(): void {
    const disclaimerData = {
      onlyText: true,
    };
    this.dialog.open(DisclaimerComponent, {
      width: '500px',
      data: disclaimerData,
    });
  }

  openNotificationPreferences(): void {
    this.dialog.open(NotificationPreferencesComponent, {
      width: '500px',
    });
  }

  fetchSystemStatus(): void {
    this.applicationService.getSystemStatuses().pipe(take(1)).subscribe((res) => {
      this.systemStatusList = res;
    });
  }

  changePassword(): void {
    this.keycloakUserService.changePassword();
  }
}
