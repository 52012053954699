import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UserPreferencesDto } from 'src/app/case/models/UserPreferencesDto';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { State } from 'src/app/state/app.state';
import { selectDisabledEmailNotificationPreferences, selectDisabledSystemNotificationPreferences, selectUserPreferences } from 'src/app/users/state';
import { UserPageActions } from 'src/app/users/state/actions';
import { NotificationLevel } from 'swagger';

@Component({
  selector: 'app-notification-preferences',
  templateUrl: './notification-preferences.component.html',
  styleUrls: ['./notification-preferences.component.scss']
})
export class NotificationPreferencesComponent implements OnInit {
  userPreferencesToUpdate: UserPreferencesDto;
  emailNotificationPreferences$: Observable<any>;
  systemNotificationPreferences$: Observable<any>;
  NotificationLevel = NotificationLevel;

  constructor (private dialogRef: MatDialogRef<NotificationPreferencesComponent>,
    private notificationService: NotificationService, private translate: TranslateService,
    private store: Store<State>) {
  }

  ngOnInit(): void {
    this.store.select(selectUserPreferences).pipe().subscribe(userPreferences => {
      if (userPreferences) {
        this.userPreferencesToUpdate = { ...userPreferences };
      }
    });
    this.emailNotificationPreferences$ = this.store.select(selectDisabledEmailNotificationPreferences);
    this.systemNotificationPreferences$ = this.store.select(selectDisabledSystemNotificationPreferences);
  }

  close() {
    this.dialogRef.close();
  }

  IsChecked(notificationLevel: NotificationLevel, notificationLevels: NotificationLevel[]): boolean {
    if (notificationLevels == null || notificationLevels.length == 0) {
      return true;
    }
    switch (notificationLevel) {
      case NotificationLevel.RequiringAction:
        return !notificationLevels.includes(notificationLevel);
      case NotificationLevel.Informative:
        return !notificationLevels.includes(notificationLevel);

      default:
        return false;
    }
  }

  toggleNotificationPreference(checked: boolean, isSystemNotification: boolean) {
    if (isSystemNotification && !checked) {
      this.userPreferencesToUpdate.disabledSystemNotificationPreference = [NotificationLevel.Informative];
    }

    if (isSystemNotification && checked) {
      this.userPreferencesToUpdate.disabledSystemNotificationPreference = [];
    }

    if (!isSystemNotification && !checked) {
      this.userPreferencesToUpdate.disabledEmailNotificationPreference = [NotificationLevel.Informative];
    }

    if (!isSystemNotification && checked) {
      this.userPreferencesToUpdate.disabledEmailNotificationPreference = [];
    }
  }

  savePreferences() {
    this.store.dispatch(UserPageActions.updateUserPreferences({ userPreferences: this.userPreferencesToUpdate }));
    this.notificationService.showSuccess(this.translate.instant('NOTIFICATION_PREFERENCES_SUCCESSFULLY_SAVED'), '');
    this.close();
  }
}
