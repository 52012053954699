/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CaseStatus } from './caseStatus';

export interface RelatedRecordDto { 
    id?: string;
    caseId?: string;
    relatedCaseId?: string;
    requestingStateCaseReferenceNo?: string;
    requestedStateCaseReferenceNo?: string;
    caseStatus?: CaseStatus;
    givenName?: string;
    surname?: string;
    maidenName?: string;
}