import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceSeparator'
})
export class SpaceSeparatorPipe implements PipeTransform {

  transform(value: string): string {
    let result = value.replace(/(?!^)([A-Z]|\d+)/g, ' $1');
    result = result.replace('-', ' ');
    return result;
  }

}
