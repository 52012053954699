import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IcaoResult } from '../../models';

@Component({
  selector: 'app-photo-results',
  templateUrl: './photo-results.component.html',
  styleUrls: ['./photo-results.component.scss']
})
export class PhotoResultsComponent {
  displayedColumns: string[] = ['description', 'status'];
  constructor(@Inject(MAT_DIALOG_DATA) public icao: IcaoResult) { }
}
