import { Injectable } from '@angular/core';
import { GroupRepresentationDto } from 'swagger/model/groupRepresentationDto';
import { KeycloakService as ApiKeycloakService, TaskRequestType, TaskType } from 'swagger';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class KeycloakService {
  constructor(private apiKeycloakService: ApiKeycloakService) {}

  public getCorrespondenceRecipients(isInternational: boolean, caseId: string): Observable<GroupRepresentationDto[]> {
    return this.apiKeycloakService.getCorrespondenceRecipients(isInternational, caseId);
  }

  public getNationalAgencies(): Observable<GroupRepresentationDto[]> {
    return this.apiKeycloakService.getNationalAgencies();
  }

  public getTaskGroupRecipientsByTaskType(isNational: boolean, taskType: TaskType, caseId: string): Observable<GroupRepresentationDto[]> {
    return this.apiKeycloakService.getTaskGroupRecipientsByTaskType(isNational, taskType, caseId);
  }

  public getTaskGroupRecipientsByTaskTypeAndTaskRequestType(
    isNational: boolean,
    taskType: TaskType,
    caseId: string,
    taskRequestType: TaskRequestType,
  ): Observable<GroupRepresentationDto[]> {
    return this.apiKeycloakService.getTaskGroupRecipientsByTaskType_1(isNational, taskType, caseId, taskRequestType);
  }
}
