/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type DayOfWeek = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';

export const DayOfWeek = {
    Sunday: 'Sunday' as DayOfWeek,
    Monday: 'Monday' as DayOfWeek,
    Tuesday: 'Tuesday' as DayOfWeek,
    Wednesday: 'Wednesday' as DayOfWeek,
    Thursday: 'Thursday' as DayOfWeek,
    Friday: 'Friday' as DayOfWeek,
    Saturday: 'Saturday' as DayOfWeek
};