import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '@shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { take } from 'rxjs';

@Component({
  selector: 'app-set-passwords',
  templateUrl: './set-passwords.component.html',
  styleUrls: ['./set-passwords.component.scss'],
})
export class SetPasswordsComponent {
  form: FormGroup;

  public constructor(
    public dialogRef: MatDialogRef<SetPasswordsComponent>,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}')]],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    if (this.form.valid) {
      const dialog = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('Workshop.ConfirmDelete'),
        },
      });

      dialog
        .afterClosed()
        .pipe(take(1))
        .subscribe((result: boolean) => {
          if (result) {
            this.dialogRef.close(this.form.value.password);
          }
        });
    }
  }
}
