<mat-form-field appearance="fill" class="w-100">
  <mat-label class="text-black">{{ 'USER_ADMINISTRATION.GROUP' | translate }} *</mat-label>
  <input type="text" matInput [matAutocomplete]="auto" [formControl]="states" value={{groupName}} />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-optgroup *ngFor="let group of stateList">
      <div>
        <mat-radio-button [checked]="group.checked" (change)="toggleSelection($event, group, group)"
          (click)="$event.stopPropagation()">
          {{ group.name!.split(':').pop() }}
        </mat-radio-button>
        <button mat-button (click)="expandDocumentTypes(group)" *ngIf="group.childGroups!.length > 0">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      </div>
      <mat-option *ngFor="let childGroup of group.childGroups"
        [ngClass]="isExpandedAgency[group.id!] ? 'list-show' : 'list-hide'">
        <mat-radio-button [checked]="childGroup.checked" (change)="toggleSelection($event, childGroup, group)"
          (click)="$event.stopPropagation()">
          {{ childGroup.name!.split(':').pop() }}
        </mat-radio-button>
      </mat-option>

      <mat-option *ngIf="group.childGroups!.length < 1" [ngClass]="'list-hide'">
        <mat-radio-button>
          {{ '' }}
        </mat-radio-button>
      </mat-option>
    </mat-optgroup>
  </mat-autocomplete>
</mat-form-field>