/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type NotificationLevel = 'RequiringAction' | 'Informative';

export const NotificationLevel = {
    RequiringAction: 'RequiringAction' as NotificationLevel,
    Informative: 'Informative' as NotificationLevel
};