import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as AppState from '../../state/app.state';
import { CaseState } from './case.reducer';
import { CaseStatus, TransmitStatus } from 'swagger';

export * from './case-state.service';

export interface State extends AppState.State {
  Case: CaseState;
}

const selectCaseFeatureState = createFeatureSelector<CaseState>('case');

export const selectIsTranslationMode = createSelector(selectCaseFeatureState, (state) => state.isTranslationMode);

export const selectCaseData = createSelector(selectCaseFeatureState, (state) => state.currentCaseData);

export const selectIsTransmissionStatus = (transmitStatus: TransmitStatus) =>
  createSelector(selectCaseData, (caseData) => caseData?.transmitStatus == transmitStatus ?? false);

export const selectIsCurrentCaseOutcomeShared = createSelector(selectCaseFeatureState, (state) => state.currentCaseData?.outcome?.isShared ?? null);

export const selectCaseId = createSelector(selectCaseData, (currentCaseData) => currentCaseData?.id);

export const selectCurrentCaseReceivingCountryCode = createSelector(selectCaseFeatureState, (state) => state.currentCaseData?.receivingCountryCode);

export const selectCurrentCaseIsRequestingCountry = createSelector(selectCaseFeatureState, (state) => state.currentCaseData?.isRequestingCountry);

export const selectCurrentCaseSentByCountryCode = createSelector(selectCaseFeatureState, (state) => state.currentCaseData?.sentByCountryCode);

export const selectCaseDataFamilyMembers = createSelector(selectCaseFeatureState, (state) => state.currentCaseData?.familyMembers);

export const selectCaseDataMeansOfEvidences = createSelector(selectCaseFeatureState, (state) => state.currentCaseData?.meansOfEvidences);

export const selectCaseDataTransferModality = createSelector(selectCaseFeatureState, (state) => state.currentCaseData?.transferModality);

export const selectCaseDataTransferModalityId = createSelector(selectCaseDataTransferModality, (transferModality) => transferModality?.id ?? null);

export const selectIsTransferModalityTransmissionStatus = (transmitStatus: TransmitStatus) =>
  createSelector(selectCaseDataTransferModality, (transferModality) => transferModality?.transmitStatus === transmitStatus ?? false);

export const selectTranslatedCaseData = createSelector(selectCaseFeatureState, (state) => state.currentTranslatedCase);

export const selectCaseCorrespondences = createSelector(selectCaseData, (state) => state?.correspondences);

export const selectCorrespondenceCommentByCorrespondenceId = (correspondenceId: string) =>
  createSelector(
    selectCaseCorrespondences,
    (correspondences) => correspondences?.find((correspondence) => correspondence.id === correspondenceId)?.comments,
  );

export const selectCorrespondenceCommentByCorrespondenceIdAndCommentId = (correspondenceId: string, parentId: string) =>
  createSelector(selectCorrespondenceCommentByCorrespondenceId(correspondenceId), (comments) => comments?.find((comment) => comment.id === parentId));

export const selectInternationalCaseCorrespondences = createSelector(
  selectCaseCorrespondences,
  (correspondences) => correspondences?.filter((correspondence) => correspondence.isNationalCorrespondence == false),
);

export const selectNationalCaseCorrespondences = createSelector(
  selectCaseCorrespondences,
  (correspondences) => correspondences?.filter((correspondence) => correspondence.isNationalCorrespondence == true),
);

export const selectRelatedRecords = createSelector(selectCaseFeatureState, (state) => state.currentCaseData?.relatedRecords);

export const selectRelatedRecordsCount = createSelector(selectCaseFeatureState, (state) => state.currentCaseData?.relatedRecords?.length ?? 0);

export const selectHasBiometrics = createSelector(
  selectCaseData,
  (caseData) =>
    caseData?.biometrics?.leftFourFingers != null || caseData?.biometrics?.rightFourFingers != null || caseData?.biometrics?.twoThumbs != null,
);

export const selectIsCaseSatus = (caseStatus: CaseStatus) => createSelector(selectCaseData, (caseData) => caseData?.caseStatus == caseStatus);

export const selectRequestingStateCaseReferenceNumber = createSelector(
  selectCaseFeatureState,
  (state) => state.currentCaseData?.requestingStateCaseReferenceNo,
);

export const selectRequestedStateCaseReferenceNumber = createSelector(
  selectCaseFeatureState,
  (state) => state.currentCaseData?.requestedStateCaseReferenceNo,
);

export const selectIsInterviewTabActive = createSelector(
  selectCaseData,
  (state) => (state?.interviewRequest && state?.caseStatus != CaseStatus.Saved) ?? false,
);

export const selectInterviewStatus = createSelector(selectCaseData, (state) => state?.interview?.interviewStatus ?? null);

export const selectIsInterviewReceiver = createSelector(selectCaseData, (state) => state?.interview?.isReceivingCountry ?? false);

export const selectIsInterviewRequested = createSelector(selectCaseData, (state) => state?.interviewRequest ?? false);

export const selectInterviewOutcomeAttachments = createSelector(selectCaseData, (state) => state?.interview?.interviewOutcome?.attachments);

export const selectIsReactivated = createSelector(selectCaseData, (caseData) => (caseData?.reactivated ? caseData.reactivated : false));
