/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CaseWorker } from './caseWorker';
import { Comment } from './comment';
import { Correspondence } from './correspondence';
import { Country } from './country';
import { ModelCase } from './modelCase';
import { Task } from './task';
import { UserGroupAttribute } from './userGroupAttribute';

export interface UserGroup { 
    id?: string;
    name?: string;
    fileId?: string;
    logoFileId?: string;
    logo?: any;
    parentId?: string;
    childGroups?: Array<UserGroup>;
    countryCode?: string;
    country?: Country;
    isMainAgency?: boolean;
    isMainConsularAgency?: boolean;
    isPublic?: boolean;
    userLimit?: number;
    internal?: boolean;
    visible?: boolean;
    installation?: string;
    createdDate?: Date;
    updatedDate?: Date;
    userGroupAttributes?: Array<UserGroupAttribute>;
    casesReceivedByAgency?: Array<ModelCase>;
    casesSubmittedByAgency?: Array<ModelCase>;
    taskAgency?: Array<Task>;
    taskDepartment?: Array<Task>;
    tasks?: Array<Task>;
    correspondenceSentByAgencies?: Array<Correspondence>;
    correspondanceSentByDepartments?: Array<Correspondence>;
    commentSentByAgencies?: Array<Comment>;
    commentSentByDepartments?: Array<Comment>;
    correspondenceReceivingAgencies?: Array<Correspondence>;
    correspondenceReceivingDepartments?: Array<Correspondence>;
    agencyCaseWorkers?: Array<CaseWorker>;
    departmentCaseWorkers?: Array<CaseWorker>;
}