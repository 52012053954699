import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MonitoringService } from './core/monitoring/monitoring.service';
import { LoginService } from '@shared/services';
import { BaseDataService } from '@shared/services/base-data.service';
import { AvailableTranslationDto, TranslationService } from 'swagger';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  userHasLoaded = false;

  constructor(
    private translate: TranslateService,
    private monitoring: MonitoringService,
    private loginService: LoginService,
    private baseData: BaseDataService,
    private translationService: TranslationService,
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
    monitoring.logEvent('Using Application Insights');
  }

  switchLang(lang: string): void {
    this.translate.use(lang);
  }

  ngOnInit(): void {
    this.loginService.loggedInUserLoaded.subscribe({
      next: (userHasLoaded: boolean) => {
        if (userHasLoaded) {
          this.baseData.fetchData().subscribe({
            next: () => {
              this.userHasLoaded = true;
            },
          });
        }
      this.fetchEnabledLocales();
      },
    });
  }

  fetchEnabledLocales() {
    this.translationService.getActivatedTranslations(true).subscribe((res: AvailableTranslationDto[]) => {
      const enabledLanguages = res.map((x) => x.locale) as string[];
      this.translate.addLangs(enabledLanguages);
    });
  }
}
