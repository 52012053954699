<div *ngIf="cases != undefined" class="mat-table-wrapper">
    <mat-table #table [dataSource]="dataSource" class="mat-table" multiTemplateDataRows autoLayout="true">
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <!-- Checkbox Column -->
        <ng-container matColumnDef="selectCase">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let case">
                <mat-checkbox matInput color="accent" [checked]="cases.includes(case.id)"
                    (change)="onCaseSelection($event, case)" (click)="$event.stopPropagation()"></mat-checkbox>
            </mat-cell>
        </ng-container>
        <!-- Row -->
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
        <!-- Columns -->
        <ng-container [matColumnDef]="'requestedStateCaseReferenceNo'">
            <mat-header-cell *matHeaderCellDef style="min-width: 100px;"> {{
                "CASE_ATTRIBUTES.REQUESTED_STATE_CASE_REFERENCE_NO" | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let row" style="min-width: 100px;"> {{row.requestedStateCaseReferenceNo}} </mat-cell>
        </ng-container>
        <ng-container [matColumnDef]="'requestingStateCaseReferenceNo'">
            <mat-header-cell *matHeaderCellDef style="min-width: 100px;"> {{
                "CASE_ATTRIBUTES.REQUESTING_STATE_CASE_REFERENCE_NO" | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let row" style="min-width: 100px;"> {{row.requestingStateCaseReferenceNo}}
            </mat-cell>
        </ng-container>
        <ng-container [matColumnDef]="'givenName'">
            <mat-header-cell *matHeaderCellDef style="min-width: 100px;"> {{ "PERSONAL_DETAILS.GIVEN_NAME" | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" style="min-width: 100px;"> {{row.givenName}} </mat-cell>
        </ng-container>
        <ng-container [matColumnDef]="'maidenName'">
            <mat-header-cell *matHeaderCellDef style="min-width: 100px;"> {{ "PERSONAL_DETAILS.MAIDEN_NAME" | translate
                }} </mat-header-cell>
            <mat-cell *matCellDef="let row" style="min-width: 100px;"> {{row.maidenName}} </mat-cell>
        </ng-container>
        <ng-container [matColumnDef]="'surName'">
            <mat-header-cell *matHeaderCellDef style="min-width: 100px;"> {{ "PERSONAL_DETAILS.SURNAME" | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" style="min-width: 100px;"> {{row.surname}} </mat-cell>
        </ng-container>
        <ng-container [matColumnDef]="'caseStatus'">
            <mat-header-cell *matHeaderCellDef style="min-width: 100px;"> {{ "CASE_ATTRIBUTES.CASE_STATUS" | translate
                }} </mat-header-cell>
            <mat-cell *matCellDef="let row" style="min-width: 100px;">
                <span class="status" [ngClass]="caseStateService.getCaseStatusClass(row.caseStatus)"> {{ row.caseStatus
                    | translate }} </span>
            </mat-cell>
        </ng-container>
        <ng-container [matColumnDef]="'caseType'">
            <mat-header-cell *matHeaderCellDef style="min-width: 100px;"> {{ "CASE_ATTRIBUTES.CASE_TYPE" | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" style="min-width: 100px;"> {{row.caseType}} </mat-cell>
        </ng-container>
    </mat-table>
    <div class="add-btn-wrapper">
        <button mat-button class="add-btn" (click)="onAddCases()">
            <mat-icon color="primary">add_circle_outline</mat-icon> {{"ACTIONS.ADD" | translate}} </button>
    </div>
</div>