import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionCleaner } from '@shared/helper/subscription-cleaner/subscription-cleaner.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent extends SubscriptionCleaner implements OnInit {
  @Input() items: string[] = [];
  @Input() formControlField: FormControl;
  @Input() translationKey: string;
  @Input() labelTranslationKey: string;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  filteredItems: string[] = [];

  constructor(public translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.filter();
    });
    this.formControlField.addValidators([this.customErrorValidator('itemNotFound')]);
  }

  onInputChange(): void {
    const filterValue = this.input?.nativeElement?.value.toLowerCase();
    this.formControlField.setErrors(null);

    if (!filterValue) {
      this.formControlField.patchValue(null);
    } else {
      const itemExists = this.items.some(
        (item: any) =>
          this.getTranslation(item, this.translateService.currentLang).toLowerCase() === filterValue && item === this.formControlField.value,
      );

      if (!itemExists) {
        this.formControlField.setErrors({ ...this.formControlField.errors, itemNotFound: true });
      }
    }

    this.filter();
  }

  optionSelected(): void {
    this.onInputChange();
  }

  filter(): void {
    const filterValue = this.input?.nativeElement?.value?.toLowerCase() ?? '';
    const currentLanguage = this.translateService.currentLang;

    const filteredItems = this.items
      .filter((item: string) => this.getTranslation(item, currentLanguage).toLowerCase().includes(filterValue))
      .sort((itemA: string, itemB: string) => {
        const translationA = this.getTranslation(itemA, currentLanguage);
        const translationB = this.getTranslation(itemB, currentLanguage);

        return translationA.localeCompare(translationB, currentLanguage, {
          sensitivity: 'base',
        });
      });

    this.filteredItems = filteredItems;
  }

  getTranslation(item: string, language: string): string {
    return this.translateService.instant(this.translationKey + '.' + item, { locale: language });
  }

  displayFn = (item: string): string => {
    if (item) {
      return this.translateService.instant(this.translationKey + '.' + item, { locale: this.translateService.currentLang });
    }
    return '';
  };

  customErrorValidator(errorName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const errors = control.errors || {};

      if (errors[errorName]) {
        return { [errorName]: true };
      }

      return null;
    };
  }
}
