<button mat-button (click)="$event.stopPropagation(); refreshNotifications()" class="right">
  <mat-icon [@rotatedState]='state'>cached</mat-icon>
</button>
<div *ngIf="loaded && notifications.length > 0">

  <h2 style="margin-left: .5rem;">{{ "NOTIFICATIONS.NOTIFICATIONS" | translate }}</h2>

  <mat-form-field style=" width: 80%; padding-left: 1rem;" class="center flex" appearance="standard">
    <mat-label> {{'ACTIONS.FILTER' | translate}} </mat-label>
    <input matInput (click)="$event.stopPropagation()" (keyup)=" applyFilter($event)"
      placeholder="{{'ACTIONS.FILTER' | translate}}" #input>
  </mat-form-field>

  <mat-chip-list selectable style=" margin: 1rem;" class="flex center">
    <mat-chip [matBadge]="notificationsCountRead" [selected]="typeOfFilter === 'read'"
      (click)="$event.stopPropagation(); filterRead(true)">
      {{ "NOTIFICATIONS.READ" | translate }}
    </mat-chip>
    <mat-chip [matBadge]="notificationsCountNotRead" [selected]="typeOfFilter === 'unread'"
      (click)="$event.stopPropagation(); filterRead(false)">
      {{ "NOTIFICATIONS.UNREAD" | translate }}
    </mat-chip>
    <mat-chip [matBadge]="notificationsCount" [selected]="typeOfFilter === 'all'"
      (click)="$event.stopPropagation(); filterRead(null)">
      {{ "NOTIFICATIONS.ALL" | translate }}
    </mat-chip>
  </mat-chip-list>

  <div class="action-buttons">
    <button mat-stroked-button color="accent" (click)="setAllRead()">
      {{"NOTIFICATIONS.SET_ALL_AS_READ" | translate}}
    </button>
    <button mat-raised-button color="warn" (click)="deleteAllNotifications()">
      {{"ACTIONS.DELETE_ALL" | translate}}
    </button>
  </div>

  <div *ngFor="let n of notificationsFiltered">
    <div class="separator"></div>
    <app-notification-row [notification]="n"></app-notification-row>
  </div>
</div>
<div *ngIf="loaded && notifications.length < 1" class="center">
  <h3> {{"NOTIFICATIONS.NO_NOTIFICATIONS" | translate}}</h3>
</div>
