import { Component, Inject } from '@angular/core';
import { CaseType, CountryDto, CreateWorkflowTemplateDto, DeadlineCountType, UserGroupAttribute, UserGroupDto, WorkflowTemplateDto } from 'swagger';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WorkflowCreateDialogData } from './workflow-create.interface';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, UtilityService } from '@shared/services';
import { EnumObject } from '@shared/models/enumObject';
@Component({
  selector: 'app-workflow-create',
  templateUrl: './workflow-create.component.html',
  styleUrls: ['../../../../../assets/bootstrap-utilities.min.css'],
})
export class WorkflowCreateComponent {
  deadlineCountTypes: EnumObject[] = this.utilityService.convertToEnumObject(DeadlineCountType, 'DeadlineCountType', this.translateService);
  private caseTypes: CaseType[];
  unSelectedCaseTypes: CaseType[];
  countries: CountryDto[];
  userGroups: UserGroupDto[];
  departmentsOfSelectedAgency: UserGroupDto[] | null;
  workflowForm: FormGroup;
  registeredWorkflows: WorkflowTemplateDto[];
  ready = false;

  public constructor(
    public dialogRef: MatDialogRef<WorkflowCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: WorkflowCreateDialogData,
    private formBuilder: FormBuilder,
    protected translateService: TranslateService,
    private notificationService: NotificationService,
    private utilityService: UtilityService,
  ) {
    this.init();
  }

  public init() {
    this.caseTypes = this.dialogData.caseTypes;
    this.countries = this.dialogData.countries;
    this.userGroups = this.dialogData.userGroups.filter((ug: UserGroupDto) => !ug.parentId && this.groupIsPublic(ug));
    this.registeredWorkflows = this.dialogData.registeredWorkflows;
    this.countries = this.getUnSelectedCountries(this.dialogData.countries);

    this.workflowForm = this.formBuilder.group({
      caseType: ['', [Validators.required]],
      assignToGroupId: ['', [Validators.required, Validators.minLength(2)]],
      assignToDepartmentId: [null],
      requestingCountryCode: ['', [Validators.required, Validators.minLength(2)]],
      deadline: this.formBuilder.group({
        regularDays: ['', [Validators.required, Validators.min(1)]],
        regularCountType: ['', [Validators.required]],
        regularAsCalendarDays: ['', [Validators.required]],
        acceleratedDays: ['', [Validators.required, Validators.min(1)]],
        acceleratedCountType: ['', [Validators.required]],
        acceleratedAsCalendarDays: ['', [Validators.required]],
      }),
    });

    this.ready = true;
  }

  protected getUnSelectedCountries(countries: CountryDto[]): CountryDto[] {
    return countries.filter((c: CountryDto) => this.isCountrySelectable(c));
  }

  private isCountrySelectable(c: CountryDto): boolean {
    const caseTypesSet = new Set(
      this.registeredWorkflows
        .filter((e: WorkflowTemplateDto) => e.requestingCountry?.code === c.code)
        .map((e: WorkflowTemplateDto) => e.caseType as CaseType),
    );

    if (caseTypesSet.size !== this.caseTypes.length) {
      for (let x = 0; x < this.caseTypes.length; x++) {
        const c = this.caseTypes[x];
        if (!caseTypesSet.has(c)) {
          return true;
        }
      }
    }

    return false;
  }

  protected setUnSelectedCaseTypes(event: any): void {
    const countryCode = event.value;

    const selectedCaseTypes: CaseType[] = this.registeredWorkflows
      .filter((e) => e.requestingCountry?.code === countryCode)
      .map((e) => e.caseType as CaseType);

    this.unSelectedCaseTypes = this.caseTypes.filter((e) => selectedCaseTypes.findIndex((e1) => e1 === e) === -1);
  }

  private groupIsPublic(userGroupDto: UserGroupDto): boolean {
    return (
      userGroupDto.userGroupAttributes?.findIndex((attribute: UserGroupAttribute) => attribute.key === 'public' && attribute.value === 'true') !== -1
    );
  }

  public onAgencySelectionChange(event: any): void {
    const selectedAgency = this.userGroups.find((ug: UserGroupDto) => ug.id == event.value);

    if (selectedAgency?.childGroups) {
      this.departmentsOfSelectedAgency = selectedAgency?.childGroups.filter((ug: UserGroupDto) => this.groupIsPublic(ug));
    } else {
      this.departmentsOfSelectedAgency = null;
    }
  }

  public onCaseTypeChange(event: any): void {
    const acceleratedDaysControl = this.workflowForm.get('deadline')?.get('acceleratedDays');
    const acceleratedCountTypeControl = this.workflowForm.get('deadline')?.get('acceleratedCountType');
    const acceleratedAsCalendarDaysControl = this.workflowForm.get('deadline')?.get('acceleratedAsCalendarDays');

    if ((event.value as CaseType) == CaseType.Readmission) {
      acceleratedDaysControl?.enable();
      acceleratedCountTypeControl?.enable();
      acceleratedAsCalendarDaysControl?.enable();
    } else {
      acceleratedDaysControl?.disable();
      acceleratedCountTypeControl?.disable();
      acceleratedAsCalendarDaysControl?.disable();
    }
  }

  private getResultData(): CreateWorkflowTemplateDto {
    return {
      caseType: this.workflowForm.get('caseType')?.value,
      requestingCountryCode: this.workflowForm.get('requestingCountryCode')?.value,
      assignToGroupId: this.workflowForm.get('assignToDepartmentId')?.value ?? this.workflowForm.get('assignToGroupId')?.value,
      createWorkflowDeadlineTemplateDto: {
        regularCountType: this.workflowForm.get(['deadline', 'regularCountType'])?.value,
        regularAsCalendarDays: this.workflowForm.get(['deadline', 'regularAsCalendarDays'])?.value,
        regularDays: this.workflowForm.get(['deadline', 'regularDays'])?.value,
        acceleratedCountType: this.workflowForm.get(['deadline', 'acceleratedCountType'])?.value,
        acceleratedAsCalendarDays: this.workflowForm.get(['deadline', 'acceleratedAsCalendarDays'])?.value,
        acceleratedDays: this.workflowForm.get(['deadline', 'acceleratedDays'])?.value,
      },
    };
  }

  public showAcceleratedFields(): boolean {
    return (this.workflowForm.get('caseType')?.value as CaseType) == CaseType.Readmission;
  }

  public onClose(save = false): void {
    if (save != true) {
      this.dialogRef.close();
    } else {
      this.workflowForm.invalid
        ? this.notificationService.showError(this.translateService.instant('UI.WORKFLOW.ERRONEOUS_WORKFLOW'))
        : this.dialogRef.close(this.getResultData());
    }
  }
}
