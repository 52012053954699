/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DeadlineCountType } from './deadlineCountType';

export interface UpdateWorkflowDeadlineTemplateDto { 
    regularDays?: number;
    acceleratedDays?: number;
    regularAsCalendarDays?: boolean;
    acceleratedAsCalendarDays?: boolean;
    regularCountType?: DeadlineCountType;
    acceleratedCountType?: DeadlineCountType;
}