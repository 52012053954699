import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { TaskService as ApiTaskService, CreateTaskDto, CreateTaskResponseDto, TaskDto, TaskType, TaskRequestType } from 'swagger';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  public constructor(private apiTaskService: ApiTaskService) {}

  public getTasksByCaseId(caseId: string): Observable<TaskDto[]> {
    return this.apiTaskService.getTasksByCaseId(caseId);
  }

  public deleteTask(id: string): Observable<unknown> {
    return this.apiTaskService.deleteTaskById(id);
  }

  // TODO: add the return type attribute to the action method of the controller in order to get known return type
  public createTask(body: CreateTaskDto): Observable<unknown> {
    return this.apiTaskService.createTask(body);
  }

  public createTaskResponse(body: CreateTaskResponseDto): Observable<unknown> {
    return this.apiTaskService.createTaskResponse(body);
  }

  public updateTask(body: TaskDto): Observable<unknown> {
    return this.apiTaskService.updateTask(body);
  }

  public getTaskTypesByCaseId(caseId: string): Observable<TaskType[]> {
    return this.apiTaskService.getTaskTypesByCaseId(caseId);
  }

  public getTaskRequestTypesByCaseId(caseId: string, isNational: boolean): Observable<TaskRequestType[]> {
    return this.apiTaskService.getTaskRequestTypesByCaseId(caseId, isNational);
  }

  public completeTask(taskId: string): Observable<unknown> {
    return this.apiTaskService.completeTask(taskId);
  }
}
