<mat-form-field style="width: 100%;">
    <mat-label>{{ labelTranslationKey | translate }}</mat-label>
    <input
      #input
      matInput
      [matAutocomplete]="auto"
      placeholder="{{ labelTranslationKey | translate }}"
      [formControl]="formControlField"
      (input)="onInputChange()"
      (focus)="filter()"
      (onBlur)="onInputChange()"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" requireSelection (optionSelected)="optionSelected()">
      <mat-option *ngFor="let item of filteredItems" [value]="item">
        {{ translationKey + '.' + item | translate }}
      </mat-option>
    </mat-autocomplete>
</mat-form-field>
