import { Component, Inject } from '@angular/core';
import { DeadlineCountType } from 'swagger';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkflowDeadlineTemplateDialogData } from './workflow-deadline-template.interface';
@Component({
  selector: 'app-workflow-deadline-template',
  templateUrl: './workflow-deadline-template.html',
  styleUrls: ['../../../../../assets/bootstrap-utilities.min.css'],
})
export class WorkflowDeadlineTemplateComponent {
  deadlineCountTypes: DeadlineCountType[] = Object.values(DeadlineCountType);
  ready = false;

  public constructor(
    public dialogRef: MatDialogRef<WorkflowDeadlineTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) protected dialogData: WorkflowDeadlineTemplateDialogData,
  ) {}

  protected getBusinessDayTypeTranslationKey(calendar: boolean): string {
    return calendar ? 'BusinessDayType.Calendar' : 'BusinessDayType.Working';
  }

  public onClose() {
    this.dialogRef.close();
  }
}
