/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CaseType } from './caseType';
import { CountryDto } from './countryDto';
import { UserGroupDto } from './userGroupDto';
import { WorkflowDeadlineTemplateDto } from './workflowDeadlineTemplateDto';

export interface WorkflowTemplateDto { 
    id?: string;
    caseType?: CaseType;
    requestingCountry?: CountryDto;
    assignToGroupId?: string;
    assignToGroup?: UserGroupDto;
    isDefault?: boolean;
    createdByUserId?: string;
    workflowDeadlineTemplateDto?: WorkflowDeadlineTemplateDto;
}