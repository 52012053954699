import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  
  public constructor(private translateService: TranslateService) {}
  
  public getLanguageCode(): string | undefined {
    return this.translateService.currentLang ?? this.translateService.defaultLang;
  }
}
