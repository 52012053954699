/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type DeadlineUnitType = 'Hours' | 'Days';

export const DeadlineUnitType = {
    Hours: 'Hours' as DeadlineUnitType,
    Days: 'Days' as DeadlineUnitType
};