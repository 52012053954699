import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KeycloakUserService } from '@shared/services';
import { GroupRepresentationDto } from 'src/app/case/models/groupRepresentationDto';
import { UserGroupDto } from 'swagger';

export interface StateGroup {
  index: number;
  checked: boolean;
  names: string[];
}

@Component({
  selector: 'app-multi-dropdown',
  templateUrl: './multi-dropdown.component.html',
  styleUrls: ['./multi-dropdown.component.scss'],
})
export class MultiDropdownComponent {
  @Output() selectedGroupId = new EventEmitter<string | null>();
  @Input() groupId = '';
  @Input() groupName = '';
  @Input() groups: GroupRepresentationDto[] = [];
  constructor(private usersService: KeycloakUserService) {}

  isExpandedAgency: any = [];
  states = new FormControl();
  stateList: UserGroupDto[] = [];

  ngOnInit(): void {
    this.usersService.getAllGroups().subscribe((res) => {
      var userGroups = res as UserGroupDto[];
      if (this.groupId) {
        let userGroupIndex = userGroups.findIndex((userGroup) => userGroup.id == this.groupId);
        if (userGroupIndex > -1) {
          userGroups[userGroupIndex].checked = true;
          this.states.setValue(userGroups[userGroupIndex].name!.split(':').pop()!);
        } else {
          userGroupIndex = userGroups.findIndex((f) => f.childGroups!.some((s) => s.id == this.groupId));
          if (userGroupIndex > -1) {
            const group = userGroups[userGroupIndex];
            this.isExpandedAgency[group.id!] = true;
            const userChildGroupIndex = group.childGroups!.findIndex((userGroup) => userGroup.id == this.groupId);
            group.childGroups![userChildGroupIndex].checked = true;
            this.states.setValue(group.childGroups![userChildGroupIndex].name!.split(':').pop()!);
          }
        }
      }

      this.stateList = userGroups;
    });
  }

  expandDocumentTypes(group: any) {
    this.isExpandedAgency[group.id] = !this.isExpandedAgency[group.id];
  }

  toggleSelection(event: any, name: any, group: any) {
    this.groupName = name.name.split(':').pop();
    this.selectedGroupId.emit(name.id);
  }
}
