import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/app-config/app-config.service';
import { IcaoResult } from 'src/app/case/models';

@Injectable({
  providedIn: 'root',
})
export class FileService {

  apiBaseUrl = this.config.getConfig().api.baseUrl;
  icaoPath = '/api/icao/validate';
  filePath = '/api/file';

  constructor(
    private http: HttpClient,
    private config: AppConfigService
  ) { }

  getFile(fileId: string){
    return this.http.get<string>(`${this.apiBaseUrl}${this.filePath}/${fileId}`);
  }

  downloadFile = (data: HttpResponse<Blob>) => {
    if (data?.body == null || data?.body?.type == null) {
      return;
    }

    const fileName = this.getFileName(data);
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');

    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = fileName;
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  };

  runIcaoTests(body: FormData) {
    return this.http.post<IcaoResult>(this.apiBaseUrl + this.icaoPath, body);
  }

  private getFileName(data: HttpResponse<Blob>): string {
    let fileName = 'file';
    const contentDisposition = data.headers.get('Content-Disposition');

    if (!contentDisposition) {
      return fileName;
    }

    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = fileNameRegex.exec(contentDisposition);

    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }
    return fileName;
  }
}
