import { Injectable } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import formValidationData from 'src/assets/form-validation/form-validation.json';
import { NotificationService } from './notification.service';
import {
  FamilyInformationFormFields,
  MeansOfEvidenceFormFields,
  PersonalDetailsReadmissionFieldNames,
  PersonalDetailsTransitFieldNames,
  TransitOperationFormFields,
  TransferModalitiesFormFields,
} from 'src/app/shared/consts/form-fields';
import moment from 'moment';
import { CaseType } from 'swagger';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  constructor(private notificationService: NotificationService, private translate: TranslateService) {}

  setValidatorsForPersonalDetails(personalDetailsForm: FormGroup, caseType: CaseType) {
    if (caseType === CaseType.Readmission) {
      personalDetailsForm = this.setMandatoryFields(PersonalDetailsReadmissionFieldNames, caseType, 'personalDetails', personalDetailsForm);
    }

    if (caseType === CaseType.Return) {
      personalDetailsForm = this.setMandatoryFields(PersonalDetailsReadmissionFieldNames, caseType, 'personalDetails', personalDetailsForm);
    }

    if (caseType === CaseType.Transit) {
      personalDetailsForm = this.setMandatoryFields(PersonalDetailsTransitFieldNames, caseType, 'personalDetails', personalDetailsForm);
    }
    return personalDetailsForm;
  }

  setValidatorsForTransferModalities(transferModalitiesForm: FormGroup, caseType: CaseType) {
    return this.setMandatoryFields(TransferModalitiesFormFields, caseType, 'transferModalities', transferModalitiesForm);
  }

  setValidatorsForFamilyInformation(familyInformationForm: FormGroup, caseType: CaseType) {
    return this.setMandatoryFields(FamilyInformationFormFields, caseType, 'familyInformation', familyInformationForm);
  }

  setValidatorsForFamilyInformationArray(familyInformationForm: FormArray, caseType: CaseType) {
    const familyMembers = familyInformationForm.controls;
    familyMembers.forEach((memberForm: any) => this.setMandatoryFields(FamilyInformationFormFields, caseType, 'familyInformation', memberForm));
    return familyInformationForm;
  }

  setValidatorsForMeansOfEvidence(meansOfEvidenceForm: FormGroup, caseType: CaseType) {
    return this.setMandatoryFields(MeansOfEvidenceFormFields, caseType, 'meansOfEvidence', meansOfEvidenceForm);
  }

  setValidatorsForMeansOfEvidenceArray(meansOfEvidenceForm: FormArray, caseType: CaseType) {
    const evidences = meansOfEvidenceForm.controls;
    evidences.forEach((evidenceForm: any) => this.setMandatoryFields(MeansOfEvidenceFormFields, caseType, 'meansOfEvidence', evidenceForm));
    return meansOfEvidenceForm;
  }

  setValidatorsForTransitOperation(transitOperationForm: FormGroup) {
    return this.setMandatoryFields(TransitOperationFormFields, 'Transit', 'transitOperation', transitOperationForm);
  }

  checkValidityOfInterview(interviewForm: FormGroup) {
    let validData = true;
    const date = interviewForm.controls['proposedStartDate'].value._i
      ? interviewForm.controls['proposedStartDate'].value._i
      : interviewForm.controls['proposedStartDate'].value;
    const startTime = moment(date + ' ' + interviewForm.controls['proposedStartTime'].value).toDate();
    const finishTime = moment(date + ' ' + interviewForm.controls['proposedFinishTime'].value).toDate();
    if (startTime >= finishTime) {
      this.notificationService.showError(this.translate.instant('ERRORS.FINISH_TIME_BEFORE_START_TIME'));
      validData = false;
    }

    if (interviewForm.controls['proposedStartDate'].status === 'INVALID') {
      this.notificationService.showError(this.translate.instant('ERRORS.NOT_FUTURE_DATE'));
      validData = false;
    }

    return validData;
  }

  checkValidityOfPersonalDetails(personalDetailsForm: FormGroup, caseType: CaseType) {
    if (caseType === CaseType.Readmission || caseType === CaseType.Return) {
      this.checkValidityOfFields(PersonalDetailsReadmissionFieldNames, personalDetailsForm, 'PERSONAL_DETAILS.', 'NEW_CASE.PERSONAL_DETAILS');
    }

    if (caseType === CaseType.Transit) {
      this.checkValidityOfFields(PersonalDetailsTransitFieldNames, personalDetailsForm, 'PERSONAL_DETAILS.', 'NEW_CASE.PERSONAL_DETAILS');
    }
  }

  checkValidityOfTransitOperation(transitOperationForm: FormGroup) {
    this.checkValidityOfFields(TransitOperationFormFields, transitOperationForm, 'TRANSIT_OPERATION.', 'NEW_CASE.TRANSIT_OPERATION');
  }

  checkValidityOfFamilyInformation(familyInformationForm: FormArray) {
    const familyMembers = familyInformationForm.controls;
    familyMembers.forEach((memberForm: any) =>
      this.checkValidityOfFields(FamilyInformationFormFields, memberForm, 'FAMILY_INFORMATION.', 'NEW_CASE.FAMILY_INFORMATION')
    );
  }

  checkValidityOfMeansOfEvidence(meansOfEvidenceForm: FormArray) {
    const evidences = meansOfEvidenceForm.controls;
    evidences.forEach((evidenceForm: any) =>
      this.checkValidityOfFields(MeansOfEvidenceFormFields, evidenceForm, 'MEANS_OF_EVIDENCE.', 'NEW_CASE.MEANS_OF_EVIDENCE')
    );
  }

  checkValidityOfTransferModalities(transferModalitiesForm: FormGroup) {
    this.checkValidityOfFields(TransferModalitiesFormFields, transferModalitiesForm, 'TRANSFER_MODALITIES.', 'NEW_CASE.TRANSFER_MODALITIES');
  }

  private checkValidityOfFields(formFields: string[], formGroup: FormGroup, translationPath: string, translationTitle: string) {
    let convertedWord: string;
    formFields.forEach((fieldName: string) => {
      convertedWord = fieldName.replace(/([A-Z])/g, '_$1').trim();
      if (formGroup.controls[fieldName].status === 'INVALID') {
        this.notificationService.showError(
          this.translate.instant(translationPath + convertedWord.toUpperCase()) + ' ' + this.translate.instant('IS_INVALID'),
          this.translate.instant(translationTitle)
        );
      }
    });
  }
  private setMandatoryFields(formFields: string[], caseType: CaseType, formName: string, formGroup: FormGroup) {
    formFields.forEach((fieldName) => {
      if (this.isFieldMandatory(caseType, formName, fieldName)) {
        formGroup.get(fieldName)?.setValidators([Validators.required]);
      }
    });
    return formGroup;
  }

  private isFieldMandatory(caseType: CaseType, formName: string, fieldName: string) {
      const form =  Object((formValidationData as any)[caseType.toLocaleLowerCase()])[formName];
      const formField = Object(form)[fieldName];

      if (formField.mandatory) {
        return true;
      }
      return false;
    } 
  }
