/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CaseType } from './caseType';
import { CivilStatus } from './civilStatus';
import { CreateBiometricDto } from './createBiometricDto';
import { CreateFamilyMemberDto } from './createFamilyMemberDto';
import { CreateMeansOfEvidenceDto } from './createMeansOfEvidenceDto';
import { CreateTransitOperationDto } from './createTransitOperationDto';
import { FileDto } from './fileDto';
import { SexType } from './sexType';

export interface CreateCaseParameter { 
    givenName?: string;
    surname?: string;
    maidenName?: string;
    civilStatus?: CivilStatus;
    photograph?: FileDto;
    sex?: SexType;
    dateOfBirth?: Date;
    deadline?: Date;
    placeOfBirth?: string;
    placeOfBirthRegionCity?: string;
    acceleratedProcedure?: boolean;
    interviewRequest?: boolean;
    readmissionInError?: boolean;
    physicalDescription?: string;
    alias?: string;
    languageSpoken?: string;
    nationalityCountryCode?: string;
    lastAddress?: string;
    submittingAgencyReference?: string;
    stateOfHealth?: string;
    observations?: string;
    indicationOfDangerousPerson?: string;
    caseType?: CaseType;
    travelDocumentType?: string;
    documentNo?: string;
    receivingCountryCode?: string;
    saveAsDraft?: boolean;
    assignedUserId?: string;
    assignedGroups?: Array<string>;
    transitOperation?: CreateTransitOperationDto;
    biometrics?: CreateBiometricDto;
    familyMembers?: Array<CreateFamilyMemberDto>;
    meansOfEvidences?: Array<CreateMeansOfEvidenceDto>;
    relatedRecords?: Array<string>;
}