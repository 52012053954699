/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateTaskDataDto } from './createTaskDataDto';
import { DeadlineUnitType } from './deadlineUnitType';
import { TaskRequestType } from './taskRequestType';
import { TaskType } from './taskType';

export interface CreateTaskDto { 
    name?: string;
    description?: string;
    type?: TaskType;
    requestType?: TaskRequestType;
    caseId?: string;
    deadlineUnit?: DeadlineUnitType;
    taskData?: CreateTaskDataDto;
    receivingCountryCode?: string;
    nationalTask?: boolean;
    deadlineCount?: number;
    assignedCaseWorkerIds?: Array<string>;
    assignedAgencyGroupId?: string;
    assignedDepartmentGroupId?: string;
}