<div class="dialog-header">
    <h2 mat-dialog-title> {{"NOTIFICATION_PREFERENCES.NOTIFICATION_PREFERENCES" | translate}}</h2>
</div>

<mat-dialog-content>
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="email-icon">email</mat-icon>
                {{"NOTIFICATION_PREFERENCES.EMAIL" | translate}}
            </ng-template>
            <div class="preferences">
                <mat-checkbox matInput disabled
                    [checked]=" IsChecked(NotificationLevel.RequiringAction, emailNotificationPreferences$ | async)">
                    {{"NOTIFICATION_PREFERENCES.REQUIRING_ACTION" | translate}}</mat-checkbox>
                <br>
                <mat-checkbox matInput
                    [checked]=" IsChecked(NotificationLevel.Informative, emailNotificationPreferences$ | async)"
                    (change)="toggleNotificationPreference($event.checked, false)">
                    {{"NOTIFICATION_PREFERENCES.INFORMATIVE" | translate}}</mat-checkbox>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>notifications</mat-icon>
                {{"NOTIFICATION_PREFERENCES.SYSTEM" | translate}}
            </ng-template>
            <div class="preferences">
                <mat-checkbox matInput disabled
                    [checked]=" IsChecked(NotificationLevel.RequiringAction, systemNotificationPreferences$ | async)">
                    {{"NOTIFICATION_PREFERENCES.REQUIRING_ACTION" | translate}}</mat-checkbox>
                <br>
                <mat-checkbox matInput
                    [checked]=" IsChecked(NotificationLevel.Informative, systemNotificationPreferences$ | async)"
                    (change)="toggleNotificationPreference($event.checked, true)">
                    {{"NOTIFICATION_PREFERENCES.INFORMATIVE" | translate}}</mat-checkbox>
            </div>
        </mat-tab>
    </mat-tab-group>

</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-button color="primary" cdkFocusInitial (click)="savePreferences()">
        <mat-icon>save</mat-icon> {{"ACTIONS.SAVE" | translate}}
    </button>
    <button mat-button color="primary" mat-dialog-close (click)="close()">
        <mat-icon>cancel</mat-icon> {{"ACTIONS.CANCEL" | translate}}
    </button>
</mat-dialog-actions>
