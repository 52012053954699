import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ApplicationService } from './api/application.service';
import { CalendarService } from './api/calendar.service';
import { CaseService } from './api/case.service';
import { FileService } from './api/file.service';
import { HelpCenterService } from './api/helpCenter.service';
import { ICAOService } from './api/iCAO.service';
import { KeycloakService } from './api/keycloak.service';
import { LoginService } from './api/login.service';
import { NotificationService } from './api/notification.service';
import { StatisticsService } from './api/statistics.service';
import { TaskService } from './api/task.service';
import { TranslationService } from './api/translation.service';
import { UserGroupService } from './api/userGroup.service';
import { UsersService } from './api/users.service';
import { WorkflowService } from './api/workflow.service';
import { WorkshopService } from './api/workshop.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ApplicationService,
    CalendarService,
    CaseService,
    FileService,
    HelpCenterService,
    ICAOService,
    KeycloakService,
    LoginService,
    NotificationService,
    StatisticsService,
    TaskService,
    TranslationService,
    UserGroupService,
    UsersService,
    WorkflowService,
    WorkshopService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
