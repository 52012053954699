<h1 mat-dialog-title>{{ 'UI.WORKFLOW.CREATE_WORKFLOW' | translate }}</h1>
<mat-dialog-content *ngIf="ready">
  <form class="w-100 mb-5" [formGroup]="workflowForm">
    <div class="mb-5">
      <h2 class="h2">{{ 'UI.GENERAL' | translate }}</h2>

      <mat-form-field class="w-100">
        <mat-label>{{ 'UI.WORKFLOW.SENDING_STATE' | translate }}</mat-label>
        <mat-select formControlName="requestingCountryCode" (selectionChange)="setUnSelectedCaseTypes($event)">
          <mat-option *ngFor="let country of countries" [value]="country.code">{{ country.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-100">
        <mat-label>{{ 'UI.ENUM.CASE_TYPE' | translate }}</mat-label>
        <mat-select formControlName="caseType" (selectionChange)="onCaseTypeChange($event)">
          <mat-option *ngFor="let caseType of unSelectedCaseTypes" [value]="caseType">{{ caseType }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-100">
        <mat-label>{{ 'UI.WORKFLOW.RECEIVING_AGENCY' | translate }}</mat-label>
        <mat-select formControlName="assignToGroupId" (selectionChange)="onAgencySelectionChange($event)">
          <mat-option *ngFor="let userGroup of userGroups" [value]="userGroup.id">{{ userGroup.formattedName }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-100" *ngIf="departmentsOfSelectedAgency">
        <mat-label>{{ 'UI.WORKFLOW.RECEIVING_DEPARTMENT' | translate }}</mat-label>
        <mat-select formControlName="assignToDepartmentId">
          <mat-option></mat-option>
          <mat-option *ngFor="let userGroup of departmentsOfSelectedAgency" [value]="userGroup.id">{{ userGroup.formattedName }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div formGroupName="deadline">
      <h2 class="h2">{{ 'UI.DEADLINE' | translate }}</h2>

      <div class="mb-5">
        <h3 class="h3">{{ 'UI.REGULAR' | translate }}</h3>
        <mat-form-field class="w-100">
          <mat-label>{{ 'UI.WORKFLOW.NUMBER_OF_DAYS' | translate }}</mat-label>
          <input matInput formControlName="regularDays" type="number" />
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>{{ 'UI.WORKFLOW.TYPE_OF_DAYS' | translate }}</mat-label>
          <mat-select formControlName="regularAsCalendarDays">
            <mat-option [value]="true">{{ 'BusinessDayType.Calendar' | translate }}</mat-option>
            <mat-option [value]="false">{{ 'BusinessDayType.Working' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>{{ 'UI.WORKFLOW.START_AT' | translate }}</mat-label>
          <mat-select formControlName="regularCountType">
            <mat-option *ngFor="let deadlineCountType of deadlineCountTypes" value="{{ deadlineCountType.enumValue }}">{{
              deadlineCountType.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="mb-2" *ngIf="showAcceleratedFields()">
        <h3 class="h3">{{ 'UI.ACCELERATED' | translate }}</h3>
        <mat-form-field class="w-100">
          <mat-label>{{ 'UI.WORKFLOW.NUMBER_OF_DAYS' | translate }}</mat-label>
          <input matInput formControlName="acceleratedDays" type="number" />
        </mat-form-field>

        <mat-form-field class="w-100">
          <mat-label>{{ 'UI.WORKFLOW.TYPE_OF_DAYS' | translate }}</mat-label>
          <mat-select formControlName="acceleratedAsCalendarDays">
            <mat-option [value]="true">{{ 'BusinessDayType.Calendar' | translate }}</mat-option>
            <mat-option [value]="false">{{ 'BusinessDayType.Working' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-100">
          <mat-label>{{ 'UI.WORKFLOW.START_AT' | translate }}</mat-label>
          <mat-select formControlName="acceleratedCountType">
            <mat-option *ngFor="let deadlineCountType of deadlineCountTypes" value="{{ deadlineCountType.enumValue }}">{{
              deadlineCountType.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
  <mat-dialog-actions [align]="'start'">
    <button mat-button color="primary" (click)="onClose(true)"><mat-icon class="next-icon">add</mat-icon> {{ 'ACTIONS.SAVE' | translate }}</button>
    <button mat-button color="primary" (click)="onClose()"><mat-icon>cancel</mat-icon> {{ 'ACTIONS.CANCEL' | translate }}</button>
  </mat-dialog-actions>
</mat-dialog-content>
