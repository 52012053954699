/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface KeycloakUser { 
    id?: string;
    email?: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
    createdTimestamp?: number;
    emailVerified?: boolean;
    enabled?: boolean;
    groupsToAdd?: Array<string>;
    groupId?: string;
    groupName?: string;
}