/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type RelationshipType = 'Mother' | 'Father' | 'Daughter' | 'Son' | 'Brother' | 'Sister' | 'Spouse' | 'Cousin' | 'Fatherinlaw' | 'Motherinlaw' | 'Other';

export const RelationshipType = {
    Mother: 'Mother' as RelationshipType,
    Father: 'Father' as RelationshipType,
    Daughter: 'Daughter' as RelationshipType,
    Son: 'Son' as RelationshipType,
    Brother: 'Brother' as RelationshipType,
    Sister: 'Sister' as RelationshipType,
    Spouse: 'Spouse' as RelationshipType,
    Cousin: 'Cousin' as RelationshipType,
    Fatherinlaw: 'Fatherinlaw' as RelationshipType,
    Motherinlaw: 'Motherinlaw' as RelationshipType,
    Other: 'Other' as RelationshipType
};