import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppConfigService } from '../app-config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {
  appInsights: ApplicationInsights;
  // this.appInsights.loadAppInsights();

  constructor(private config: AppConfigService) {
    this.appInsights = new ApplicationInsights({
      config: {    
        connectionString: config.getConfig().applicationInsights.connectionString,
        enableAutoRouteTracking: true, // option to log all route changes
        loggingLevelConsole: 2
      }
    });

    if(this.appInsights.config.connectionString)
    {
      this.appInsights.loadAppInsights();
      this.appInsights.trackPageView();
    }
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message}, properties);
  }
}
