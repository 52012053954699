import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

const TOASTR_OPTIONS: any = {
  'closeButton': true,
  'disableTimeOut': false,
  'easeTime': 300,
  'easing': 'ease-in',
  'enableHtml': false,
  'extendedTimeOut': 1000,
  'messageClass': 'toast-message',
  'newestOnTop': true,
  'onActivateTick': false,
  'payload': null,
  'positionClass': 'toast-top-center',
  'progressAnimation': 'decreasing',
  'progressBar': true,
  'tapToDismiss': true,
  'timeOut': 5000,
  'titleClass': 'toast-title',
  'toastClass': 'ngx-toastr'
};

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  showSuccess(message: string | undefined = undefined, title: string | undefined = undefined, timeout?: number) {
    if (timeout) {
      TOASTR_OPTIONS.timeOut = timeout;
    }
    this.toastr.success(message, title, TOASTR_OPTIONS);
  }

  showError(message: string | undefined = undefined, title: string | undefined = undefined, timeOut?: number) {
    if (timeOut) {
      TOASTR_OPTIONS.timeOut = timeOut;
    }
    else {
      TOASTR_OPTIONS.timeOut = 7000;
    }
    this.toastr.error(message, title, TOASTR_OPTIONS);
  }

  showInfo(message: string | undefined = undefined, title: string | undefined = undefined, timeout?: number) {
    if (timeout) {
      TOASTR_OPTIONS.timeOut = timeout;
    }
    this.toastr.info(message, title, TOASTR_OPTIONS);
  }

  showWarning(message: string | undefined = undefined, title: string | undefined = undefined, maxOpened: number | undefined, timeout: number | undefined = undefined) {

    if (maxOpened != undefined) {
      this.toastr.toastrConfig.maxOpened = maxOpened;
    }

    if (timeout) {
      TOASTR_OPTIONS.timeOut = timeout;
    }

    this.toastr.warning(message, title, TOASTR_OPTIONS);
  }

  showLogoutTimer(message: string | undefined = undefined, title: string | undefined = undefined, maxOpened: number | undefined, timeout: number | undefined = undefined) {
    if (maxOpened != undefined) {
      this.toastr.toastrConfig.maxOpened = maxOpened;
    }

    TOASTR_OPTIONS.positionClass = 'toast-center-center';
    TOASTR_OPTIONS.disableTimeOut = 'extendedTimeOut';

    if (timeout) {
      TOASTR_OPTIONS.timeOut = timeout;
    }

    return this.toastr.warning(message, title, TOASTR_OPTIONS);
  }

}
