/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Correspondence } from './correspondence';
import { Country } from './country';
import { TranslatedComment } from './translatedComment';
import { TransmitStatus } from './transmitStatus';
import { UserGroup } from './userGroup';

export interface Comment { 
    id?: string;
    correspondenceId?: string;
    parentId?: string;
    body?: string;
    sentByUser?: string;
    sentByAgencyId?: string;
    sentByAgency?: UserGroup;
    sentByDepartmentId?: string;
    sentByDepartment?: UserGroup;
    userId?: string;
    transmitStatus?: TransmitStatus;
    attachments?: Array<any>;
    correspondence?: Correspondence;
    translatedComment?: TranslatedComment;
    createdAt?: Date;
    sentByCountryCode?: string;
    sentByCountry?: Country;
}