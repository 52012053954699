import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { File2Service, NotificationService, UtilService, UtilityService } from '@shared/services';
import { FileDto } from 'swagger';
import { FileExtensionType } from '@shared/models/enums';
import { ConfirmationDialogComponent } from '@shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss', '../../../../src/assets/bootstrap-utilities.min.css'],
})
export class FileUploadComponent {
  @Output() singleFileOutput: EventEmitter<FileDto | null> = new EventEmitter<FileDto | null>();
  @Output() multipleFilesOutput: EventEmitter<FileDto[] | []> = new EventEmitter<FileDto[] | []>();

  @Input() singleFileInput: FileDto | undefined | null;
  @Input() multipleFilesInput: FileDto[] | undefined | null;
  @Input() validFileExtensions: string = Object.keys(FileExtensionType)
    .map((key) => `.${key}`)
    .join(',');
  @Input() multiple = false;
  @Input() viewOnly = false;

  errorMessage: string;
  selectedFiles: FileDto[] = [];

  constructor(
    private utilService: UtilService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private utilityService: UtilityService,
    private file2Service: File2Service,
    private dialog: MatDialog,
  ) {}

  ngOnChanges() {
    if (this.singleFileInput) {
      this.selectedFiles = [this.singleFileInput];
    } else if (this.multipleFilesInput) {
      this.selectedFiles = this.multipleFilesInput;
    } else {
      this.selectedFiles = [];
    }
  }

  public addFile(event: any): void {
    const file: File = event.target.files[0];

    const errorMessage = this.utilService.validateFileUpload(file, this.validFileExtensions);
    if (errorMessage !== null) {
      this.notificationService.showError(this.translate.instant(errorMessage), this.translate.instant('ERROR'));
      return;
    }

    this.utilService.convertFileToFileDto(file).subscribe({
      next: (fileDto) => {
        if (this.multiple) {
          this.selectedFiles.push(fileDto);
          this.multipleFilesOutput.emit(this.selectedFiles);
        } else {
          this.selectedFiles = [fileDto];
          this.singleFileOutput.emit(fileDto);
        }
      },
    });
  }

  public removeFile(index: number): void {
    const file = this.selectedFiles[index];

    if (file) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('CONFIRMATION_DIALOG.ARE_YOU_SURE_DELETE_FILE', { fileName: file.fileName }),
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.selectedFiles.splice(index, 1);

          if (this.multiple) {
            this.multipleFilesOutput.emit(this.selectedFiles);
          } else {
            this.singleFileOutput.emit(null);
          }
        }
      });
    }
  }

  public downloadFile(event: MouseEvent, index: number): void {
    event.preventDefault();
    const file = this.selectedFiles[index];

    if (file?.id) {
      this.file2Service.downloadFile(file);
    } else {
      this.utilityService.downloadFile(file);
    }
  }
}
