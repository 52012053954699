import { createReducer, on } from '@ngrx/store';
import { FlagDto } from 'src/app/case/models/flagDto';
import { UserPreferencesDto } from 'src/app/case/models/UserPreferencesDto';
import { KeycloakTokenUserInfo } from 'src/app/system-administration/models/keycloakUser';
import { UserApiActions } from './actions';

export interface UserState {
  userPreferences: UserPreferencesDto | null;
  error: string;
  userCaseFlags: FlagDto[];
  keycloakUserInfo: KeycloakTokenUserInfo | null;
}

const initialState: UserState = {
  userPreferences: null,
  userCaseFlags: [],
  keycloakUserInfo: null,
  error: ''
};

export const userReducer = createReducer<UserState>(
  initialState,
  on(UserApiActions.loadUserPreferencesSuccess, (state, action): UserState => {
    return {
      ...state,
      userPreferences: action.userPreferences,
      error: ''
    };
  }),
  on(UserApiActions.loadUserPreferencesFailure, (state, action): UserState => {
    return {
      ...state,
      error: action.error
    };
  }),
  on(UserApiActions.updateUserPreferencesSuccess, (state, action): UserState => {
    return {
      ...state,
      userPreferences: action.userPreferences,
      error: ''
    };
  }),
  on(UserApiActions.updateUserPreferencesFailure, (state, action): UserState => {
    return {
      ...state,
      error: action.error
    };
  }),
  on(UserApiActions.loadUserCaseFlagsSuccess, (state, action): UserState => {
    return {
      ...state,
      userCaseFlags: action.userCaseFlags,
      error: ''
    };
  }),
  on(UserApiActions.loadUserCaseFlagsFailure, (state, action): UserState => {
    return {
      ...state,
      error: action.error
    };
  }),
  on(UserApiActions.loadKeycloakUserInfoSuccess, (state, action): UserState => {
    return {
      ...state,
      keycloakUserInfo: action.keycloakUserInfo,
      error: ''
    };
  }),
  on(UserApiActions.loadKeycloakUserInfoFailure, (state, action): UserState => {
    return {
      ...state,
      error: action.error
    };
  }),
);
