import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './security/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './security/unauthorized/unauthorized.component';
import { AuthGuard } from './shared/services/authguard';

// todo this commented code is to be used when the new login page is on place
const routes: Routes = [
  {
    path: 'cases' /* canLoad: [AuthGuard, RegularUserGuard], */,
    loadChildren: () => import('./case/case.module').then((m) => m.CaseModule),
  },
  {
    path: 'system-administration' /* canLoad: [AuthGuard, PrimaryUserGuard]  */,
    loadChildren: () => import('./system-administration/system-administration.module').then((m) => m.SystemAdministrationModule),
  },
  {
    path: 'help-center',
    loadChildren: () => import('./help-center/help-center.module').then((m) => m.HelpCenterModule),
  },
  {
    path: 'statistics' /* canLoad: [AuthGuard, PrimaryUserGuard],  */,
    loadChildren: () => import('./statistics/statistics.module').then((m) => m.StatisticsModule),
  },
  {
    path: 'language-administration',
    loadChildren: () => import('./language-administration/language-administration.module').then((m) => m.LanguageAdministrationModule),
  },
  {
    path: 'workflow',
    loadChildren: () => import('./workflow/workflow.module').then((m) => m.WorkflowModule),
  },
  {
    path: 'workshop-administration',
    loadChildren: () => import('./workshop-administration/workshop-administration.module').then((m) => m.WorkshopAdministrationModule),
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '', pathMatch: 'full', redirectTo: 'cases' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
