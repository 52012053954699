/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type NotificationType = 'CaseCreated' | 'CorrespondenceAdded' | 'TransferModalitiesAdded' | 'TransferModalitiesUpdated' | 'OutcomeMade' | 'NewReturnIndication' | 'GeneralTaskAdded' | 'TranslationTaskAdded' | 'CaseReallocated' | 'NewTransitIndication' | 'CaseUpdated' | 'TransitIndicationReminder' | 'GeneralTaskCompleted' | 'TranslationTaskCompleted' | 'CaseAnonymizationReminder' | 'CaseFailedTransmission' | 'CorrespondenceFailedTransmission' | 'TransferModalityFailedTransmission' | 'CaseOutcomeFailedTransmission' | 'NewCaseCorrespondenceReply' | 'NewCaseWithInterviewRequest' | 'NewCaseWithReadmissionInError' | 'RequestTaskAdded' | 'RequestTaskCompleted' | 'ProcessMinuteTaskAdded' | 'ProcessMinuteTaskCompleted' | 'Register' | 'SendResetPassword' | 'VerifyEmail' | 'SendVerifyEmail' | 'InterviewFailedTransmission' | 'InterviewScheduled' | 'InterviewConfirmed' | 'InterviewRescheduled' | 'InterviewCompleted' | 'CasePutOnHold' | 'CaseReactivated' | 'CaseAllocationReminder' | 'TaskDeadlineReminder' | 'CaseDeadlineReminder' | 'OutcomeDetermined' | 'OutOfSyncCaseClosed' | 'ProcessMinuteTaskUpdated' | 'GeneralTaskUpdated' | 'TranslationTaskUpdated' | 'RequestTaskUpdated';

export const NotificationType = {
    CaseCreated: 'CaseCreated' as NotificationType,
    CorrespondenceAdded: 'CorrespondenceAdded' as NotificationType,
    TransferModalitiesAdded: 'TransferModalitiesAdded' as NotificationType,
    TransferModalitiesUpdated: 'TransferModalitiesUpdated' as NotificationType,
    OutcomeMade: 'OutcomeMade' as NotificationType,
    NewReturnIndication: 'NewReturnIndication' as NotificationType,
    GeneralTaskAdded: 'GeneralTaskAdded' as NotificationType,
    TranslationTaskAdded: 'TranslationTaskAdded' as NotificationType,
    CaseReallocated: 'CaseReallocated' as NotificationType,
    NewTransitIndication: 'NewTransitIndication' as NotificationType,
    CaseUpdated: 'CaseUpdated' as NotificationType,
    TransitIndicationReminder: 'TransitIndicationReminder' as NotificationType,
    GeneralTaskCompleted: 'GeneralTaskCompleted' as NotificationType,
    TranslationTaskCompleted: 'TranslationTaskCompleted' as NotificationType,
    CaseAnonymizationReminder: 'CaseAnonymizationReminder' as NotificationType,
    CaseFailedTransmission: 'CaseFailedTransmission' as NotificationType,
    CorrespondenceFailedTransmission: 'CorrespondenceFailedTransmission' as NotificationType,
    TransferModalityFailedTransmission: 'TransferModalityFailedTransmission' as NotificationType,
    CaseOutcomeFailedTransmission: 'CaseOutcomeFailedTransmission' as NotificationType,
    NewCaseCorrespondenceReply: 'NewCaseCorrespondenceReply' as NotificationType,
    NewCaseWithInterviewRequest: 'NewCaseWithInterviewRequest' as NotificationType,
    NewCaseWithReadmissionInError: 'NewCaseWithReadmissionInError' as NotificationType,
    RequestTaskAdded: 'RequestTaskAdded' as NotificationType,
    RequestTaskCompleted: 'RequestTaskCompleted' as NotificationType,
    ProcessMinuteTaskAdded: 'ProcessMinuteTaskAdded' as NotificationType,
    ProcessMinuteTaskCompleted: 'ProcessMinuteTaskCompleted' as NotificationType,
    Register: 'Register' as NotificationType,
    SendResetPassword: 'SendResetPassword' as NotificationType,
    VerifyEmail: 'VerifyEmail' as NotificationType,
    SendVerifyEmail: 'SendVerifyEmail' as NotificationType,
    InterviewFailedTransmission: 'InterviewFailedTransmission' as NotificationType,
    InterviewScheduled: 'InterviewScheduled' as NotificationType,
    InterviewConfirmed: 'InterviewConfirmed' as NotificationType,
    InterviewRescheduled: 'InterviewRescheduled' as NotificationType,
    InterviewCompleted: 'InterviewCompleted' as NotificationType,
    CasePutOnHold: 'CasePutOnHold' as NotificationType,
    CaseReactivated: 'CaseReactivated' as NotificationType,
    CaseAllocationReminder: 'CaseAllocationReminder' as NotificationType,
    TaskDeadlineReminder: 'TaskDeadlineReminder' as NotificationType,
    CaseDeadlineReminder: 'CaseDeadlineReminder' as NotificationType,
    OutcomeDetermined: 'OutcomeDetermined' as NotificationType,
    OutOfSyncCaseClosed: 'OutOfSyncCaseClosed' as NotificationType,
    ProcessMinuteTaskUpdated: 'ProcessMinuteTaskUpdated' as NotificationType,
    GeneralTaskUpdated: 'GeneralTaskUpdated' as NotificationType,
    TranslationTaskUpdated: 'TranslationTaskUpdated' as NotificationType,
    RequestTaskUpdated: 'RequestTaskUpdated' as NotificationType
};