import { TranslateLoader } from '@ngx-translate/core';
import { concatMap, filter, first, map } from 'rxjs';
import { TranslationService, UserDataDto } from 'swagger';
import { LoginService } from './login.service';

export class TranslationHttpLoaderService implements TranslateLoader {
  constructor(
    private translationService: TranslationService,
    private loginService: LoginService,
  ) {}

  getTranslation(lang: string) {
    const service$ = this.translationService.getTranslation(lang).pipe(
      first(),
      map((res: string) => JSON.parse(res)),
    );

    return this.loginService.loggedInUser$.pipe(
      filter((e: UserDataDto | undefined) => e !== undefined),
      concatMap(() => service$),
    );
  }
}
