/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Comment } from './comment';
import { Country } from './country';
import { ModelCase } from './modelCase';
import { TransmitStatus } from './transmitStatus';
import { UserGroup } from './userGroup';

export interface Correspondence { 
    id?: string;
    caseId?: string;
    _case?: ModelCase;
    sender?: string;
    subject?: string;
    body?: string;
    isNationalCorrespondence?: boolean;
    receivingAgencies?: Array<UserGroup>;
    receivingDepartments?: Array<UserGroup>;
    sentByAgencyId?: string;
    sentByAgency?: UserGroup;
    sentByDepartmentId?: string;
    sentByDepartment?: UserGroup;
    sentByPreferredUsername?: string;
    comments?: Array<Comment>;
    attachments?: Array<any>;
    transmitStatus?: TransmitStatus;
    createdDate?: Date;
    receivingCountryCode?: string;
    sentByCountryCode?: string;
    sentByCountry?: Country;
    receivingCountry?: Country;
}