import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-subscription-cleaner',
  templateUrl: './subscription-cleaner.component.html',
  styleUrls: ['./subscription-cleaner.component.scss'],
})
export class SubscriptionCleaner implements OnDestroy {
  destroy$ = new Subject<boolean>();

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
