/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Country } from './country';
import { InterviewOutcome } from './interviewOutcome';
import { InterviewStatus } from './interviewStatus';
import { ModelCase } from './modelCase';
import { TransmitStatus } from './transmitStatus';

export interface Interview { 
    id?: string;
    caseId?: string;
    _case?: ModelCase;
    interviewOutcome?: InterviewOutcome;
    interviewStatus?: InterviewStatus;
    physicalInterview?: boolean;
    proposedStartDate?: string;
    proposedStartTime?: string;
    proposedFinishTime?: string;
    address?: string;
    meetingUrl?: string;
    transmitStatus?: TransmitStatus;
    receivingCountryCode?: string;
    receivingCountry?: Country;
}