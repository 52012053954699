/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FileExtension } from './fileExtension';
import { FileType } from './fileType';

export interface ModelFile { 
    fileName?: string;
    filePath?: string;
    sizeInBytes?: number;
    fileTypeId?: number;
    fileType?: FileType;
    fileExtensionId?: number;
    fileExtension?: FileExtension;
    id?: string;
    createdDate?: Date;
    lastUpdated?: Date;
}