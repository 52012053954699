import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';
import { AvailableTranslationDto, TranslationService } from 'swagger';

@Component({
  selector: 'app-language-administration-table',
  templateUrl: './language-administration-table.component.html',
  styleUrls: ['./language-administration-table.component.scss'],
})
export class LanguageAdministrationTableComponent {
  @Output('fetchAvailableTranslations') fetchAvailableTranslations: EventEmitter<any> = new EventEmitter();
  @Input() availableTranslations: AvailableTranslationDto[];
  displayedColumns: string[] = ['locale', 'description', 'update', 'remove'];

  constructor(
    private translationService: TranslationService,
    private translate: TranslateService) {}

  protected enableOrDisableLanguage(translation: AvailableTranslationDto): void {
    this.translationService
      .enableOrDisableTranslation(translation.locale!, !translation.enabled)
      .pipe(first())
      .subscribe(() =>  
      {
        this.fetchAvailableTranslations.emit();
        this.fetchEnabledLocales() 
      });
  }

  protected removeUserAddedTranslation(translation: AvailableTranslationDto): void {
    this.translationService
      .deleteUserAddedTranslation(translation.id!)
      .pipe(first())
      .subscribe(() => {
        if(this.translate.currentLang == translation.locale) {
          window.location.reload();
        }
        else {
          this.fetchAvailableTranslations.emit();
        }
      });
  }

  protected fetchEnabledLocales() {
    this.translationService.getActivatedTranslations(true).subscribe((res: AvailableTranslationDto[]) => {
      const enabledLanguages = res.map((x) => x.locale) as string[];
      this.translate.langs = enabledLanguages;
    });
  }
}
