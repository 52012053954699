import { Injectable } from '@angular/core';
import { FileExtensionType } from '@shared/models/enums/fileExtensionType';
import mime from 'mime';
import { FileDto } from 'swagger';
import moment from 'moment';
import { EnumObject } from '@shared/models/enumObject';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  public getEnumValueByKey(enumType: Record<string, string>, key: string): string {
    return Object.entries(enumType).find(([, val]) => val === key)![0];
  }

  public getEnumKeyByValue(enumType: Record<string, string>, value: string): string {
    return Object.entries(enumType).find(([key, _]) => key === value)![1];
  }

  public getBase64FromDataUri(dataUri: string): string {
    const match = dataUri.match(/data:([-\w]+\/[-+\w.]+)?(;?\w+=[-\w]+)*(;base64)?,.*/) != null;

    if (match) {
      return dataUri.split(',')[1];
    }

    throw SyntaxError('Syntax of the DataUri is incorrect');
  }

  public getBlobFromDataUri(dataUri: string): Blob {
    const match = dataUri.match(/data:([-\w]+\/[-+\w.]+)?(;?\w+=[-\w]+)*(;base64)?,.*/) != null;

    if (match) {
      const base64 = dataUri.split(',')[1];
      const byteString = window.atob(base64);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: '' });
      return blob;
    }

    throw SyntaxError('Syntax of the DataUri is incorrect');
  }

  public formatDate(date: string): string | null {
    if (date) {
      return moment(date).format('yyyy-MM-DD');
    }
    return null;
  }

  public downloadFile(file: FileDto): boolean {
    if (file.content) {
      const blob = this.getBlobFromBase64(file.content);
      const url = window.URL.createObjectURL(blob);

      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = file.fileName!;
      downloadLink.click();
      return true;
    }

    return false;
  }

  public downloadFile2(base64String: string, mimeType: string, fileName: string) {
    const source = `data:${mimeType};base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}`;
    link.click();
  }

  public getDataUriFromFile(file: FileDto): string {
    const extension = this.getEnumValueByKey(FileExtensionType, file.fileExtensionId!.toString());
    const mimeType = mime.getType(extension);
    return `data:${mimeType};base64,${file.content}`;
  }

  public getBlobFromBase64(base64: string): Blob {
    const byteString = window.atob(base64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: '' });
    return blob;
  }

  public getFileExensionIdFromDataUri(dataUri: string): number {
    const match = dataUri.match(/data:([-\w]+\/[-+\w.]+)?(;?\w+=[-\w]+)*(;base64)?,.*/) != null;

    if (match) {
      // Get the mimeType
      const type = dataUri.match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)?.[0] as string;

      const extension = mime.getExtension(type)!;
      const fileExtension = this.getEnumValueByKey(FileExtensionType, extension);

      return Number.parseInt(fileExtension);
    }

    throw SyntaxError('Syntax of the DataUri is incorrect');
  }

  public compareDate(a: Date | undefined, b: Date | undefined): number {
    if (a === undefined && b === undefined) {
      return 0;
    } else if (a === undefined) {
      return -1;
    } else if (b === undefined) {
      return 1;
    } else {
      const t1 = new Date(a).getTime();
      const t2 = new Date(b).getTime();

      if (t1 < t2) {
        return -1;
      } else if (t1 > t2) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  public getFormattedGroupName(groupName: string | undefined): string {
    return groupName?.includes(':') ? groupName?.split(':')[1] : '';
  }

  public convertToEnumObject(enum_: object, i18nPrefix: string, translateService: TranslateService): EnumObject[] {
    return Object.values(enum_)
      .map((value) => {
        return {
          enumValue: value,
          name: translateService.instant(i18nPrefix + '.' + value),
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }
}
