/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Country } from './country';
import { ModelCase } from './modelCase';
import { TransferModalityDocumentType } from './transferModalityDocumentType';
import { TransitType } from './transitType';
import { TransmitStatus } from './transmitStatus';

export interface TransferModality { 
    id?: string;
    caseId?: string;
    _case?: ModelCase;
    typeOfTransfer?: TransitType;
    proposedReturnDate?: Date;
    transferDocumentType?: TransferModalityDocumentType;
    transferDocumentNo?: string;
    returneeIssuingAuthority?: string;
    returneePlaceOfIssuance?: string;
    returneeExpirationDate?: string;
    escortPossible?: boolean;
    escortFullName?: string;
    escortNationalityCountryCode?: string;
    escortPassportNo?: string;
    escortIssuanceDate?: string;
    escortExpirationDate?: string;
    transmitStatus?: TransmitStatus;
    escortIssuingAuthority?: string;
    escortPlaceOfIssuance?: string;
    isUpdated?: boolean;
    isAnonymized?: boolean;
    escortNationalityCountry?: Country;
}