<table mat-table *ngIf="availableTranslations" [dataSource]="availableTranslations" class="mat-elevation-z8"
    matSortDirection="asc">

    <ng-container matColumnDef="locale">
        <th mat-header-cell *matHeaderCellDef> {{"LANGUAGE_ADMINISTRATION.LOCALE" | translate}} </th>
        <td mat-cell *matCellDef="let translation"> {{translation.locale}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> {{"LANGUAGE_ADMINISTRATION.LOCALE_DESCRIPTION" | translate}} </th>
        <td mat-cell *matCellDef="let translation"> {{translation.localeDescription}} </td>
    </ng-container>

    <ng-container matColumnDef="update">
        <th mat-header-cell *matHeaderCellDef> {{"LANGUAGE_ADMINISTRATION.ENABLED" | translate}} </th>
        <td mat-cell *matCellDef="let translation">
            <mat-checkbox class="example-margin" [checked]="translation.enabled"
                [disabled]="!translation.canBeDeactivated" (click)="enableOrDisableLanguage(translation)">
            </mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef> {{"ACTIONS.ACTIONS" | translate}}</th>
        <td mat-cell *matCellDef="let translation">
            <button *ngIf="translation.userAdded && translation.systemVersionExists" mat-stroked-button color="warn"
                (click)="removeUserAddedTranslation(translation)">
                <mat-icon>restore</mat-icon>
                {{ "ACTIONS.REVERT" | translate}}
            </button>
            <button *ngIf="translation.userAdded && !translation.systemVersionExists" mat-stroked-button color="warn"
                (click)="removeUserAddedTranslation(translation)">
                <mat-icon>delete</mat-icon>
                {{ "ACTIONS.DELETE" | translate}}
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>