<h2 class="page-title">{{'PAGE_NAMES.LANGUAGE_ADMINISTRATION' | translate}}</h2>

<app-language-administration-table 
    *ngIf="availableTranslations" 
    (fetchAvailableTranslations)="fetchAvailableTranslations()"
    [availableTranslations]="availableTranslations">
</app-language-administration-table>

<button mat-stroked-button color="primary" (click)="fileInput.click()"> 
    <input type="file" accept=".json" #fileInput (change)="importTranslation($event)" [hidden]="true" />
    {{ "LANGUAGE_ADMINISTRATION.IMPORT" | translate }} 
</button>