import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationRowComponent } from './notification-row/notification-row.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationBoxComponent } from './notification-box/notification-box.component';
import { MaterialModule } from '../material/material.module';
import { NotificationPreferencesComponent } from './dialogs/notification-preferences/notification-preferences.component';

@NgModule({
  declarations: [
    NotificationRowComponent,
    NotificationBoxComponent,
    NotificationPreferencesComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
  ],
  exports: [
    NotificationBoxComponent
  ]
})
export class NotificationsModule { }
