import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { LoginService } from './login.service';
import { NotificationService } from './notification.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly notificationService: NotificationService,
    private userService: UserService,
    private loginService: LoginService,
  ) {
    super(router, keycloak);
  }

  static subscribeToEvents = true;

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin,
      });
    }

    if (AuthGuard.subscribeToEvents) {
      AuthGuard.subscribeToEvents = false;

      this.loginService.login();

      this.userService.initLoggedInUsed();

      this.keycloak.keycloakEvents$.subscribe((event) => {
        if (event.type == KeycloakEventType.OnTokenExpired) {
          this.keycloak.updateToken();
        }

        if (event.type == KeycloakEventType.OnAuthRefreshError) {
          this.keycloak.logout();
        }

        if (event.type == KeycloakEventType.OnAuthError) {
          this.keycloak.logout();
        }
      });
    }

    return this.authenticated;
  }
}
