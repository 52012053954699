import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import * as UserActions from '../../users/state/actions/user-page.actions';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { UtilityService } from './utility.service';
import { LoginService as ApiLoginService, UserDataDto } from 'swagger';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public loggedInUser: UserDataDto;
  public loggedInUserLoaded = new EventEmitter<boolean>();
  private loggedInUserSubject$: BehaviorSubject<UserDataDto | undefined> = new BehaviorSubject<UserDataDto | undefined>(undefined);
  public loggedInUser$: Observable<UserDataDto | undefined> = this.loggedInUserSubject$.asObservable();

  get loggedInUserAgencyEmblem(): string | null {
    if (this.loggedInUser && this.loggedInUser.countryFlag) {
      return this.utilityService.getDataUriFromFile(this.loggedInUser.countryFlag);
    }
    return null;
  }

  get loggedInUserGroupId(): string {
    return this.loggedInUser.department?.id ?? this.loggedInUser.agency?.id ?? '';
  }

  get loggedInUserCountryCode(): string | null {
    if (this.loggedInUser && this.loggedInUser.countryCode) {
      return this.loggedInUser.countryCode;
    }
    return null;
  }

  public constructor(
    private store: Store<State>,
    private apiLoginService: ApiLoginService,
    private utilityService: UtilityService,
  ) {}

  public login(): void {
    this.store.dispatch(UserActions.loadInitialData());
    this.loggedInUserLoaded.emit(false);
    this.apiLoginService
      .login()
      .pipe(take(1))
      .subscribe((e: UserDataDto) => {
        this.loggedInUser = e;
        this.loggedInUserSubject$.next(e);
        this.loggedInUserLoaded.emit(true);
        this.store.dispatch(UserActions.loadUserPreferences());
      });
  }
}
