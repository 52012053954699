/**
 * eRCMS.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TransitType } from './transitType';

export interface TransitOperationDto { 
    id?: string;
    admissionGuaranteed?: boolean;
    typeOfTransit?: TransitType;
    anyReasonOfRefusal?: boolean;
    finalDestinationCountryCode?: string;
    proposedCrossingPoint?: string;
    proposedTransferDate?: string;
    proposedTransferTime?: string;
    escortPossible?: boolean;
    escortDetails?: string;
    observations?: string;
    otherStatesOfTransit?: Array<string>;
}