<h2 class="page-title">{{'Workshop.Header' | translate}}</h2>

<div class="buttons">
    <button mat-raised-button color="primary" (click)="resetData()">
        <mat-icon>restart_alt</mat-icon> {{"Workshop.ResetData" | translate}}
    </button>

    <button mat-raised-button color="primary" (click)="setPassword()">
        <mat-icon>update</mat-icon> {{"Workshop.SetPassword" | translate}}
    </button>
</div>

<table cdkDropList cdkDropListOrientation="horizontal" mat-table [dataSource]="usersTable" matSort>
    <ng-container [matColumnDef]="col.key" *ngFor="let col of columns">
        <th mat-header-cell *matHeaderCellDef>
            {{ col.label | translate }}
        </th>

        <td mat-cell *matCellDef="let element">
            {{ element[col.key] }}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row"></tr>
</table>